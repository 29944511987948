import { Observable } from "./observable";

interface FactoryMap {
	[ id: string ]: ()=> Persistent;
}

export class Persistent extends Observable {
	static readonly factoryMap: FactoryMap = {}
	static registerFactory<T extends Persistent>( className: string, factory: ()=>T ) {
		this.factoryMap[ className ] = factory
	}

	getClassName() {
		return this['__className'];
	}

	setId( id: string ) {
		this._id = id;
		return this;
	}

	get id() {
		return this._id;
	}

	get token() {
		return this._token;
	}

	protected loaded(){}

	fromObject( p: any ) {
		for( var prop in p ) {
			const memberProp = this._persistentProperties.find( v => v.name === '_'+prop );
			if ( memberProp ) {
				let value = p[ prop ];
				if ( typeof value !== 'undefined' ) {
					if ( memberProp.fromJSONParser ) {
						value = memberProp.fromJSONParser( value );
					}
					else {
						if ( value && value.__className ) {
							const factory = Persistent.factoryMap[ value.__className ];
							value = factory().fromObject( value );
						}
					}

					this[ '_'+prop ] = value;
				}
			}
		}
		this.loaded();
		return this;
	}

	toObject() {
		let obj = {};
		this._persistentProperties.forEach( prop => {
			let value = this[ prop.name ];
			if ( typeof value !== 'undefined' ) {
				if ( value !== null && prop.toJSONParser ) {
					value = prop.toJSONParser( value );
				}
				else {
					const member = this[ prop.name ];
					if ( value !== null && member instanceof Persistent ) {
						value = { __className: member.getClassName(), ...member.toObject() };
					}
				}

				obj[ prop.name.slice(1) ] = value;
			}
		})
		return obj;
	}

	@persistent private _id: string;
	@persistent private _token: string;
	private _persistentProperties: PersistentProperty[];
}

export function registerFactory( className: string, factory: ()=>Persistent ) {
	Persistent.registerFactory( className, factory );
	return ( constructor: Function )=>{
		constructor.prototype.__className = className;
	}
}

interface PersistentProperty {
	name: string;
	toJSONParser?: ( val: any ) => any;
	fromJSONParser?: ( val: any ) => any;
}

export function persistent( target: Persistent, property: string ) {
	return persistentParser()( target, property);
}

export function persistentDate( target: Persistent, property: string ) {
	return persistentParser(
		value => {
			if ( typeof value !== 'undefined' && value !== null ) {
				let d = new Date( value );
				return isNaN( d.getTime() )? null : d;
			}
			return value;
		},
		date => ( date && !isNaN( date.getTime() ) )? date.toISOString() : null
	)( target, property);
}

export function persistentBoolean( target: Persistent, property: string ) {
	return persistentParser(
		value => {
			if ( typeof value !== 'undefined' && value !== null ) {
				return Boolean( value );
			}
			return value;
		},
		bool => bool? 1 : 0
	)( target, property);
}

export function persistentCollection( target: Persistent, property: string ) {
	return persistentParser(
		collection => {
			if ( typeof collection !== 'undefined' && collection !== null ) {
				const retVal = {};
				for( var key in collection ) { // don't convert to Object.values because don't want props in prototype
					const factory = Persistent.factoryMap[ collection[ key ].__className ];
					const item = factory().fromObject( collection[ key ] );
					retVal[ key ] = item;
				}
				return retVal;
			}
			return collection;
		},
		collection => {
			const retVal = {};
			for ( var key in collection ) { // don't convert to Object.values because don't want props in prototype
				const item: Persistent = collection[ key ];
				retVal[ key ] = { __className: item.getClassName(), ...item.toObject() };
			}
			return retVal;
		}
	)( target, property);
}

export function persistentParser( toTypeParser?: ( val: any )=> any, fromTypeParser?: ( val: any ) => any ) {
	return function( target: Persistent, property: string ) {
			// from: https://stackoverflow.com/questions/43912168/typescript-decorators-with-inheritance
			if ( !Object.getOwnPropertyDescriptor( target, '_persistentProperties' ) ) {
				if ( target[ '_persistentProperties' ] )	target[ '_persistentProperties' ]  = [...target[ '_persistentProperties' ] ];
				else target[ '_persistentProperties' ]  = [];
			}
			target[ '_persistentProperties' ].push( { name: property, toJSONParser: fromTypeParser, fromJSONParser: toTypeParser } );
	}
}
