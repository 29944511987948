import { h } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "./jas-component"
import { LockerIcon } from "./locker-icon";
import { ErrorIcon } from "./error-icon";

export interface DateWithLockerState extends JasComponentState {
	showErrorPanel: boolean;
}

export interface DateWithLockerProps extends JasComponentProps {
	label: string;
	onLockerClick: () => void;
	locked: boolean;
	onInput: ( e: Event ) => any;
	value: string;
	errorMessage: string;
	disabled?: boolean;
	type?: string;
	min?: string;
}

export class DateWithLocker extends JasComponent< DateWithLockerProps, DateWithLockerState > {
	constructor( props: DateWithLockerProps ) {
		super( props );
		this.state = { ...this.state, showErrorPanel: false }
		this._lastErrorMessage = '';
	}

	render() {
		const { label, onLockerClick, locked, onInput, value, errorMessage, disabled, type, min } = this.props;

		return (
			<div className={ this.buildMainCSSClass() }>
				<div className="no-wrap">

					<label>{ label }:  </label>
					<LockerIcon
						onClick={ disabled? ()=>{} : ()=> onLockerClick() }
						locked={ locked }
					/>

				</div>
				<div>

					<ErrorIcon message={ errorMessage }>
						<input
							placeholder={ label }
							className={ errorMessage && 'error' }
							type={ type? type : 'date' }
							value={ value }
							onInput={ ( event: Event ) => onInput( event ) }
							min={ min }
							disabled={ disabled }
						/>
					</ErrorIcon>
				</div>
			</div>
		);
	}

	getClassName() {
		return 'DateWithLocker';
	}

	private _lastErrorMessage: string;
}
