import { h, ComponentChildren, Fragment } from "preact";
let SpinnerIcon = require( "@fortawesome/fontawesome-free/svgs/solid/spinner.svg" );

export interface WaitingSpinnerProps {
	waiting: boolean;
	children: ComponentChildren;
}

export function WaitingSpinner( props: WaitingSpinnerProps ) {
	if ( props.waiting ) {
		return (
			<div className="waiting-spinner modal-container full">
				
				{ props.children }

				{ props.waiting &&

					<div className="modal lighter">
						<SpinnerIcon
							data-testid="icon-spinner-big"
							fill="grey"
							width="25%"
							height="25%"
							className="svg-inline icon-button spin"
						/>
					</div>
				
				}
			</div>
		)
	}
	else {
		return (<Fragment>{ props.children }</Fragment>)
	}
}