import { Persistent, persistent, registerFactory } from "../../quarkum.js/persistent";
import { v4 as uuid } from "uuid"
import * as countryCode from "../../../country-codes/country-codes.json";

@registerFactory( 'Wish', ()=>new Wish() )
export class Wish extends Persistent {

	constructor() {
		super();
		this.setId( uuid() )
		this._wishLevel = 0;
		this._lastUpdated = Date.now()
	}

	setUserId( value: string ) {
		this._userId = value;
		return this;
	}

	get userId() {
		return this._userId;
	}

	setCountry( value: string ) {
		this._country = Wish.normalizeCountry( value );
		return this;
	}

	get country() {
		return this._country;
	}

	setCity( value: string ) {
		this._city = value;
		return this;
	}

	get city() {
		return this._city;
	}

	setActivity( value: string ) {
		this._activity = value;
		return this;
	}

	get activity() {
		return this._activity;
	}

	setPost( value: string ) {
		this._post = value;
		return this;
	}

	get post() {
		return this._post;
	}

	setPicture( value: string ) {
		this._picture = value;
		return this;
	}

	get picture() {
		return this._picture;
	}

	setWishLevel( value: number ) {
		if ( this._wishLevel !== value ) {
			this._wishLevel = value
			this._lastUpdated = Date.now()
		}
		return this;
	}

	get wishLevel() {
		return this._wishLevel? this._wishLevel : 0;
	}

	setBlogId( value: string ) {
		this._blogId = value
		return this
	}

	get blogId() {
		return this._blogId || 'no-assigned-blog'
	}

	get lastUpdated() {
		return new Date( this._lastUpdated )
	}

	static normalizeCountry( country: string ): string {
		if ( !country ) return null
		
		const exception = countryCode.exceptions[ country.toUpperCase() ];
		if ( exception ) {
			return exception;
		}

		const code = countryCode.fromCountryName[ country ];
		if ( code ) {
			return code;
		}

		if ( countryCode.fromCountryCode[ country.toUpperCase() ] ) {
			return country.toUpperCase();
		}

		return null;
	}

	static countryName( country_iso_or_name: string ) {
		return countryCode.fromCountryCode[ this.normalizeCountry( country_iso_or_name ) ];
	}

	@persistent private _userId: string;
	@persistent private _country: string;
	@persistent private _city: string;
	@persistent private _activity: string;
	@persistent private _post: string;
	@persistent private _picture: string;
	@persistent private _wishLevel: number;
	@persistent private _lastUpdated: number;
	@persistent private _blogId: string;
}
