import "../../../scss/widgets/travel-planner/controller-panel.scss";
import { h } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../../components/jas-component";
import { Controller } from "../controller/controller";
import { ViewListItem } from "../../components/item-picker/item-picker";
import { MarkdownToJSX } from "../../utils/markdown/markdown-to-jsx";
import { ChangeCallback } from "../../quarkum.js/observable";
import { List } from '../../utils/list';
import { Wish } from '../../wishes/controller/wish';

export interface ControllerPanelProps extends JasComponentProps {
	controller: Controller;
	active?: boolean;
	activatePanel: ( controller: Controller, e: MouseEvent ) => void;
}

export interface ControllerPanelState extends JasComponentState {
	scrollHeight: number;
}

export interface WishListItemMap {
	[key:string]: ViewListItem<Wish>
}

export abstract class ControllerPanel< P extends ControllerPanelProps, S extends ControllerPanelState > extends JasComponent< P, S >{
	constructor( props: P ) {
		super( props );
		this.setupNotificationListeners( props.controller );
	}

	protected setupNotificationListeners( controller: Controller ) {
		this.removeNotificationListeners()
		if ( !controller ) return;

		// REVIEW: Performance maybe compromised
		this._controllerHandler = controller.onChange( () => {
			// if ( sender === this.props.controller ) {
				this.setState({});
			// }
		})

		if ( controller.owner ) {
			this._ownerHandler = controller.owner.onChange( prop => {
				if ( prop.stayLength ) {
					this.setState({});
				}
			})
		}
	}

	removeNotificationListeners() {
		this.props.controller?.removeOnChange( this._controllerHandler );
		this.props.controller?.owner?.removeOnChange( this._ownerHandler );
	}

	componentWillUnmount() {
		this.removeNotificationListeners()
	}

	shouldComponentUpdate( nextProps: ControllerPanelProps ) {
		if ( this.props.controller != nextProps.controller ) {
			this.setupNotificationListeners( nextProps.controller );
		}
		return true;
	}

	activatePanel( controller: Controller, event?: MouseEvent ) {
		this.props.activatePanel( controller, event );
	}

	protected getSelectableItemsMessage( message: string ) {
		const controller = this.props.controller

		return <p class="error">
			{
				new MarkdownToJSX().parse( message, {
					destination: controller?.name,
					link: controller?.relatedPostUrl
				})
			}
		</p>
	}

	getBackgroundPictureStyle( gradient: string ) {
		const { controller } = this.props
		return controller.pictureUrl? {
			backgroundImage: `linear-gradient( ${ gradient } ), url("${ controller.pictureUrl }")`,
			backgroundPosition: 'center',
			backgroundRepeat: 'repeat',
			backgroundSize: 'contain-x'
		} : {}
	}

	renderHeaderPicture() {
		const { active, controller } = this.props

		if ( active && controller.pictureUrl ) return (
			<div className="image-container">

				<img src={ controller.pictureUrl}/>

			</div>
		)
	}

	moveSelected< T extends Controller >( item: ViewListItem<T>, distance: number ): ViewListItem<T> {
		const replacedController = this.props.controller.moveSelected( item.object, distance )
		if ( replacedController )
			return {
				key: replacedController.id,
				label: replacedController.name,
				object: replacedController as T
			}
	}

	protected retrieveSelectableItemsFromRelatedWishes(): ViewListItem< Wish > [] {
		return []
	}

	getSelectableItems(): List< ViewListItem< Wish > > {
		let selectable = this.retrieveSelectableItemsFromRelatedWishes().sort( ( a, b )=> a.label?.localeCompare( b.label ) )
		return new List< ViewListItem< Wish > >( selectable );
	}


	private _controllerHandler: ChangeCallback<Controller>;
	private _ownerHandler: ChangeCallback<Controller>;
}
