import "../../scss/widgets/wish-counter-widget.scss"
import { h } from "preact";
import * as preact from "preact";
import { JasComponent, JasComponentProps, JasComponentState } from "../components/jas-component";
import { UserController, CallbackUserController } from "../user/controller/user-controller";
import { Tutorial, TutorialCallback } from "../components/tutorial/tutorial";
import { MarkdownToJSX } from "../utils/markdown/markdown-to-jsx";
import { TravelPlanWidget } from "./travel-plan-widget";
import { Config } from "../config";
const HeartIconRegular = require( "@fortawesome/fontawesome-free/svgs/regular/heart.svg" );

interface WishCounterWidgetProps extends JasComponentProps {
	tripPlanner?: string;
}

interface WishCounterWidgetState extends JasComponentState {
	wishCount: number;
	showHintMessage: boolean;
	showModal: boolean;
}

export class WishCounterWidget extends JasComponent<WishCounterWidgetProps, WishCounterWidgetState> {
	componentDidMount() {
		this._userChangeHdl = UserController.instance.onUserControllerChange( event =>{
			const e = event.userChange

			if ( e ) {
				this.setState({ 
					wishCount: e.user?.wishCount || e.userEvent?.wishCount,
					showHintMessage: e.user?.showTutorial
				})
			}
		})

		this._tutorialChange = Tutorial.instance.onChange( event => {
			this.setState({ showHintMessage: false })
		})

		UserController.instance.startListeningForUserChanges();
		this.setState({ wishCount: UserController.instance.user?.wishCount })
	}

	componentWillUnmount() {
		UserController.instance.removeOnChange( this._userChangeHdl )
		Tutorial.instance.removeOnChange( this._tutorialChange )
	}

	render() {
		const { wishCount, locale, showHintMessage, showModal } = this.state

		if ( showHintMessage ) {
			const rect = (this.base as Element).firstElementChild.getBoundingClientRect()
			
			const pos = ( rect.top - 50 < 0 )? 'bottom' : 'top'
			const align = ( rect.right - 100 < 0 )? ' align-left' : ' align-right'
			var hintPosition = ` ${ pos } ${ align }`
		}

		return (
			<div className="wish-to-go">
				<div className={ this.buildMainCSSClass() }>
					<div className="badge super clickable"
						data-count={ wishCount || '0' } 
						onClick={ ()=> this.openTripPlanner() }
					>
						<HeartIconRegular data-testid="icon-heart-regular" fill="red" width="1em"/>
					</div>

					{ showHintMessage &&					
						<div className={ 'help-bubble clickable' + hintPosition } 
							onClick={ ()=> this.setState({ showHintMessage: false }) }
						>
							{ new MarkdownToJSX().parse( locale.hintMessage ) }

							<div className="continue-label clickable">
								<small onClick={ ()=> this.setState({ showHintMessage: false }) }>
									{ locale.gotIt }
								</small>
							</div>

						</div>
					}
				</div>

				{ showModal && 
					<TravelPlanWidget modal
						queryClose={ ()=> this.setState({ showModal: false }) }
					/> 
				}

			</div>
		)
	}

	private openTripPlanner() {
		const { tripPlanner } = this.props

		if ( tripPlanner ) {
			window.location.href = tripPlanner
				? tripPlanner : Config.getWebServerURL( Config.pages.travelPlanner )
		}
		else {
			window.scrollBy( 0, 65 )
			this.setState({ showModal: true })
		}
	}

	getClassName() {
		return 'WishCounterWidget'
	}

	static attacher( props: WishCounterWidgetProps, element: Element ) {
		preact.render(<WishCounterWidget { ...props } />, element );
	}

	private _userChangeHdl: CallbackUserController;
	private _tutorialChange: TutorialCallback;
}