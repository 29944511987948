import "../../../scss/components/help-bubble.scss";
import { h, ComponentChildren } from "preact";
import { Tutorial, TutorialCallback } from "./tutorial"
import { JasComponent, JasComponentState, JasComponentProps } from "../jas-component";
import { Controller } from "../../trip-planner/controller/controller";
const CloseIcon = require( "@fortawesome/fontawesome-free/svgs/regular/window-close.svg" );

type WaitActivator = () => (boolean | Promise<boolean> )
export type Step = number | TutorialBubbleStep

interface TutorialBubbleConfig {
	stepSeq?: number;
	hideContinueButton?: boolean;
	hide?: boolean;
	waitEvent?: WaitActivator;
	skip?: boolean;
	finish?: boolean;
	className?: string;
	position?: 'top' | 'left' | 'right' | 'bottom';
	align?: 'left' | 'right';
	/** Shifts the panel along the vertical axis by the indicated amount in CSS units */
	vShift?: string; 
	/** Shifts the panel along the horizontal axis by the indicated amount in CSS units */
	hShift?: string; 
	zIndex?: number;
	/** Shifts the arrow by the specified amount in CSS units */
	arrowShift?: string;
	width?: string;
}

export interface TutorialStepConfig {
	[ id: string ]: TutorialBubbleConfig
}

interface TutorialBubbleStep {
	[ step: number ]: TutorialBubbleConfig
}

interface TutorialBubbleProps extends TutorialBubbleConfig, JasComponentProps {
	step: Step;
	children: ComponentChildren;
}

interface TutorialBubbleState extends JasComponentState {
	currentStep: number
}

export class TutorialBubble extends JasComponent< TutorialBubbleProps, TutorialBubbleState > {

	componentDidMount() {
		this.setState({
			currentStep: Tutorial.instance.tutorialCurrentStep
		})

		this._tutorialChangeHandler = Tutorial.instance.onChange( event => {
			if ( event.step !== undefined ) {
				this.setState({ currentStep: event.step	})
			}
		})
	}
	
	componentWillUnmount() {
		if ( this._waitActivatorCalled ) {
			Tutorial.instance.resetTutorial()
		}
		Tutorial.instance.removeOnChange( this._tutorialChangeHandler );
	}
	
	async componentDidUpdate() {
		if ( !this.showBubble ) return
		const { waitEvent, skip } = this.config

		if( skip ) {
			Tutorial.instance.incTutorialStep()
			return
		}

		const element = ( this.base as Element ).getElementsByClassName( this.buildMainCSSClass() )[0]
		if ( !element ) {
			return;
		}

		const clientRect = element.getBoundingClientRect()
		const outside = clientRect.top < window.scrollX 
									|| clientRect.bottom > document.documentElement.clientHeight

		if ( outside ) {
			setTimeout(
				()=>element.scrollIntoView({ behavior: 'smooth', block: 'center'	})
			, 300);
		}

		if ( !this._waitActivatorCalled && waitEvent ) {
			this._waitActivatorCalled = true 
			await waitEvent()
			if ( this.showBubble ) {
				Tutorial.instance.incTutorialStep()
			}
			this._waitActivatorCalled = false
		}
	}

	getConfig( currentStep: number ): TutorialBubbleProps {
		const cfg = this.props.step[ currentStep ] || {};
		return { ...this.props, ...cfg }
	}

	get config() {
		return this.getConfig( this.state.currentStep )
	}

	render() {
		const { 
			position, className, children, vShift, hShift, arrowShift, width, 
			zIndex, hideContinueButton, align, finish
		} = this.config;
		const { locale } = this.state;
		const pos = position || 'top'
		const step = Tutorial.instance.tutorialCurrentStep
		const cssClasses = this.buildMainCSSClass( 
			`help-bubble step-${ step } ${ pos } align-${ align || 'left' } ${ className || '' }` 
		);

		return (
			<div style={ this.showBubble && { position: 'relative' }} onClick={ ()=> this.clicked() }>
				
				{ ( pos === 'bottom' ) && 
					children
				}

				{ this.showBubble &&
					<div className={ cssClasses }
						style={{
							maxWidth: width,
							width: width,
							marginTop: vShift,
							left: ( ( pos === 'right' || ( ( pos === 'top' || pos === 'bottom' ) && align !== 'right' ) ) && hShift ) || undefined,
							right: ( ( pos === 'left' || align === 'right' ) && hShift )|| undefined,
							zIndex: zIndex,
							'--arrow-shift': arrowShift || '0em'
						}} 
					>
					{ locale.steps[ step ] }

					{ !hideContinueButton &&
						<div className="continue-label clickable">
							<small onClick={ 
								()=> {
									if ( this.isBubbleForCurrentStep() ) {
										Tutorial.instance.incTutorialStep() 
									}
									if ( finish ) {
										Tutorial.instance.enableTutorial( false ) 
									}
								}
							}>
								{ finish? locale.finish : locale.continue }&raquo;
							</small>
						</div>
					}
					<span className="close-button clickable"
						onClick={ ()=>{
							Tutorial.instance.enableTutorial( false ) 
							this.setState({})
						}}
					>
						<CloseIcon fill="white"/>
					</span>
				</div>
				}
				
				{ ( pos === 'top' || pos === 'left' || pos === 'right' ) && 
					children
				}

			</div>		
		)
	}

	static propObserver<C extends Controller, P extends keyof C>( 
		controller: C, 
		property: P, 
		value?: C[P] | ( ( val: C[P] )=>boolean )
	) {
		if ( !controller ) return false;

		return new Promise<boolean>( resolve => {
			const hdl = controller.onChange( prop => {
				if ( ( prop[ property as string ] !== undefined && value === undefined) 
							|| ( value instanceof Function && value( prop[ property as string ] ) )
							|| ( value === prop[ property as string ] && value !== undefined ) ) {
					controller.removeOnChange( hdl )
					resolve( false )
				}
			})
		})
	}

	private isBubbleForCurrentStep() {
		const { currentStep } = this.state
		const { step } = this.config

		if ( typeof step === 'number' ) {
			return ( step === currentStep )
		}
		else {
			return ( step[ currentStep ] !== undefined )
		}
	}

	private get showBubble() {
		const { hide } = this.config
		const { locale } = this.state

		return locale.loaded && 
						!hide && 
						Tutorial.instance.showTutorialBubble() &&
						this.isBubbleForCurrentStep()
	}

	private async clicked() {
		const { step, waitEvent, hideContinueButton } = this.config;

		if ( this.showBubble && hideContinueButton && waitEvent === undefined ) {
			Tutorial.instance.incTutorialStep( step )
		}
	}	

	getClassName() {
		return 'TutorialBubble'
	}

	private _tutorialChangeHandler: TutorialCallback
	private _waitActivatorCalled: boolean
}