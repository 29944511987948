import { h } from "preact";
const LockIcon = require( "@fortawesome/fontawesome-free/svgs/solid/lock.svg" );
const LockOpenIcon = require( "@fortawesome/fontawesome-free/svgs/solid/lock-open.svg" );

interface LockerIconProps {
	onClick: ()=> void;
	locked: boolean;
}

export function LockerIcon( props: LockerIconProps ) {
	const { locked, onClick } = this.props;

	return (
		<span data-testid="locker-icon" onClick={ ()=>onClick() }>
			{
				locked
					?	<LockIcon data-testid="icon-lock-lock" fill="lightPink" width="1em" className="svg-inline icon-button"/>
					:	<LockOpenIcon data-testid="icon-lock-open" fill="lightPink" width="1em" className="svg-inline icon-button"/>
			}
		</span>
	)
}
