import { h } from "preact";
import { ControllerPanel, ControllerPanelProps, ControllerPanelState } from "./controller-panel";
import { ActivityController } from "../controller/activity-controller";
import { GetYourGuide } from "../../affiliates/controller/get-your-guide";
import { BookingPanel } from "../../bookings/view/booking-panel";
import { TourPanel } from "../../bookings/view/tour-panel";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";

export class ActivityPanel extends ControllerPanel< ControllerPanelProps, ControllerPanelState > {

	render() {
		const controller = this.props.controller as ActivityController;
		const getYourGuide = new GetYourGuide( controller.tour );

		return (
			<div data-testid={ this.buildTestId() }
				className={ this.buildMainCSSClass( 'controller-panel' ) }
			>

				{ this.renderHeaderPicture() }

				<div className="clickable" 
					onClick={ e =>this.activatePanel( controller, e ) }
				>
					<TutorialBubble	step={{ 24: this.tutorialConfig().activityDetails }}>

						<div className="">
							<BookingPanel
								controller={ controller.tour }
								headingLabel={ controller.name }
								affiliateProgram={ getYourGuide }
								active={ this.props.active }
								activatePanel={ ()=>this.activatePanel( controller ) }
							>
								<TourPanel
									controller={ controller }
								/>
							</BookingPanel>
						</div>

					</TutorialBubble>
				</div>
			</div>
		);
	}

	private tutorialConfig(): TutorialStepConfig {
		const { active, controller } = this.props

		return {
			activityDetails: {
				stepSeq: 24,
				hideContinueButton: true,
				hide: controller.owner.selectedItems.items[0]!=controller,
				skip: active
			}
		}
	}

	getClassName(): string {
		return 'ActivityPanel';
  }

}
