import { Component } from "preact";
import { Locale } from "../utils/locale";
import { Utils } from "../utils/utils";
import * as preact from "preact";

export interface JasComponentState {
	locale?: {
		[x:string]: string;
	}
}

export interface JasComponentProps {}

export abstract class JasComponent< P extends JasComponentProps ={}, S extends JasComponentState={} > extends Component< P, S > {
	abstract getClassName(): string;

	constructor( props: P ) {
		super( props );
		this.state = { locale: {} } as S;
	}

	getElementName() {
		return Utils.camelToSnakeCase( this.getClassName() );
	}

	buildMainCSSClass( classes: string = '' ) {
		return classes + ' ' + this.getElementName();
	}

	buildTestId() {
		return this.getElementName();
	}

	componentWillMount() {
		this.loadLocale();
	}

	protected async loadLocale() {
		const localEntries:{} = await Locale.instance.get( this.getClassName() );
		const genericEntries:{} = await Locale.instance.get( 'Generic' );
		this.setState({ locale: { ...localEntries, ...genericEntries } } );
	}

	static attachToDOM( typeName: string, attacher: ( props: {}, element: Element ) => void, props = {} ) {
		const elements = document.getElementsByTagName( typeName );

		for ( let i = 0; i < elements.length; i++ ) {
			const attributes = (elements[i] as HTMLElement).attributes;

			const properties = {...props }
			for ( let j=0; j < attributes.length; j++ ){
				properties[ Utils.snakeToCamelCase( attributes[j].name ) ] = attributes[j].value;
			}
			attacher( properties, elements[i] );
		}
		
		return elements.length
	}

	static detachFromDOM( typeName: string ) {
		const elements = document.getElementsByTagName( typeName );

		for ( let i = 0; i < elements.length; i++ ) {
			preact.render( null, elements[i], elements[i] );
		}
	}
}
