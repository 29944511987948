import { h, render } from "preact";
import { ControllerPanel, ControllerPanelProps, ControllerPanelState, WishListItemMap } from "./controller-panel";
import { TripController } from "../controller/trip-controller";
import { CountryController } from "../controller/country-controller";
import { CountryPanel } from "./country-panel";
import { PickerMasterView } from "../../components/master-detail-view/picker-master-view";
import { Utils } from "../../utils/utils";
import { DateWithLocker } from "../../components/date-with-locker";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { Wish } from "../../wishes/controller/wish";
import { InputTripName } from "./components/input-trip-name";
import { MarkdownBase } from '../../utils/markdown/markdown-base';
import { ViewListItem } from '../../components/item-picker/item-picker';

interface TripPanelState extends ControllerPanelState {
	showLoggin: boolean;
	showMemberPanel: boolean;
}

interface TripPanelProps extends ControllerPanelProps {
}

export class TripPanel extends ControllerPanel< TripPanelProps, TripPanelState >{
	render() {
		const { locale } = this.state;
		const { controller } = this.props;
		const { addCountry, addMoreCountries } = this.tutorialConfig()

		return (
			<div className={ this.buildMainCSSClass() }>

					{ this.renderTripData() }

					<TutorialBubble step={{ 4: addCountry, 32: addMoreCountries }}>

						<PickerMasterView
							addButtonLabel={ locale.addCountry }
							customItemLabel={ locale.customCountry }
							selectableItems={ this.getSelectableItems().map( wishItem => ({
								key: wishItem.key,
								label: wishItem.label,
								object: wishItem.object
							}))}
							selectedItems={ 
								controller?.selectedItems.map( (item: CountryController) => ({
									key: item.id,
									label: item.name,
									object: item
								})
							)}
							addItem={ wishItem => {
								if ( wishItem.key === wishItem.label ) {
									if ( wishItem.label.length > 2 ) {
										wishItem.key = Wish.normalizeCountry( wishItem.label )
									}
									else {
										wishItem.label = Wish.countryName( wishItem.key )
									}
								}
								if ( wishItem.label && wishItem.key ) {
									return controller.addSelected(
										new CountryController( wishItem.label, wishItem.key )
											.setRelatedPostUrl( wishItem.object && wishItem.object.post )
											.setPictureUrl( wishItem.object && wishItem.object.picture )
									) as CountryController
								}
								else {
									alert( MarkdownBase.replaceValue( locale.countryNotFound, {
										country: wishItem.label
									}) )
								}
							}}
							onDelete={ item => controller.deleteSelected( item.object ) }
							onMoveUp={ item => this.moveSelected( item, -1 ) }
							onMoveDown={ item => this.moveSelected( item, 1 ) }
							emptySelectableItemsMessage={ 
								this.getSelectableItemsMessage( locale.noWishFound ) 
							}
						>
							{
								(	country: CountryController, 
									active: boolean, 
									activateClick: () => void ) =>
										<CountryPanel 
											controller={country} 
											active={active} 
											activatePanel={ ()=> this.activatePanel( country ) }
										/>
							}
						</PickerMasterView>

					</TutorialBubble>
				</div>
		);
	}

	
	renderTripData() {
		const { locale } = this.state;
		const controller: TripController = this.props.controller as TripController;

		return (
				<div className="trip-data">
					<InputTripName
						controller={ controller }
						placeholder={ locale.tripNamePlaceholder }
						newTripPlaceholder={ locale.newTripPlaceholder }
					/>

					<TutorialBubble	step={{ 1: this.tutorialConfig().writeCityName }} position="bottom">

						<div className="dim-color">
							<label>{locale.tripOrigin}: </label>
							<input type="text"
								placeholder={ locale.tripOriginPlaceholder }
								value={ controller?.departure || '' }
								onInput={ e => controller.setDeparture( e.target['value'] ) }
							/>
						</div>
					
					</TutorialBubble>


					<div className="row multiline dim-color">

						<div  className="column align-center">
							<TutorialBubble step={{ 2: this.tutorialConfig().setDepartureDate }}>

								<div className="no-wrap">
									<label>{locale.departureDate}: </label>
								</div>
								<div>
									<input type="date"
										value={ 
											Utils.isValid( controller?.departureDate )
												? controller.departureDate.toISOString().slice( 0, 10 ) 
												: '' 
										}
										onInput={ e => controller.setDepartureDate( new Date( e.target['value']) ) }
									/>
								</div>
							</TutorialBubble>
						</div>

						<div className="column align-center">
							<TutorialBubble step={{ 3: this.tutorialConfig().returnDate }}>

								<DateWithLocker
									label={ locale.returnDate }
									onLockerClick={ 
										()=> controller.setLockStayLength( !controller.lockStayLength ) 
									}
									locked={ controller?.lockStayLength }
									onInput={ e => controller.setReturnDate( new Date( e.target['value']) ) }
									value={ 
										controller?.returnDate && controller.returnDate.toISOString().slice( 0, 10 ) || '' 
									}
									errorMessage={ 
										controller?.getErrorState().stayTooShort && locale.stayTooShort 
									}
								/>

							</TutorialBubble>
						</div>
					</div>
				</div>
		)
	}

	private tutorialConfig(): TutorialStepConfig {
		const controller = this.props.controller as TripController

		return {
			writeCityName: {
				stepSeq: 1,
				position: 'bottom',
				skip: controller?.departure?.length > 0,
				hideContinueButton: true,
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'departure' )
			},
			setDepartureDate: {
				stepSeq: 2,
				position: 'bottom',
				arrowShift: '2em',
				hideContinueButton: true,
				skip: Utils.isValid( controller?.departureDate ),
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'departureDate' )
			},
			returnDate: {
				stepSeq: 3,
				position: 'bottom',
				align: 'right',
				arrowShift: '15%'
			},
			addCountry: {
				stepSeq: 4,
				position: 'right',
				hideContinueButton: true,
				skip: controller?.selectedItems.length > 0,
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'selectedItems' ) 
			},
			addMoreCountries: {
				stepSeq: 32,
				position: 'bottom',
				hShift: '1em',
				skip: controller?.selectedItems.length > 1
			}
		}
	}

	protected retrieveSelectableItemsFromRelatedWishes(): ViewListItem< Wish >[] {
		const { controller } = this.props

		if ( !controller?.user ) return [];

		const wishes = controller.user.searchWishes(()=>true);
		let wishByCountry: WishListItemMap = {};

		wishes.forEach( wish => {
			const isCountryWishOnly = !( wish.city || wish.activity );
			if ( !( wishByCountry[ wish.country ] && wishByCountry[ wish.country ].object ) ) {
				wishByCountry[ wish.country ] = {
					key: wish.country,
					label: Wish.countryName( wish.country ),
					object: isCountryWishOnly? wish : undefined
				}
			}
		})

		let items = [];
		for ( var key in wishByCountry ) {
			items.push( wishByCountry[ key ] )
		}
		return items;
	}

	getClassName() {
		return 'TripPanel';
	}

	static attacher( props: TripPanelProps, element: Element ) {
		render(<TripPanel { ...props } />, element );
	}
}
