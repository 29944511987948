import "../../scss/widgets/travel-planner/travel-plan-widget.scss"
import { h } from "preact";
import * as preact from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../components/jas-component";
import { WaitingSpinner } from "../components/waiting-spinner";
import { TripController } from "./controller/trip-controller";
import { UserController, UserControllerEvent } from "../user/controller/user-controller";
import { Callback } from "../quarkum.js/delegate";
import { Tutorial, TutorialCallback } from "../components/tutorial/tutorial";
import { ViewPanel } from "./view/view-panel";
import { TutorialBubble, TutorialStepConfig } from "../components/tutorial/tutorial-bubble";
import { SaveTripButton } from "./view/components/save-trip-button";
import { LoginArea } from "./view/components/login-area";
import { LoginPanel, LoginAction } from "../user/view/login-panel";
// import { MadeWithLove } from "./view/components/made-with-love";

interface TravelPlanWidgetProps extends JasComponentProps {
	blog?: string
	modal?: boolean
	queryClose?: ()=>void
}

interface TravelPlanWidgetState extends JasComponentState {
	tripController: TripController;
	loading: boolean;
	saving: boolean;
	loginStarted: boolean;
}

export class TravelPlanWidget extends JasComponent< TravelPlanWidgetProps, TravelPlanWidgetState > {

	constructor( props: TravelPlanWidgetProps ) {
		super( props );
		if ( UserController.instance.user === undefined ) {
			this.state = { 
				loading: true,
				...this.state 
			};
		}
	}

	componentDidMount() {
		this._userCtrlHandler = UserController.instance.onUserControllerChange( event => {
			if ( event.userChange !== undefined ) {
				if ( event.userChange.userEvent?.showTutorial !== undefined ) {
					Tutorial.instance.enableTutorial( event.userChange.userEvent.showTutorial )
				}

				if ( event.userChange.user !== undefined ) {
					event.userChange?.user?.lastViewedTrip.setRelatedPostUrl( this.props.blog )
					Tutorial.instance.enableTutorial( event.userChange.user?.showTutorial )

					this.setState({
						loading: event.loading,
						tripController: event.userChange?.user?.lastViewedTrip
					});
				}

				if ( event.userChange.userEvent?.lastViewedTrip !== undefined ) {
					this.setState({
						loading: event.loading,
						tripController: event.userChange.userEvent.lastViewedTrip
					});
				}
			} 

		})

		this._tutorialHandler = Tutorial.instance.onChange( tutorialEvent =>{
			if ( tutorialEvent.enabled !== undefined ) {
				UserController.instance.user.setShowTutorial( tutorialEvent.enabled )
				this.setState({})
			}
		})

		UserController.instance.startListeningForUserChanges();
	}

	componentWillUnmount() {
		UserController.instance.removeOnChange( this._userCtrlHandler )
		Tutorial.instance.removeOnChange( this._tutorialHandler )
	}

	render() {
		const { modal, queryClose } = this.props
		const { loading, tripController, loginStarted } = this.state;

		return(
			<div className={ `wish-to-go ${ modal !== undefined? 'modal full-screen darker' : '' }` }>
				<div data-testid={ this.getElementName() }
					className={ this.buildMainCSSClass( 
						`${ modal !== undefined? '' : 'panel-border' }` 
					)}
					onMouseLeave={ ()=>UserController.instance.save() }
				>
					<WaitingSpinner waiting={ loading }>
							{ this.renderHeading() }

							{	( !tripController || loginStarted ) && 
								<LoginPanel 
									loginAction={ LoginAction.signUp }
									subHeading={ <p>{ this.state.locale?.loginFirst }</p>	}
									onFinish={ () => this.setState({ loginStarted: false }) }
									onStart={ () => this.setState({ loginStarted: true }) }
								/>
							}

							{ !loading &&
								<ViewPanel
									queryClose={ queryClose	}
									initialController={ tripController }
									key={ tripController?.id }
								/>
							}
					</WaitingSpinner>
	
					{/* <MadeWithLove /> */}
	
				</div>
			</div>
		);
	}

	renderHeading() {
		const { locale } = this.state;
		const { start, finish } = this.tutorialConfig()

		return (
			<div className="trip-panel-heading">
				<div className="title-strip row multiline vertical-center">
					<div className="column half">
						<h2>
							{locale.title}
						</h2>
						<SaveTripButton
							saveLabel={ locale.saveTrip }
							savingLabel={ locale.saving }
							savedLabel={ locale.saved }
						/>
					</div>
					<div  className="align-right column half">

						<TutorialBubble	step={{ 0: start,	33: finish }}>

							{ locale.loaded &&
								<LoginArea 
									loginLabel={ locale.login }
									signUpLabel={ locale.signUp }
									loggedInLabel={ locale.loggedIn }
								/>
							}

						</TutorialBubble>
					</div>
				</div>
			</div>
		)
	}

	private tutorialConfig(): TutorialStepConfig {
		return {
			start: {
				stepSeq: 0,
				position: 'bottom',
				align: 'right',
				hShift: '-0.5em'
			},
			finish: {
				stepSeq: 33,
				position: 'bottom',
				align: 'right',
				hShift: '-0.5em',
				finish: true
			}
		}
	}

	getClassName(): string {
    return 'TravelPlanWidget';
  }

	static attacher( props: TravelPlanWidgetProps, element: Element ) {
		preact.render(<TravelPlanWidget { ...props } />, element );
	}

	private _userCtrlHandler: Callback< UserController, UserControllerEvent >
	private _tutorialHandler: TutorialCallback
}
