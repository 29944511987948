import { Controller } from "./controller";
import { registerFactory } from "../../quarkum.js/persistent";
import { CityController } from "./city-controller";

@registerFactory( 'CountryController', ()=>new CountryController() )
export class CountryController extends Controller {

	protected childrenStayLength(): number {
		let days = 0;
		this.selectedItems.items.forEach( ( item: CityController, index ) => {
			days += item.stayLength;
			if ( index > 0 ) days += item.daysInTransit;
		})
		return days;
	}
}
