import "../../../scss/widgets/wish-widget.scss";
import { h } from "preact";
import * as preact from "preact";
import { Wish } from "../controller/wish";
import { WishData } from "../model/wish-data";
import { JasComponent, JasComponentState, JasComponentProps } from "../../components/jas-component";
import { User } from "../../user/user";
import { Auth } from "../../user/auth/auth";
import { DataSource } from "../../database/data-source";
import { LoginPanel, LoginAction } from "../../user/view/login-panel";
import { MarkdownToJSX } from "../../utils/markdown/markdown-to-jsx";
import { Config } from "../../config";
import { UserCredential } from "../../user/auth/user-auth-types";
const HeartIconRegular = require( "@fortawesome/fontawesome-free/svgs/regular/heart.svg" );
const HeartIconSolid = require( "@fortawesome/fontawesome-free/svgs/solid/heart.svg" );

export interface WishWidgetProps extends JasComponentProps{
	country?: string;
	city?: string;
	activity?: string;
	post?: string;
	picture?: string;
	label?: string;
	blogId?: string;
}

interface WishWidgetState extends JasComponentState {
	user: User;
	wish: Wish;
	showLoginPanel: boolean;
	error: string;
}

export class WishWidget extends JasComponent< WishWidgetProps, WishWidgetState >{

	componentDidMount() {
		this.state = {
			wish: this.fillWishData( null ),
			showLoginPanel: false,
			user: null,
			error: null
		}
		Auth.instance.onAuthStateChange( userCredential => this.userChanged( userCredential ) )
	}

	private async userChanged( userCredential: UserCredential ) {
		if ( userCredential ) {
			const user = await DataSource.instance.getUser( userCredential.id );
			user.setAuthUser( userCredential )

			const wish = await this.getStoredWish( user.id )
			this.setState({
				wish: wish,
				user: user
			})
		}
		else {
			this.setState({ user: null })
			this.setState({ wish: null })
		}
	}

	private async getStoredWish( userId: string ): Promise< Wish > {
		const { country, city, activity } = this.props;
		let wish: Wish = new Wish();

		if ( userId ) {
			try {
				wish = await WishData.get( userId, country, city, activity, this.getBlogId() );
			}
			catch( error ) {
				this.setState({ error: error });
			}
		}

		return this.fillWishData( wish );
	}

	private fillWishData( wish: Wish ) {
		wish = wish || new Wish();
		wish
			.fromObject(this.props)
			.setCountry(Wish.normalizeCountry(this.props.country))
			.setPost(this.props.post || window.location.href)
			.setPicture(this.props.picture)
			.setBlogId( this.getBlogId() )
		return wish;
	}

	private getBlogId() {
		const host = window.location.hostname
		return this.props.blogId || host.replace( 'www.', '' ).replace( '.', '-' )
	}

	icon() {
		const { wish } = this.state;

		if ( wish?.wishLevel ) {
			return <HeartIconSolid data-testid="icon-heart-solid" className="icon-button heart-icon" fill='red' width='1em'/>;
		}
		else {
			const wishColor = wish? '#fcc' : 'gray';
			const className = 'icon-button heart-icon';
			return <HeartIconRegular data-testid="icon-heart-regular" className={className} fill={wishColor} width='1em'/>;
		}
	}

	clicked() {
		const { wish, user } = this.state
		if ( !user ) {
			this.setState({ showLoginPanel: true })
		}

		if ( !wish ) return;

		this.setState( ( state: WishWidgetState ) => {
			state.wish.setWishLevel( state.wish.wishLevel? 0 : 5 );
			state.wish.setUserId( user.id );
			WishData.set( state.wish );

			return state;
		});
	}

  render() {
		const { error, locale, wish, showLoginPanel } = this.state;
		const label = this.props.label && this.props.label === "true"? locale.wishToGo : this.props.label;
		
		if ( showLoginPanel ){
			var subHeading = new MarkdownToJSX().parse( locale.subHeading, {
				tripPlannerLink: Config.getWebServerURL( Config.pages.travelPlanner ),
				learnMore: Config.getWebServerURL( Config.pages.bucketListTutorial )
			})
		}

    return (
			<span className="wish-to-go">
				<div className={ this.buildMainCSSClass()} >
					<div
						data-testid="wish-widget"
						className={ this.buildMainCSSClass( label && 'wish-widget-button') }
						style={{ color: error? 'red ':'' }}
						onClick={ ()=>this.clicked() }
						disabled={ wish === undefined }
					>
						<div className="inline-middle">
							{this.icon()}
						</div>
						{ ( error || label ) &&
							<div className="inline-middle wish-widget-label">
								{ error || label }
							</div>
						}
					</div>

					{ showLoginPanel &&
						<LoginPanel
							modal
							signUpHeading={ locale.pleaseSignUp }
							loginAction={ LoginAction.infoDialog }
							loginHeading={ locale.pleaseLogin }
							subHeading={ subHeading }
							onFinish={ ()=>this.setState({ showLoginPanel: false }) }
						/>
					}
				</div>
			</span>
		);
  }

	getClassName(){
		return 'WishWidget';
	}

	static attacher( props: WishWidgetProps, element: Element ) {
		preact.render(<WishWidget { ...props } />, element );
	}
}
