import { DataStream } from "./data-stream";
import { User, TripCollection } from "../user/user";

export class DataSource {
	protected constructor( streamFactory: ()=> DataStream ){
		if ( !streamFactory ) throw( new Error( 'You should register a data stream before using DataSource.') );
		this._stream = streamFactory();
	}

	static get instance() {
		return this._instance || ( this._instance = new this( this._streamFactory ) );
	}

	static registerDataStreamFactory( streamFactory: ()=>DataStream ) {
		this._streamFactory = streamFactory;
		this._instance = undefined;
	}

	get dataStream() {
		return this._stream;
	}

	getUser( userId: string ): Promise< User > {
		if ( this._lastUserId !== userId ) {
			this._lastUserId = userId;
			this._lastUser = this._stream.getUser( userId );
		}
		return this._lastUser;
	}

	saveUser( user: User ): Promise< void > {
		return this._stream.saveUser( user );
	}

	getTripCollection( userId: string ): Promise<TripCollection> {
		return this._stream.getTripCollection( userId );
	}

	private static _instance: DataSource = null;
	private static _streamFactory: ()=>DataStream;
	private _stream: DataStream;
	private _lastUser: Promise< User >;
	private _lastUserId: string;
}
