import { h } from "preact"
import { JasComponent, JasComponentState, JasComponentProps } from "../../components/jas-component";
import { Controller } from "../controller/controller";
import { TripController } from "../controller/trip-controller";
import { TripPanel } from "./trip-panel";
import { CountryController } from "../controller/country-controller";
import { CountryPanel } from "./country-panel";
import { CityController } from "../controller/city-controller";
import { CityPanel } from "./city-panel";
import { ActivityController } from "../controller/activity-controller";
import { ActivityPanel } from "./activity-panel";
import { Transport } from "../../bookings/controller/transport"
import { Accommodation } from "../../bookings/controller/accommodation";
import { ChangeCallback, ChangeEvent } from "../../quarkum.js/observable";
import { TransportPanel } from "../../bookings/view/transport-panel";
import { AccommodationPanel } from "../../bookings/view/accommodation-panel";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { Printable } from "./printable";
import { UserController } from "../../user/controller/user-controller";
let BackIcon = require( "@fortawesome/fontawesome-free/svgs/regular/arrow-alt-circle-left.svg")
let CloseIcon = require( "@fortawesome/fontawesome-free/svgs/regular/times-circle.svg")
let ShareIcon = require( "@fortawesome/fontawesome-free/svgs/solid/share-alt.svg")


interface ViewPanelState extends JasComponentState {
	controller: Controller;
	shareTrip: boolean;
	height: number;
}

interface ViewPanelProps extends JasComponentProps {
	initialController: Controller;
	queryClose?: () => void;
}

export class ViewPanel extends JasComponent<ViewPanelProps, ViewPanelState> {
	constructor( props: ViewPanelProps ) {
		super( props )
		this.state = { 
			controller: props.initialController,
			height: 0,
			shareTrip: false
		}
	}

	componentDidMount() {
		this._onChangeHandler = this.props.initialController?.onChange( 
			event => this.scrollToBottom( event )
		)

		window.addEventListener( 'resize', ()=>{
			this.setState({
				height: this.calculateHeight()
			})
		})
	}

	componentDidUpdate( _p, prevState: ViewPanelState ) {
		const currentController = this.state.controller

		if ( currentController !== prevState.controller ) {
			prevState.controller?.removeOnChange( this._onChangeHandler )
			this._onChangeHandler = currentController?.onChange( e => this.scrollToBottom( e ) )
		}
	}

	componentWillUnmount() {
		this.state.controller?.removeOnChange( this._onChangeHandler )
	}

	render() {
		const { controller, height, shareTrip } = this.state
		const { queryClose } = this.props
		const { 
			backFromAccommodation,  backFromTransport, backFromActivity, 
			toCountryPanel, toTripPanel,
		} = this.tutorialConfig()

		this._lastController = controller

		return(
			<div className={ this.buildMainCSSClass() }
				style={{
					height: height || this.calculateHeight()
				}}
			>

				<div className="controller-container">
					{ this.getControllerPanel( controller ) }
					{	shareTrip && <Printable/>	}
				</div>

				<div className="bottom-container">
					<TutorialBubble step={{ 
						15: backFromAccommodation, 22: backFromTransport, 
						26: backFromActivity, 27: toCountryPanel, 31: toTripPanel
					}}>


						{ !shareTrip &&
							<ShareIcon
								className="svg-inline icon-button" fill='grey' height="2.5em"
								onClick={ ()=>this.setState({ shareTrip: true }) }
							/>
						}

						{ queryClose &&
							<CloseIcon 
								data-testid="close-icon"
								className="svg-inline icon-button" fill='grey'
								onClick={ 
									()=>{
										UserController.instance.save()
										queryClose() 
									}
								}
							/>
						}

						{ ( controller?.owner || shareTrip ) &&
							<BackIcon 
								className="svg-inline icon-button" fill='grey'
								onClick={ ()=>this.activatePanel( controller.owner ) }
							/>

						}

					</TutorialBubble>
				</div>
			</div>
		
		)
	}

	private getControllerPanel( controller: Controller ) {
		const { shareTrip } = this.state

		if ( shareTrip ) return
		
		if ( controller instanceof TripController ) {
			return (
				<TripPanel 
					controller={ controller } 
					activatePanel={ newController => this.activatePanel( newController )}
				/>
			)
		}

		if ( controller instanceof CountryController ) {
			return (
				<CountryPanel 
					controller={ controller } 
					active={ true }
					activatePanel={ newController => this.activatePanel( newController )}
				/>
			)
		}

		if ( controller instanceof CityController ) {
			return (
				<CityPanel 
					controller={ controller } 
					active={ true }
					activatePanel={ newController => this.activatePanel( newController )}
				/>
			)
		}

		if ( controller instanceof ActivityController ) {
			return (
				<ActivityPanel 
					controller={ controller } 
					active={ true }
					activatePanel={ newController => this.activatePanel( newController )}
				/>
			)
		}

		if ( controller instanceof Transport ) {
			return (
				<TransportPanel 
					controller={ controller }
					active={ true }
					activatePanel={ newController =>this.activatePanel( newController ) }
				/>
			)
		}

		if ( controller instanceof Accommodation ) {
			return (
				<AccommodationPanel 
					controller={ controller } 
					active={ true }
					activatePanel={ newController => this.activatePanel( newController )}
				/>
			)
		}
	}

	private activatePanel( controller: Controller ) {
		const { shareTrip } = this.state

		this.setState({ 
			shareTrip: false,
			controller: shareTrip? this._lastController : controller, 
		})
	}

	private scrollToBottom( event: ChangeEvent<Controller> ) {
		const elem = ( this.base as HTMLElement )

		if ( event.addSelected ) {
			setTimeout(  //REVIEW: it could be placed on componentDidUpdate without setTimeout. Have to investigate more
			 ()=> elem.scrollTop = elem.scrollHeight
			, 100 )
		}

		if ( event.moveSelected ) {
			const cardElem = elem.getElementsByClassName('master-view')[0]
							.children.namedItem( event.moveSelected['item'].id )
			const direction = event.moveSelected['distance']
			const scrollHeight = direction < 0
				?	- cardElem.previousElementSibling.scrollHeight
				: cardElem.nextElementSibling.scrollHeight
		
			elem.scrollBy( 0, scrollHeight  )
		}
	}

	private calculateHeight() {
		const elem = ( this.base as HTMLElement )
		if ( !elem ) return 0

		const heatherHeight = elem.previousElementSibling.clientHeight
		const madeWithLoveHeight = elem.nextElementSibling?.clientHeight || 0
		const containerHeight = elem.parentElement.clientHeight

		return containerHeight - heatherHeight - madeWithLoveHeight - 1
	}

	private tutorialConfig(): TutorialStepConfig {

		return {
			backFromAccommodation: {
				stepSeq: 15,
				align: 'right',
				vShift: '-0.5em',
				hideContinueButton: true,
				arrowShift: '-0.3em'
			},
			backFromTransport: {
				stepSeq: 22,
				align: 'right',
				vShift: '-0.5em',
				hideContinueButton: true,
				arrowShift: '-0.3em'
			},
			backFromActivity: {
				stepSeq: 26,
				align: 'right',
				vShift: '-0.5em',
				hideContinueButton: true,
				arrowShift: '-0.3em'
			},
			toCountryPanel: {
				stepSeq: 27,
				align: 'right',
				vShift: '-0.5em',
				hideContinueButton: true,
				arrowShift: '-0.3em'
			},
			toTripPanel: {
				stepSeq: 31,
				align: 'right',
				vShift: '-0.5em',
				hideContinueButton: true,
				arrowShift: '-0.3em'
			}
		}
	}
	
	getClassName() {
		return 'ViewPanel'
	}

	private _onChangeHandler: ChangeCallback<Controller>
	private _lastController: Controller
}

