// Must be the first import
if (process.env.NODE_ENV==='development') {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
	require("preact/debug");
}

import { Initializer } from "./wtg-init";
import { DataSource } from "./database/data-source";
import { Auth } from "./user/auth/auth";
import { FirebaseStream } from "./database/firebase-stream";
import { FirebaseAuth } from "./user/auth/firebase-auth";
import { Admin } from "./user/admin-services/admin";
import { FirebaseAdmin } from "./user/admin-services/firebase-admin";
// import { FirebaseRESTStream } from "./database/firebase-rest-stream";
// import { AuthMock } from "../test/user/auth-mock";

// Auth.registerAuthServiceFactory( ()=>new AuthMock() )
// DataSource.registerDataStreamFactory( ()=> new FirebaseRESTStream() )

Auth.registerAuthServiceFactory( ()=>new FirebaseAuth() )
Admin.registerAdminServiceFactory( () => new FirebaseAdmin() )
DataSource.registerDataStreamFactory( ()=> new FirebaseStream() )

Initializer.wtgInit();
