import { h, Fragment } from "preact";
import { ControllerPanel, ControllerPanelProps, ControllerPanelState, WishListItemMap } from "./controller-panel";
import { PickerMasterView } from "../../components/master-detail-view/picker-master-view";
import { ActivityController } from "../controller/activity-controller";
import { ActivityPanel } from "./activity-panel";
import { CityController } from "../controller/city-controller";
import { AccommodationPanel } from "../../bookings/view/accommodation-panel";
import { TransportPanel } from "../../bookings/view/transport-panel";
import { InputStayingDays } from "./components/input-staying-days";
import { Locale } from "../../utils/locale";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { ViewListItem } from '../../components/item-picker/item-picker';
import { Wish } from '../../wishes/controller/wish';

export class CityPanel extends ControllerPanel< ControllerPanelProps, ControllerPanelState > {

	render() {
		const { locale } = this.state;
		const { active } = this.props;
		if( !locale.loaded ) return;

		const controller = this.props.controller as CityController;
		const { stayTooLong, stayTooShort } = controller.getErrorState();
		const { moveCity, deleteCity, cityDetails, setStayLength, stayLengthError } = this.tutorialConfig()

		return (
			<div
				className={ this.buildMainCSSClass( 'controller-panel' ) }>
				
				{ this.renderHeaderPicture() }

				<TutorialBubble	step={{ 29: moveCity, 30: deleteCity }}>

					<div className="row multiline">
							<div  className="clickable column half" onClick={ e => {
								this.activatePanel( controller, e ) 
							}}>
								<TutorialBubble	step={{ 8: cityDetails }}>
							
									<h2>{controller.name}</h2>
	
								</TutorialBubble>
							</div>

						<div className="align-right dim-color column half">

							<span>
								{ controller.arrivalDate() && 
									<Fragment>
										<small>
											{ `${ locale.arriving } ${ locale.on } ` }
										</small>
										<small className="no-wrap">
											{ `${ controller.arrivalDate().toLocaleDateString('default', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }` }
										</small>
									</Fragment>
								}
								{	controller.daysInTransit && locale.loaded && controller.arrivalDate()?
									` (+${ controller.daysInTransit } ${ Locale.pluralize( locale.day, controller.daysInTransit, locale.standardPlural ) })` : ''
								}
							</span>

							<TutorialBubble step={{	7: setStayLength, 13: stayLengthError }}>

								<InputStayingDays
									controller={ controller }
									errorMessage={
										stayTooLong? locale.stayTooLong :
										stayTooShort? locale.stayTooShort :
										controller.accommodation.hasError()? locale.accommodationError :
										controller.transport.hasError()? locale.transportError :
										controller.doChildrenHaveError()? locale.activityError : ''
									}
									errorSeverity={ this.highestErrorSeverity() }
								/>
							</TutorialBubble>
						</div>
					</div>

					{ active && this.renderContent() }

				</TutorialBubble>
			</div>
		);
	}

	renderContent() {
		const { locale } = this.state;
		const controller: CityController = this.props.controller as CityController

		return (
			<div className="panel-content">
				<div className="detail-view">

					<TransportPanel 
						controller={ controller.transport }
						activatePanel={ ()=>this.activatePanel( controller.transport ) }
					/>

				</div>
				<div className="detail-view">

					<AccommodationPanel 
						controller={ controller.accommodation }
						activatePanel={ ()=>this.activatePanel( controller.accommodation ) }
					/>

				</div>

				<div>
					<h3 className="city-panel-entry">

						{ locale.activities }

					</h3>
					<div>
						<TutorialBubble step={{	23: this.tutorialConfig().addActivity }}>

							<PickerMasterView
								addButtonLabel={ locale.addActivity }
								customItemLabel={ locale.customActivity }
								selectableItems={ this.getSelectableItems().map( wishItem => ({
									key: wishItem.key,
									label: wishItem.label,
									object: wishItem.object
								}))}
								selectedItems={ controller.selectedItems.map( activity => ({
									key: activity.id,
									label: activity.name,
									object: activity
								}))}
								addItem={ wishItem => {
									return controller.addSelected(
										new ActivityController( wishItem.label, wishItem.key )
										.setRelatedPostUrl( wishItem.object && wishItem.object.post )
										.setPictureUrl( wishItem.object && wishItem.object.picture )
									)
								}}
								onDelete={ item => controller.deleteSelected( item.object ) }
								onMoveUp={ item => this.moveSelected( item, -1 ) }
								onMoveDown={ item => this.moveSelected( item, 1 ) }
								emptySelectableItemsMessage={ 
									this.getSelectableItemsMessage( locale.noWishFound ) 
								}
							>
								{( activity: ActivityController, pActive: boolean, activateClick: () => void ) => (
									<ActivityPanel
										controller={activity}
										active={pActive}
										activatePanel={ ()=>this.activatePanel( activity ) }
								/>)}
							</PickerMasterView>
						</TutorialBubble>
					</div>
				</div>
			</div>
		);
	}

	private highestErrorSeverity() {
		const controller = this.props.controller as CityController

		let highestSeverity = controller.hasError()
		const accommodation = controller.accommodation.hasError()
		const transport = controller.transport.hasError()
		const children = controller.doChildrenHaveError()

		if ( accommodation > highestSeverity ) highestSeverity = accommodation;
		if ( transport > highestSeverity ) highestSeverity = transport;
		if ( children > highestSeverity ) highestSeverity = children;

		return highestSeverity
	}

	private tutorialConfig(): TutorialStepConfig {
		const controller = this.props.controller as CityController
		const active = this.props.active

		return {
			cityDetails: {
				stepSeq: 8,
				hideContinueButton: true,
				hide: !controller.isFirstCity(),
				skip: active
			},
			setStayLength: {
				stepSeq: 7,
				position: 'bottom',
				align: 'right',
				width: '35%',
				hShift: '1em',
				arrowShift: '2em',
				hideContinueButton: true,
				hide: !controller.isFirstCity(),
				skip: controller.stayLength > 0,
				waitEvent: ()=>TutorialBubble.propObserver( 
					controller, 'stayLength', ( value ) => value > 0 
				)
			},
			stayLengthError: {
				stepSeq: 13,
				position: 'bottom',
				align: 'right',
				arrowShift: '0.3em',
				hideContinueButton: true,
				waitEvent: ()=>TutorialBubble.propObserver( 
					controller, 'lockStayLength' 
				)
			},
			addActivity: {
				stepSeq: 23,
				position: 'right',
				hShift: '10em',
				hideContinueButton: true,
				skip: controller?.selectedItems.length > 0,
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'selectedItems' )
			},
			moveCity: {
				stepSeq: 29,
				hShift: '-1.5em',
				vShift: '-2em',
				hide: !controller.isFirstCity()
			},
			deleteCity: {
				stepSeq: 30,
				align: 'right',
				arrowShift: '-0.8em',
				hShift: '-1em',
				vShift: '-2em',
				hide: !controller.isFirstCity()
			}
		}
	}

	protected retrieveSelectableItemsFromRelatedWishes(): ViewListItem< Wish >[] {
		const { controller } = this.props

		const wishes = controller.user.searchWishes( wish => {
			return wish.activity
				&& wish.activity.length > 0
				&& wish.city === controller.name
				&& wish.country === controller.owner.isoCode
		});

		let wishByActivity: WishListItemMap = {};

		wishes.forEach( wish => {
			wishByActivity[ wish.activity ] = {
				key: wish.activity,
				label: wish.activity,
				object: wish
			}
		})

		let items = [];
		for ( var key in wishByActivity ) {
			items.push( wishByActivity[ key ])
		}
		return items;
	}

	getClassName(): string {
		return 'CityPanel';
  }
}
