import { persistent, persistentDate, persistentBoolean } from "../../quarkum.js/persistent";
import { Controller, ControllerErrorState, Severity } from "../../trip-planner/controller/controller";
import { Utils } from "../../utils/utils";

export interface BookingErrorState extends ControllerErrorState {
	checkInError: Severity;
	checkOutError: Severity;
	checkInMissmatchError: Severity;
	checkOutMissmatchError: Severity;
}

export abstract class Booking extends Controller {
	/**
	 * List the available booking categories. For a trip booking, posible categories are
	 * plane, train, boat... or for an accommodation can be hotel, guesthouse...
	 * @return An array of strings with the categories list
	 */
	abstract getBookingCategories(): string[];

	checkOut() {
		if ( this.lockStayLength || this.stayLength ) {
			return Utils.dateFrom( this.checkIn, this.stayLength );
		}
		else {
			return null;
		}
	}

	setBookingCategory( value: string ) {
		this.changeProp( 'bookingCategory', value );
		return this;
	}

	get bookingCategory() {
		return this._bookingCategory;
	}

	setBookingReference( value: string ) {
		this.changeProp( 'bookingReference', value );
		return this;
	}

	get bookingReference() {
		return this._bookingReference;
	}

	setPrice( value: number ) {
		this.changeProp( 'price', value );
		return this;
	}

	get price() {
		return this._price;
	}

	setCurrency( value: string ) {
		this.changeProp( 'currency', value );
		return this;
	}

	get currency() {
		return this._currency;
	}

	setCheckIn( value: Date ) {
		const changed = this.changeProp( 'checkIn', value );
		if ( changed ) {
			this._lockCheckIn = Utils.isValid( value );
		}
		return this;
	}

	get checkIn() {
		return this.getCheckIn();
	}

	protected getCheckIn(): Date {
		return this.lockCheckIn? this._checkIn : this.owner.arrivalDate();
	}

	setLockCheckIn( value: boolean ) {
		this.changeProp( 'lockCheckIn', value );
		return this;
	}

	get lockCheckIn() {
		return this._lockCheckIn;
	}

	setDestination( value: string ) {
		this.changeProp( 'destination', value );
		return this;
	}

	get destination() {
		return this._destination || ( this.owner && this.owner.name );
	}

	setDetails( value: string ) {
		this.changeProp( 'details', value );
		return this;
	}

	get details() {
		return this._details;
	}

	setDataUrl( value: string ) {
		this.changeProp( 'dataUrl', value );
		return this;
	}

	get dataUrl() {
		return this._dataUrl;
	}

	setConfirmed( value: boolean ) {
		if ( this.changeProp( 'confirmed', value ) ) {
			this.setCheckIn( this.checkIn );
			this.setLockCheckIn( true );
			this.setStayLength( this.stayLength );
			this.setLockStayLength( true );
		}
		return this;
	}

	get confirmed() {
		return this._confirmed;
	}

	getErrorState(): BookingErrorState {
		return {
			...super.getErrorState(),
			checkInError: Severity.false,
			checkOutError: Severity.false,
			checkInMissmatchError: Severity.false,
			checkOutMissmatchError: Severity.false
		};
	}

	@persistent private _bookingReference: string;
	@persistent private _price: number;
	@persistent private _currency: string;
	@persistentDate private _checkIn: Date;
	@persistentBoolean private _lockCheckIn: boolean;
	@persistent private _destination: string;
	@persistent private _details: string;
	@persistent private _dataUrl: string;
	@persistentBoolean private _confirmed: boolean;
	@persistent private _bookingCategory: string;
}
