import { Wish } from "../controller/wish";
import { DataSource } from "../../database/data-source";

export class WishData {

	static get( userId: string, country: string, city: string, activity: string, blogId: string ): Promise<Wish> {
		return new Promise( async ( resolve, reject ) => {
			const user = await DataSource.instance.getUser( userId );
			const normalizedCountry = Wish.normalizeCountry( country );
			if ( normalizedCountry ) {
				const wish = user.findWish( normalizedCountry, city, activity, blogId );
				resolve( wish );
			}
			else {
				reject('Cannot find "' + country + '" as a country. Please set the country attribute to a ISO Alpha 2 country code.' )
			}
		});
	}

	static async set( wish: Wish ) {
		let user = await DataSource.instance.getUser( wish.userId );
		if ( !user.id ) user.setId( wish.userId );
		if( wish.wishLevel ) {
			user.addWish( wish );
		}
		else {
			user.removeWish( wish );
		}
		DataSource.instance.saveUser( user );
	}
}
