import { Booking, BookingErrorState } from "./booking";
import { persistent, registerFactory } from "../../quarkum.js/persistent";
import { Severity } from "../../trip-planner/controller/controller";

@registerFactory( 'Tour', ()=> new Tour() )
export class Tour extends Booking {
	constructor( name?: string, isoCode?: string ) {
		super( name, isoCode );

		this.setBookingCategory( 'tour' );
	}

	setMeetingPointAddress( value: string ) {
    this.changeProp( 'meetingPointAddress', value );
		return this;
  }

	get meetingPointAddress() {
		return this._meetingPointAddress;
	}

	getBookingCategories(): string[] {
		return [
			'tour'
		]
	}

	checkOut() {
		return this.checkIn;
	}

	getErrorState(): BookingErrorState {
		if ( !( this.owner && this.owner.owner && this.checkIn && this.owner.arrivalDate() ) ) return super.getErrorState();

		return {
			...super.getErrorState(),
			checkInError: Severity.false,
			checkInMissmatchError:
						( this.checkIn < this.owner.arrivalDate()
				|| 	this.checkIn.getTime() > ( this.owner.arrivalDate().getTime() + this.owner.owner.stayLength * ( 24*60*60*1000 ) ) ) && Severity.error
		};
	}

	@persistent private _meetingPointAddress: string;
}
