import { Utils } from "./utils/utils"

export class Config {
	static pages = {
		travelPlanner: 'travel-planner/',
		bucketListTutorial: 'how-the-travel-bucketlist-works/',
		terms: '/legal/terms/',
		privacy: '/legal/privacy-policy/',
		bangkok: '/travel/thailand/bangkok/',
		barcelona: '/travel/spain/barcelona/',
		signedUp: '/verified/',
		localePath: '/locales',
		styleSheet: '/wish-to-go.css'
	}

	static serverDomain = process.env.SERVER_URL || ''
	static cdnDomain = process.env.CDN_URL || ''
	static betaDataServer = process.env.BETA || false
	static flagServerDomain = 'https://www.countryflags.io/'

	static getWebServerURL( path: string ) {
		return Utils.getFullUrl( Config.serverDomain, path )
	}

	static getCdnURL( path: string ) {
		return Utils.getFullUrl( Config.cdnDomain, path )
	}

	static getFlag( countryIsoCode: string ) {
		return Config.flagServerDomain + countryIsoCode + '/shiny/48.png'
	}

	static getMetaTagContent( metaName: string ): string {
		if ( typeof document === 'undefined') return ''

		const meta: HTMLMetaElement = document?.querySelector(`meta[name="${ metaName }"]`)
		return meta?.content
	}

	static getBrowserLanguage() {
		if ( typeof navigator === 'undefined') return null

		return navigator?.language.slice( 0, 2 )	|| 'en'
	}

	static locale = Config.getMetaTagContent( 'wtg-locale' ) || Config.getBrowserLanguage()
	
	static customEmailVerificationRedirect = Config.getMetaTagContent( 'wtg-sign-up-redirect' )

}