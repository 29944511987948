export class Utils {

	static getFullUrl( domain: string, resource: string ) {
		if ( resource[0] !=='/' ) resource = '/' + resource;
		if ( domain[ domain.length-1 ] === '/' ) domain = domain.slice( 0, domain.length -1 )

		return domain + resource;
	}

	static timePeriodError = 'TIME_PERIOD_ERROR';

  static objectToQueryString( obj: Object ): string {
    return '?' + Object.keys(obj)
      .map( k => encodeURIComponent( k ) + '=' + encodeURIComponent( obj[ k ] ) )
      .join('&');
  }

	static daysDiff( from: Date, to: Date ): number {
		if ( !Utils.isValid( from ) || !Utils.isValid( to ) ) return 0;
		return ( to.getTime() - from.getTime() ) / (24 * 60 * 60 * 1000);
	}

	static dateFrom( from: Date | number, days: number, hours: number = 0, minutes: number = 0, seconds: number = 0 ): Date {
		if ( typeof from !== 'number' ) {
			if ( !Utils.isValid( from ) ) return null;
			from = from.getTime()
		}
		return new Date( from + days * Utils.days_ms + hours * Utils.hour_ms + minutes * Utils.minutes_ms + seconds * 1000 )
	}

	static isWithinDay( testDate: Date | number, testDay: Date | number ) {
		const date = typeof testDate === 'number'? testDate : testDate.getTime()
		const day = typeof testDay === 'number'? new Date( testDay ) : testDay

		const minDay = day.setUTCHours( 0, 0, 0, 0 )
		const maxDay = day.setUTCHours( 23, 59, 59, 999 )

		return minDay <= date && date <= maxDay
	}

	static days_ms = 24 * 60 * 60 * 1000
	static hour_ms = 60 * 60 * 1000
	static minutes_ms = 60 * 1000

	static delay<T>( time: number, retVal: T ) {
		return new Promise<T>( resolve => setTimeout(()=>{
			resolve( retVal );
		}, time ))
	}

/**
 * Checks wether the passed date is a proper date
 * @param  date the date to check
 * @return      the date if it is valid or null if not. In this way you can assign directly the valid date or null
 */
	static isValid( date: Date ): boolean {
		return ( Boolean( date ) && !isNaN( date.getMilliseconds() ) );
	}

	static snakeToCamelCase( str: string ) {
		return str.replace(
			/([-_][a-z])/g,
			group => group.toUpperCase().replace('-', '').replace('_', '')
		)
	}

	static camelToSnakeCase( str: string, snakeChar: string = '-' ) {
		return str[0].toLowerCase() + str.slice(1).replace(/([A-Z])/g, g => snakeChar + g[0].toLowerCase() );

	}

	static capitalize( str: string ) {
		return str[0].toUpperCase() + str.slice(1)
	}

}
