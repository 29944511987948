import { h, Fragment } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../../../components/jas-component";
import { LockerIcon } from "../../../components/locker-icon";
import { Controller, Severity } from "../../controller/controller";
import { ErrorIcon } from "../../../components/error-icon";

interface InputStayingDaysProps extends JasComponentProps {
	controller: Controller;
	errorMessage?: string;
	errorSeverity: Severity;
}

interface InputStayingDaysState extends JasComponentState {
	showInput: boolean;
}

export class InputStayingDays extends JasComponent< InputStayingDaysProps, InputStayingDaysState > {

	renderSetStayingDays() {
		const { locale } = this.state
		const { errorMessage, errorSeverity } = this.props

		return (
			<Fragment>
				<a href="##" onClick={ ()=>this.setState({ showInput: true })	}>
					{ locale.setStayingDays }
				</a>
				<ErrorIcon message={ errorMessage } severity={ errorSeverity }/>
			</Fragment>
		);
	}

	renderInputDays() {
		const { locale } = this.state;
		const { controller, errorMessage, errorSeverity } = this.props;

		return (
			<div className={ this.buildMainCSSClass() }>

				<span> { locale.stayingFor } </span>

				<span className="no-wrap"> 

					<ErrorIcon 
						message={ errorMessage }
						severity={ errorSeverity }
					>
						<span className="input-days">

							<input type="number" min="0" max="99" aria-label={ locale.stayingFor }
								className={ errorMessage? ' error' : '' }
								value={controller.stayLength}
								onInput={ event => {
									controller.setStayLength(  Number( event.target['value'] ) );
									this.evaluateState()
								}}
							/>

							{ locale.days }

							<LockerIcon
								onClick={ ()=> {
									controller.lockStayLength?
										controller.setLockStayLength( false ) :
										controller.setStayLength( controller.stayLength, controller.stayLength != 0 )
									this.evaluateState();
								}}
								locked={ controller.lockStayLength }
							/>
						</span>
					</ErrorIcon>

				</span>
			</div>
		)
	}

	render() {
		return(
			<div className={ this.buildMainCSSClass() }>
				{
					!this.showInput()? this.renderSetStayingDays() : this.renderInputDays()
				}
			</div>
		);
	}

	private evaluateState() {
		const { controller } = this.props;

		this.setState({
			showInput: length>0 || controller.lockStayLength,
		});
	}

	private showInput(): boolean {
		const { controller } = this.props;

		return controller.lockStayLength
								|| this.state.showInput
								|| this.props.controller.stayLength > 0;
	}

	getClassName() {
		return( 'InputStayingDays' );
	}
}
