import { UserCustomData } from "../auth/user-auth-types";

export abstract class AdminService {
	abstract queryNewSubscription( userEmail: string, customData: UserCustomData ): Promise<any>
	abstract buyPlan( plan: string ): Promise<any>
}

export class Admin {
	protected constructor( adminFactory: () => AdminService ){
		if ( !adminFactory ) throw( new Error( 'You should register an administrator service before using Admin.') );
		this._adminService = adminFactory();
	}

	static registerAdminServiceFactory( adminFactory: ()=> AdminService ) {
		this._adminServiceFactory = adminFactory;
		this._instance = undefined;
	}

	static get instance() {
		return this._instance || (this._instance = new this( this._adminServiceFactory ) );
	}

	queryNewSubscription( userEmail: string, customData: UserCustomData ): Promise<any> {
		return this._adminService.queryNewSubscription( userEmail, customData )
	}

	buyPlan( plan: string ): Promise<any> {
		return this._adminService.buyPlan( plan )
	}

	private static _instance: Admin = null;
	private static _adminServiceFactory: () => AdminService;
	private _adminService: AdminService;
}