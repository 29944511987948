import { h, ComponentChildren, toChildArray, VNode, cloneElement } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../jas-component";
import { ViewListItem } from "../item-picker/item-picker";
let TrashBin = require( "@fortawesome/fontawesome-free/svgs/regular/trash-alt.svg" );
let AngleUp = require( "@fortawesome/fontawesome-free/svgs/solid/angle-up.svg" );
let AngleDown = require( "@fortawesome/fontawesome-free/svgs/solid/angle-down.svg" );

interface DetailViewProps<T> extends JasComponentProps {
	children: ComponentChildren;
	item: ViewListItem<T>;
	queryActivate: ( key: string ) => void;
	active: boolean;
	onDelete: ( item: ViewListItem<T> ) => void;
	onMoveUp: ( item: ViewListItem<T> ) => void;
	onMoveDown: ( item: ViewListItem<T> ) => void;
	haveToShowUp: ( item: ViewListItem<T> ) => boolean;
	haveToShowDown: ( item: ViewListItem<T> ) => boolean;
}

interface DetailViewState extends JasComponentState {
}

export class DetailView<T> extends JasComponent< DetailViewProps<T>, DetailViewState > {

	render () {
		const { item, onMoveDown, onMoveUp, onDelete, haveToShowUp, haveToShowDown } = this.props;
		const { active, children: childrenComps } = this.props
		const children = toChildArray( childrenComps ) as any[];

		return (
			<div className={ this.buildMainCSSClass() }>
				{ !active &&
					<div className="detail-icon-panel">
						<div className="move-arrows">
							{ haveToShowUp( item ) &&
								<span className="arrow-icon-text clickable"
									onClick={ () => onMoveUp( item )}
								>
									<AngleUp data-testid="icon-angle-up" fill="gray" width="1em" height="1.4em"
										className="svg-inline icon-button"
									/>
									{this.state.locale.goBefore}
								</span>
							}
							{ haveToShowDown( item ) &&
								<span className="arrow-icon-text clickable"
									onClick={ () => onMoveDown( item )}
								>
									<AngleDown data-testid="icon-angle-down" fill="gray" width="1em" height="1.4em"
										className="svg-inline icon-button"
									/>
									{this.state.locale.goAfter}
								</span>
							}
						</div>
						<div className="trash-bin-icon">
							<TrashBin data-testid="icon-trash-alt" fill="#f99898" width="1em" height="1em"
								className="svg-inline icon-button"
								onClick={ () => onDelete( item )}
							/>
						</div>
					</div>
				}
				<div className={ `detail-content`}>
					{ children.length? children[0]( item.object, this.props.active, this.props.queryActivate ) : <p>{item.label }</p> }
				</div>
			</div>
		);
	}

	getClassName() {
		return 'DetailView';
	}
}
