

export class AffiliateData {

	static getAffiliateId( _customerId: string, affiliateKey: string ) {
		// affiliateProgram = affiliateProgram[0].toLowerCase() + affiliateProgram.slice( 1 );
		const data: { [ affiliateProgram: string ]: string } = {
			jetRadar: '223921',
			bookingCom: '1767883',
			getYourGuide: 'JQ7YQM4'
		}
		return data[ affiliateKey ];
	}
}
