import { DataSource } from "../../database/data-source";
import { Delegate, Callback } from "../../quarkum.js/delegate";
import { User } from "../user";
import { Auth } from "../auth/auth";
import { TripController } from "../../trip-planner/controller/trip-controller";
import { ChangeCallback, ChangeEvent } from "../../quarkum.js/observable";

export interface UserControllerEvent {
	saving?: boolean;
	loading?: boolean;
	pendingChanges?: boolean;
	userChange?: {
		user?: User;
		userEvent?: ChangeEvent<User>;
	}
}

type UserControllerDelegate = Delegate<UserController, UserControllerEvent>
export type CallbackUserController = Callback< UserController, UserControllerEvent >

export class UserController {
	private constructor() {
		this.onUserControllerChangeDelegate = new Delegate<UserController, UserControllerEvent>()
		// this.startListeningForUserChanges()
	}
	
	static get instance() {
		return this._instance || ( this._instance = new this() );
	}

	//REVIEW: Should be private
	startListeningForUserChanges() {
		//TODO: Triggers many times. I think one for widget
		Auth.instance.onAuthStateChange( async userCredential =>{
			let nextUser: User = null;

			this.onUserControllerChangeDelegate.fire({ loading: true }, this );

			try {
				if ( userCredential ) {
					nextUser = await DataSource.instance.getUser( userCredential.id );
					
					const newUser: boolean = !nextUser

					nextUser.setAuthUser( userCredential )
					
					if ( !nextUser.lastViewedTrip) {
						if ( nextUser.tripCollection.length ) {
							nextUser.setLastViewedTrip( nextUser.tripCollection[0] );
						}
						else {
							const trip = nextUser.addTrip( new TripController() );
							nextUser.setLastViewedTrip( trip );
						}
					}

					if ( newUser ) DataSource.instance.saveUser( nextUser )
				}
			} finally {
				this.setupNotificationListeners( nextUser, this._user )
				this._user = nextUser;
				this.onUserControllerChangeDelegate.fire({ 
					loading: false,
					pendingChanges:false,
					userChange: { user: nextUser }
				}, this );
			}
		})
	}

	get user() {
		return this._user
	}

	async save() {
		if ( !this.pendingChanges || !this.user ) {
			return;
		}

		if ( this.timer ) {
			clearTimeout( this.timer );
			this.timer = null;
		}

		this.onUserControllerChangeDelegate.fire( { saving: true }, this );
		
		await DataSource.instance.saveUser( this.user );
		
		this.pendingChanges = false;
		this.onUserControllerChangeDelegate.fire( { saving: false }, this );
	}

	onUserControllerChange( cb: CallbackUserController ) {
		return this.onUserControllerChangeDelegate.connect( cb )
	}

	removeOnChange( cb: CallbackUserController ) {
		this.onUserControllerChangeDelegate.disconnect( cb )
	}

	async cloneTrip( userId: string, tripName: string ) {
		const tripCollection = await DataSource.instance.getTripCollection( userId )
		const trip = User.getTrip( tripCollection, tripName )
		if ( trip ) {
			this.user.addTrip( trip.setId( new TripController().id ) )
		}
		else throw new Error( 'Trip not Found' )
	}

	private setupNotificationListeners( user: User, lastUser: User ) {
		if ( user ) {
			this._userChangeHdl = user.onChange( event => {
				if ( !( event.addWish || event.wishCount !== undefined || event.removeWish ) ) {
					this.pendingChanges = true
					this.startAutoSaveTimer();
				}
				this.onUserControllerChangeDelegate.fire({ 
					userChange: { userEvent: event }
				}, this );
				if ( event.lastViewedTrip ) {
					this.changeSaveListener( event.lastViewedTrip )
				}
			})
		}
		else {
			lastUser?.removeOnChange( this._userChangeHdl )
		}

		if ( user?.lastViewedTrip ) {
			
			this.changeSaveListener( user.lastViewedTrip )

			addEventListener( 'beforeunload', event => {
				if ( this.pendingChanges ) {
					event.preventDefault()
					event.returnValue = ''
				}
			});
			
		}
	}

	private changeSaveListener( trip: TripController ) {
		trip.onQuerySave = () => {
			this.pendingChanges = true;
			this.startAutoSaveTimer();
			return false;
		}
	}

	private startAutoSaveTimer() {
		if ( !this.timer ) {
			this.timer = setTimeout(()=>{
				this.save();
			}, 60000 ) as any;
		}
	}

	private set pendingChanges( value: boolean ) {
		this._pendingChanges = value;
		this.onUserControllerChangeDelegate.fire({ pendingChanges: value }, this );
	} 

	private get pendingChanges() {
		return this._pendingChanges;
	}

	private _user: User;
	private _userChangeHdl: ChangeCallback<User>;
	private _pendingChanges: boolean;
	private timer: NodeJS.Timeout;
	private onUserControllerChangeDelegate: UserControllerDelegate
	private static _instance: UserController = null;
}