import "../../../scss/components/master-view.scss"
import { h, JSX } from "preact";
import { ItemPickerDropdown } from "../item-picker/item-picker-dropdown";
import { MasterView } from "./master-view";
import { JasComponent, JasComponentProps, JasComponentState } from "../jas-component";
import { ItemPickerListView } from "../item-picker/item-picker-list-view";
import { ViewListItem } from "../item-picker/item-picker";
let PlusIcon = require( "@fortawesome/fontawesome-free/svgs/solid/plus.svg" );

interface PickerMasterViewProps<T, S> extends JasComponentProps {
	addButtonLabel: string;
	customItemLabel?: string;
	selectableItems: ViewListItem<S>[];
	selectedItems: ViewListItem<T>[];
	addItem: ( item: ViewListItem<S> ) => T;
	onDelete: ( item: ViewListItem<T> ) => void;
	onMoveUp: ( item: ViewListItem<T> ) => ViewListItem<T>;
	onMoveDown: ( item: ViewListItem<T> ) => ViewListItem<T>;
	emptySelectableItemsMessage?: JSX.Element;
}

interface PickerMasterViewState<T> extends JasComponentState {
	lastAdded: T
}

export class PickerMasterView<T, S> extends JasComponent< PickerMasterViewProps<T, S>, PickerMasterViewState<T> > {

	render() {
		return (
			<div data-testid={ this.buildTestId() } className={ this.buildMainCSSClass() }>
				<MasterView
					listSource={ this.props.selectedItems }
					onDelete={ ( item: ViewListItem<T> ) => this.props.onDelete( item ) }
					onMoveUp={ ( item: ViewListItem<T> ) => this.props.onMoveUp( item ) }
					onMoveDown={ ( item: ViewListItem<T> ) => this.props.onMoveDown( item ) }
				>
					{this.props.children}
				</MasterView>
				{/* <ItemPickerListView
					items={ this.props.selectableItems }
					label={ this.props.addButtonLabel }
					onSelectItem={ item => this.props.addItem( item ) }
					emptyItemsMessage={ this.props.emptySelectableItemsMessage }
				/> */}
				<ItemPickerDropdown
					dropUp={ this.props.selectedItems.length > 1 }
					items={ this.props.selectableItems }
					label={ this.props.addButtonLabel }
					customItemLabel={ this.props.customItemLabel }
					onSelectItem={ item => {
						const added = this.props.addItem( item ) 
						this.setState({ lastAdded: added })
					}}
					icon={<PlusIcon className="svg-inline icon-button" fill='grey' width='1rem'/>}
					emptyItemsMessage={ this.props.emptySelectableItemsMessage }
				/>
			</div>
		);
	}

	getClassName() {
		return 'PickerMasterView';
	}
}
