import { Delegate, Callback } from "./delegate";

export type ChangeEvent<T extends Observable> = {
	[ P in keyof T ]?: T[P];
}

export type ChangeCallback<T extends Observable> = Callback< Observable, ChangeEvent<T> >

export class Observable {
	constructor() {
		this._onChange = new Delegate< Observable, ChangeEvent<this> >();
	}

	onChange( cb: ChangeCallback<this> ) {
		return this._onChange.connect( cb );
	}

	removeOnChange( cb: ChangeCallback<this> ) {
		this._onChange.disconnect( cb );
	}

	protected changeProp<P extends keyof this>( propName: P, value: this[ P ] ): boolean {
		const pName = '_'+propName;
		if ( this[ pName ] !== value ) {
			this[ pName ] = value;
			this.notifyChange({ [ propName ]: value } as any, this );
			return true;
		}
		return false;
	}

	protected notifyChange( event: ChangeEvent<this>, observable: Observable ) {
		this._onChange.fire( event, observable );
	}

	private _onChange: Delegate< Observable, ChangeEvent<this> >;
}