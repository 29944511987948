import "../../../scss/user/login-panel.scss";
import { h, render, JSX, Fragment } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../../components/jas-component";
import { Auth, AuthErrorCode } from "../auth/auth";
import { Link } from "../../components/link";
import { WaitingSpinner } from "../../components/waiting-spinner";
import { Config } from "../../config";
import { Admin } from "../admin-services/admin";
import { UserCredential } from "../auth/user-auth-types";
const Facebook = require('@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg');
const Google = require('@fortawesome/fontawesome-free/svgs/brands/google.svg')

export enum LoginAction { login='login', signUp='signUp', infoDialog='iDialog' };

export interface LoginPanelProps extends JasComponentProps {
	loginAction: LoginAction;
	modal?: boolean;
	loginHeading?: string; 
	signUpHeading?: string;
	dialogHeading?: string;
	dialogText?: string | JSX.Element;
	subHeading?: string | JSX.Element;
	onStart?: ()=>void;
	onSuccess?: ( user: UserCredential ) => void;
	onFailure?: ( error: AuthErrorCode )=> void;
	onFinish?: ()=>void;
}

interface LoginPanelState extends JasComponentState {
	name: string;
	email: string;
	password: string;
	loginAction: LoginAction;
	headerMessage: string;
	successMessage: string;
	errorMessage: string;
	waiting: boolean;
	userCredential: UserCredential;
}

export class LoginPanel extends JasComponent<LoginPanelProps, LoginPanelState>{
	constructor( props: LoginPanelProps ) {
		super( props ) 
		this.state = {
			loginAction: props.loginAction,
			... this.state
		}
	}

	render() {
		const { locale, loginAction } = this.state;
		const { modal, onFinish } = this.props;

		if ( !locale ) return;

		return (
			<div className={ modal? 'modal full-screen' : '' }>
				<div className={ this.buildMainCSSClass( modal? 'modal-container' : '' ) }>
					{	loginAction === LoginAction.infoDialog
						? this.infoDialog()
						: this.loginPanel()
					}
					{ modal &&
						<button className="close-modal"
							onClick={ ()=> onFinish && onFinish() }
						>
						</button>
					}
				</div>
			</div>
		)
	}

	private loginPanel() {
		const { locale, waiting } = this.state;
		const { subHeading } = this.props;

		return (
			<Fragment>
				<h2 className="align-center modal-heading">{ this.getHeadingStr() }</h2>
				{ subHeading &&
					<div className="sub-heading">{ subHeading }</div>
				}
				<div className="login-container">
					<WaitingSpinner waiting={ waiting }>
						{ this.loginEmailPanel() }
						<hr/>
						{ this.loginProviderButtons() }
					</WaitingSpinner>
				</div>
				<p className="dim-color agreement-notice">
					{ locale.agreement }&#32;
					<Link to={ Config.pages.terms }>{locale.termsOfService}</Link>
					&#32;{locale.and}&#32;
					<Link to={ Config.pages.privacy }>{locale.privacyPolicy}</Link>.
				</p>

				{ this.errorPanel() }

			</Fragment>
		)
	}

	private errorPanel() {
		const { successMessage, errorMessage, headerMessage, loginAction, locale, userCredential } = this.state;
		const { onFinish, onSuccess } = this.props;
		const nextLoginAction = ( loginAction === LoginAction.signUp )
															? LoginAction.login : LoginAction.signUp

		if ( successMessage || errorMessage ) {
			return (
				<div className={ 'login-message modal ' + ( errorMessage? 'error' : 'info' ) }>
					<div>
						<h2>
							{ errorMessage
								? locale.errorMessageHeader
								: headerMessage
							}
						</h2>
						<div>{ successMessage || errorMessage }</div>
						<button onClick={ ()=> {
							const wasSuccess = successMessage

							this.setState({
								successMessage: '',
								errorMessage:''
							})
							if ( wasSuccess ) {
								if ( onSuccess ) onSuccess( userCredential );
								if ( onFinish ) onFinish();
							}
						}}>
							{ locale.continue }
						</button>
						{ errorMessage &&
							<button
								onClick={ ()=> this.setState({
									errorMessage: '',
									loginAction: nextLoginAction
								})}
							>
								{ locale[ nextLoginAction ] }
							</button>
						}
					</div>
				</div>
			)
		}
	}

	private getHeadingStr() {
		const { loginHeading, signUpHeading } = this.props;
		const { loginAction, locale } = this.state;

		let headingProps: LoginAction[] = [];
		headingProps[ LoginAction.login ] = loginHeading;
		headingProps[ LoginAction.signUp ] = signUpHeading;

		return headingProps[ loginAction ] || locale[ loginAction ]
	}

	private loginEmailPanel() {
		const { name, email, password, locale, loginAction } = this.state;

		return (
			<form className="login-email-panel">
				{ loginAction === LoginAction.signUp &&
					<input
						placeholder="Name"
						type="text"
						autoComplete="name"
						onInput={ event => this.setState({ name: event.target[ 'value' ] })}
						value={name}
					/>
				}
				<input
					placeholder="Email"
					type="email"
					autoComplete="username"
					onInput={ event => this.setState({ email: event.target[ 'value' ] })}
					value={email}
				/>
				<input
					placeholder="Password"
					type="password"
					autoComplete="current-password"
					onInput={event => this.setState({ password: event.target[ 'value' ] })}
					value={password}
				/>
				<button	
					className="button"
					onClick={ event => {
						event.preventDefault()
						this.signWithEmail( name, email, password )	
					}}
				>
					{ locale[ loginAction ] }
				</button>

				<a className="clickable"
					onClick={ ()=>this.setState( ( prevState ) => { 
						return ({
							loginAction: prevState.loginAction==LoginAction.login? LoginAction.signUp : LoginAction.login 
						}) 
					})}
				>
					{ loginAction == LoginAction.login? locale.notAMember : locale.alreadyAMember }
				</a> 
			</form>
		)
	}

	private loginProviderButtons() {
		const { locale } = this.state;

		return (
			<div className="login-provider-buttons">
				<button 
					className="button"
					onClick={ () => this.signWithProvider( 'google' )	}
				>
					{ locale.continueWithGoogle }
					<Google className="svg-inline" height="1em" fill="whitesmoke"/>
				</button>
				{/* <button 
					className="button"
					onClick={ () => this.signWithProvider( 'facebook' ) }
				>
					{ locale.continueWithFacebook }
					<Facebook className="svg-inline" height="1em" fill="whitesmoke"/>
				</button> */}
			</div>
		)
	}

	private signWithEmail( name: string, email: string, password: string) {
		const { loginAction, locale } = this.state;
		const { onSuccess, onFailure, onFinish, onStart } = this.props;
		
		if ( onStart ) onStart()

		let promise: Promise<UserCredential>

		if ( loginAction === LoginAction.signUp ) {
			const params = {
				name: name,
				email: email,
				redirectURL: Config.customEmailVerificationRedirect
			}

			console.log( params )

			const queryString = Object.keys( params ).filter( key => params[key]).map( 
				key => `${ encodeURIComponent(key) }=${ encodeURIComponent( params[ key ] ) }`
			).join('&')
		
			promise = Auth.instance.signUp({ 
				authProvider: 'email', 
				name: name,
				email: email, 
				password: password,
				verificationLink: Config.getWebServerURL( Config.pages.signedUp ) + '?' + queryString,
			})
		}
		else {
			promise = Auth.instance.login({ 
				authProvider: 'email', 
				email: email, 
				password: password,
			})
		}

		promise.then( userCredential => {

			if ( loginAction === LoginAction.signUp ) {
				this.setState({ 
					headerMessage: locale.verificationLinkSentHeader,
					successMessage: locale.verificationLinkSent,
					userCredential: userCredential
				})
			}
			else {
				if ( onSuccess ) onSuccess( userCredential )
				if ( onFinish ) onFinish()
			}

		}).catch( error => {

			this.setState({ errorMessage: locale[ error.code || error ] || error.message || error })
			console.error( 'Cannot sign up. Reason: ', error )
			if ( onFailure ) onFailure( error );

		})
	}

	private signWithProvider( provider: string ) {
		const { onSuccess, onFailure, onFinish, onStart } = this.props;
		const { locale } = this.state

		if ( onStart ) onStart()

		this.setState({ waiting: true })

		Auth.instance.signUp({ 
			authProvider: provider,
		}).then( async userCredential => {

			if ( !userCredential.customData.subscriptionPlan ) {
				userCredential.customData.subscriptionPlan = 'user'
				userCredential.customData.gdprConsent = true
				await Admin.instance.queryNewSubscription( userCredential.email, userCredential.customData )
			}

			this.setState({ userCredential: userCredential })
			if ( onSuccess ) onSuccess( userCredential );

		}).catch( error => {

			this.setState({ errorMessage: locale[ error.code || error ] || error.message || error })
			console.error( 'Cannot sign up. Reason: ', error ) 
			if ( onFailure ) onFailure( error );

		}).finally(()=> {

			this.setState({ waiting: false })
			onFinish && onFinish() 

		})
	}

	private infoDialog() {
		const { modal, dialogHeading, dialogText, signUpHeading, loginHeading, subHeading } = this.props
		const { locale } = this.state

		return(
			<div className={ `login-info-dialog ${ modal? 'modal-container' : '' }` }>
				<h2 className="align-center modal-heading">{ dialogHeading || signUpHeading || loginHeading }</h2>
				<p>{ dialogText || subHeading }</p>
				<div className="buttons row align-center">
					<div  className="column">
						<button
							onClick={ ()=>this.setState({ loginAction: LoginAction.login }) }
						>
							{ locale.login }
						</button>
					</div>
					<div  className="column">
						<button
							onClick={ ()=>this.setState({ loginAction: LoginAction.signUp }) }
						>
							{ locale.signUp }
						</button>
					</div>
				</div>
			</div>
		)
	}

	getClassName() {
		return 'LoginPanel'
	}

	static attacher( props: LoginPanelProps, element: Element ) {
		render(<LoginPanel { ...props } />, element );
	}
}
