

export type Callback< SenderT, EventT > = ( event: EventT, sender: SenderT )=> void;

/**
 * Implements event listeners for multiple listeners or event handlers
 */
export class Delegate< SenderT, EventT > {
	constructor() {
		this.callbacks = [];
	}

	/**
	 * Calls all registered event listeners
	 * @param sender instance of the event sender.
	 * @param event event to send.
	 */
	fire( event: EventT, sender: SenderT )	{
		this.callbacks.forEach( ( callback: Callback< SenderT, EventT > ) => callback( event, sender ) );
	}

	/**
	 * Adds a listener or event handler to the listeners container.
	 * @param eventHdl callable object to act as event handler.
	 * @returns the event handler
	 */
	connect( eventHdl: Callback< SenderT, EventT > ) {
		this.callbacks.push( eventHdl );
		return eventHdl;
	}

	/**
	 * Removes a listener or event handler from the listeners container.
	 * @param eventHdl the same event handler used in connect.
	 */
	disconnect( eventHdl: Callback< SenderT, EventT > ) {
		this.callbacks = this.callbacks.filter( callback => {
			return callback !== eventHdl
		});
	}

	private	callbacks: Callback< SenderT, EventT >[];
}
