import { Controller } from "./controller";
import { registerFactory, persistent } from "../../quarkum.js/persistent";
import { Wish } from "../../wishes/controller/wish";
import { Tour } from "../../bookings/controller/tour";
import { ViewListItem } from "../../components/item-picker/item-picker";

@registerFactory( 'ActivityController', ()=>new ActivityController() )
export class ActivityController extends Controller{
	constructor( name?: string, isoCode?: string ) {
		super( name, isoCode );

		this._tour = new Tour().setOwner( this );
	}

	setTour( value: Tour ) {
		if ( value ) {
			value.setOwner( this );
		}

		this.changeProp( 'tour', value )
		return this;
	}

	get tour() {
		return this._tour;
	}

	loaded() {
		super.loaded();
		if ( this.tour ) this.tour.setOwner( this )
	}

	getErrorState() {
		return {
			...super.getErrorState(),
			...this.tour.getErrorState()
		}
	}

	doChildrenHaveError() {
		const highestSeverity = super.doChildrenHaveError()
		const tourSeverity = this.tour.hasError()
		if ( tourSeverity > highestSeverity ) return tourSeverity
		else return highestSeverity
	}

	@persistent private _tour: Tour;
}
