import { Controller } from "./controller";
import { persistent, persistentDate, registerFactory } from "../../quarkum.js/persistent";
import { Utils } from "../../utils/utils";
import { CityController } from "./city-controller";

@registerFactory( 'TripController', ()=>new TripController() )
export class TripController extends Controller {

	setDepartureDate( date: Date ) {
		this.changeProp( 'departureDate', new Date( date ) )
		return this;
	}

	get departureDate() {
		return this._departureDate;
	}

	setReturnDate( date: Date ) {
		this.setStayLength( Utils.daysDiff( this._departureDate, date ) );
		return this;
	}

	get returnDate() {
		return Utils.isValid( this._departureDate )? Utils.dateFrom( this._departureDate, this.stayLength ) : undefined;
	}

	protected getStayLength() {
		const firstCity = this.selectedItems.items[0] && this.selectedItems.items[0].selectedItems.items[0] as CityController;
		const daysInTransit = firstCity? firstCity.daysInTransit : 0;
		return this.lockStayLength
			?	super.getStayLength()
			: this.childrenStayLength() + daysInTransit;
	}

	setDeparture( place: string ) {
		this.changeProp( 'departure', place )
		return this;
	}

	get departure() {
		return this._departure;
	}

	@persistentDate private _departureDate: Date;
	@persistent private _departure: string;
}
