import { h } from "preact";
import { BookingDatesPanel } from "./booking-dates-panel";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { ControllerPanelProps, ControllerPanelState, ControllerPanel } from "../../trip-planner/view/controller-panel";
import { Accommodation } from "../controller/accommodation";
import { BookingCom } from "../../affiliates/controller/booking-com";
import { BookingPanel } from "./booking-panel";
import { CityController } from "../../trip-planner/controller/city-controller";

export interface AccommodationPanelProps extends ControllerPanelProps {
	controller: Accommodation;
}

export interface AccommodationPanelState extends ControllerPanelState {
}

export class AccommodationPanel extends ControllerPanel< AccommodationPanelProps, AccommodationPanelState > {

	render() {
		const { locale } = this.state;
		const { active } = this.props;
		const accommodation = this.props.controller;
		const bookingCom = new BookingCom( accommodation );
		const { datesAutoCalculated, clickErrorIcon } = this.tutorialConfig()

		let accommodationHeadingText: string
																	
		if ( accommodation.bookingCategory ) {
			accommodationHeadingText = `${locale.stayingAt} ${accommodation.name || ''} ${locale[ accommodation.bookingCategory]}`
		}
		else {
			accommodationHeadingText = locale.accommodation;
		}

		return (
			<div className={this.buildMainCSSClass( 'controller-panel' ) }>

				<BookingPanel
					controller={ accommodation }
					pickerLabel={ locale.accommodationPickerLabel }
					affiliateProgram={ bookingCom }
					headingLabel={ accommodationHeadingText }
					active={ active }
					activatePanel={ ()=>this.activatePanel( accommodation ) }
				>

					<div>
						{ this.renderGenericAccommodationInfo() }

						<TutorialBubble	step={{ 11: datesAutoCalculated, 12: clickErrorIcon }}>
						
							<BookingDatesPanel
								booking={ accommodation }
								startDateLabel={ locale.checkIn }
								endDateLabel={ locale.checkOut}
								errorMessages={ locale }
							/>
						
						</TutorialBubble>
					</div>
				</BookingPanel>
			</div>
		)
	}

	private renderGenericAccommodationInfo() {
		const { locale } = this.state;
		const accommodation = this.props.controller;
		const accommodationNameLegend = locale[ accommodation.bookingCategory || 'hotel'] 
																			+ ' ' + locale.name;
		return (
			<div>
				<div>
					<label>{ accommodationNameLegend }: <input placeholder={ accommodationNameLegend }
						type="text"
						value={ accommodation.name }
						onInput={ event => accommodation.setName( event.target['value'] ) }/>
					</label>
				</div>

				<div>
					<label>{ locale.address }: <input placeholder={ locale.optional }
						type="text"
						value={ accommodation.address }
						onInput={ event => accommodation.setAddress( event.target['value'] ) }/>
					</label>
				</div>

				<div>
					<label>{ locale.bookingReference }: <input placeholder={ locale.bookingReference }
						type="text"
						value={ accommodation.bookingReference }
						onInput={ event => accommodation.setBookingReference( event.target['value'] ) }/>
					</label>
				</div>
			</div>
		)
	}

	private tutorialConfig(): TutorialStepConfig {
		const accommodation = this.props.controller

		return {
			datesAutoCalculated: {
				stepSeq: 11,
				align: 'right',
				arrowShift: '5em',
				hideContinueButton: true,
				hide: !( accommodation.owner as CityController ).isFirstCity(),
				waitEvent: ()=>TutorialBubble.propObserver( accommodation, 'stayLength' )
			},
			clickErrorIcon: {
				stepSeq: 12,
				position: 'bottom',
				align: 'right',
				arrowShift: '1em',
				hideContinueButton: true,
				hide: !( accommodation.owner as CityController ).isFirstCity()
			}
		}


	}

	getClassName() {
		return 'AccommodationPanel';
	}

}
