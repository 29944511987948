import "../../scss/widgets/share-trip-widget.scss";
import { h } from "preact";
import * as preact from "preact";
import { JasComponent, JasComponentProps, JasComponentState } from "../components/jas-component";
import { UserController } from "../user/controller/user-controller";
import { MarkdownToJSX } from "../utils/markdown/markdown-to-jsx";
import { TravelPlanWidget } from "../trip-planner/travel-plan-widget";
import { LoginPanel, LoginAction } from "../user/view/login-panel";
import { WaitingSpinner } from "../components/waiting-spinner";
import { Config } from "../config";
let ItineraryIcon = require( "@fortawesome/fontawesome-free/svgs/solid/route.svg" )

interface ShareTripWidgetProps extends JasComponentProps {
	label?: string;
	subLabel?: string;
	iconUrl?: string;
	tripName: string;
	userId: string;
}

interface ShareTripWidgetState extends JasComponentState {
	showTripPlanner: boolean;
	showLoginPanel: boolean;
	isCopying: boolean;
	error: boolean;
}

export class ShareTripWidget extends JasComponent<ShareTripWidgetProps, ShareTripWidgetState> {
	render() {
		const { locale, showTripPlanner, showLoginPanel, isCopying, error } = this.state
		const { label, subLabel, iconUrl, tripName } = this.props
		const replacer = {	tripPlannerLink: Config.getWebServerURL( Config.pages.travelPlanner ) }
		
		const subHeading = new MarkdownToJSX().parse( locale.loginSubHeading, replacer )
		const infoText = new MarkdownToJSX().parse( locale.loginInfoText, replacer )

		return(
			<div className="wish-to-go">
				<WaitingSpinner waiting={ isCopying }>

					<div className={ this.buildMainCSSClass('clickable row vertical-center') }
						onClick={ ()=> this.copyItineraryToTripPlanner() }
					>

						<ItineraryIcon className="svg-inline" height="100%" width="100%"/>

						<div>
							<h2>{ subLabel || locale.subLabel }</h2>
							<p>
								{ 
									label || locale.label && 
									new MarkdownToJSX().parse( locale.label, { tripName: tripName } ) 
								}
							</p>
						</div>

						{ iconUrl && <img src={ iconUrl } className="svg-inline"/> }

						{ error &&
							<h2 className="error">
								{ locale.tripNotFound}
							</h2>
						}

					</div>
				
				</WaitingSpinner>
				
				{ showTripPlanner && 
					<TravelPlanWidget 
						modal
						queryClose={ ()=> this.setState({ showTripPlanner: false }) }
					/> 
				}

				{ showLoginPanel &&
					<LoginPanel
						modal
						loginAction={ LoginAction.infoDialog }
						subHeading={ subHeading }
						dialogHeading={ locale.loginInfoHeading }
						dialogText={ infoText }
						onSuccess={ ()=> this.copyItineraryToTripPlanner() }
						onFinish={ ()=> this.setState({ showLoginPanel: false }) }
					/>
				}

			</div>
		)
	}

	private async copyItineraryToTripPlanner() {
		const { userId, tripName } = this.props

		if ( !UserController.instance.user ) {
			this.setState({ showLoginPanel: true })
		}
		else {
			this.setState({ isCopying: true })
			try {
				await UserController.instance.cloneTrip( userId, tripName )
				await UserController.instance.save()
				this.setState({
					showTripPlanner: true,
					isCopying: false
				})
			} catch(e) {
				this.setState({
					isCopying: false,
					error: true
				})
			}
		}
	}
	
	getClassName() {
		return 'ShareTripWidget'
	}

	static attacher( props: ShareTripWidgetProps, element: Element ) {
		preact.render(<ShareTripWidget { ...props } />, element );
	}

}
