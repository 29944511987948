import { AffiliateProgram } from "./affiliate-program";
import { Utils } from "../../utils/utils";
import { Transport } from "../../bookings/controller/transport"
let logo = require( '../images/jet-radar-logo.png' );

interface JetRadarQueryString {
	marker: string;
	origin_name: string;
	destination_name: string;
	depart_date: string;
	return_date: string;
	one_way: boolean;
	with_request: boolean;
}

export class JetRadar extends AffiliateProgram {

	getUrlLink() {
		const { stayLength, departure, destination, roundTrip } = this.booking as Transport;
		let checkIn = this.booking.checkIn || new Date( Date.now() );
		const returnDate = Utils.dateFrom( checkIn, stayLength );
		const query: JetRadarQueryString = {
			marker: this.getAffiliateId(),
			origin_name: departure,
			destination_name: destination,
			depart_date: Utils.isValid( checkIn ) && checkIn.toISOString().slice( 2, 10 ),
			return_date: Utils.isValid( returnDate ) && returnDate.toISOString().slice( 2, 10 ),
			one_way: !roundTrip,
			with_request: true
		}

		return 'https://search.jetradar.com/flights' + Utils.objectToQueryString( query );
	}

	getLogo() {
		return logo;
	}

	getAffiliateKeyString() {
		return 'jetRadar';
	}

}
