import { Booking, BookingErrorState } from "./booking";
import { persistent, persistentBoolean, registerFactory } from "../../quarkum.js/persistent";
import { CityController } from "../../trip-planner/controller/city-controller";
import { Utils } from "../../utils/utils";
import { CountryController } from "../../trip-planner/controller/country-controller";
import { Severity } from "../../trip-planner/controller/controller";

export interface TransportErrorState extends BookingErrorState {
	departureMismatchError: Severity;
	destinationMismatchError: Severity;
}

@registerFactory( 'Transport', ()=>new Transport() )
export class Transport extends Booking {

	getBookingCategories(): string[] {
		return [
			'flight', 'boat', 'car', 'coach', 'taxi', 'train'
		]
	}

	setRoundTrip( value: boolean ) {
		this.changeProp( 'roundTrip', value );
		return this;
	}

	get roundTrip() {
		return this._roundTrip;
	}

	setDeparture( value: string ) {
		this.changeProp( 'departure', value );
		return this;
	}

	get departure() {
		return this._departure || ( this.owner && (<CityController>this.owner).arrivingFrom() );
	}

	setRouteId( value: string ) {
		this.changeProp( 'routeId', value );
		return this;
	}

	get routeId() {
		return this._routeId;
	}

	protected getStayLength() {
		if ( this.lockStayLength ) return super.getStayLength();

		let daysFromController = 0;
		const thisCountryName = (<CityController>this.owner).country;
		const homonimeController = <CityController>this.owner.nextControllerByName( this.owner.name, ( _, controller )=>{
			if ( controller instanceof CityController && controller.country === thisCountryName ) {
				daysFromController += controller.stayLength;
			}
			if ( controller instanceof CountryController && controller.name !== thisCountryName ) {
				daysFromController += controller.stayLength
			}
		});

		return homonimeController
			? daysFromController + this.owner.stayLength
			: this.tripController().stayLength;
	}

	protected getCheckIn(): Date {
		const daysInTransit = this.owner instanceof CityController
			?	this.owner.daysInTransit
			: 0;

		return this.lockCheckIn
			? super.getCheckIn()
			: Utils.dateFrom( this.owner.arrivalDate(), -daysInTransit );
	}

	getErrorState(): TransportErrorState {
		const city: CityController = this.owner as CityController;
		const departureSet = this.departure && city.arrivingFrom();
		const destinationSet = this.destination && city.name;
		const checkInValid = Utils.isValid( this.checkIn );
		const checkOutValid = Utils.isValid( this.checkOut() );

		return {
			...super.getErrorState(),
			checkInError: ( this.stayLength < 0 && checkOutValid && checkInValid ) && Severity.error,
			checkOutError: ( this.lockStayLength && this.stayLength < 0 && checkInValid ) && Severity.error,
			departureMismatchError: ( departureSet && this.departure.toLowerCase() !== city.arrivingFrom().toLowerCase() ) && Severity.warning,
			destinationMismatchError: ( destinationSet && this.destination.toLowerCase() !== city.name.toLowerCase() ) && Severity.warning,
			checkInMissmatchError: ( Utils.daysDiff( this.checkIn, this.owner.arrivalDate() ) != city.daysInTransit && Utils.isValid( this.owner.arrivalDate() ) ) && Severity.error,
		};
	}

	@persistentBoolean private _roundTrip: boolean;
	@persistent private _departure: string;
	@persistent private _routeId: string;
}
