import { h, JSX, Fragment } from 'preact';
import { MarkdownBase, ParsedElement, Tag, Values } from './markdown-base';

export class MarkdownToJSX extends MarkdownBase{

	/**
	 * Warning!!!!!: Only parses link elements, bold, italic, bold-italic, 
	 * lists, headers and replaces values.
	 * @param text 
	 * @param values 
	 */
	parse( text: string, values?: Values ): JSX.Element {
		if ( !text ) return

		return this.transform( this.getSyntaxTree( text, values ) )
	}

	private transform( syntaxTree: ParsedElement[] ) {
		return(
			<Fragment>
				{
					syntaxTree.map( elem => {

						switch( elem.tag ) {
							case Tag.a:
								return (
									<a href={ elem.attributes[ 'href' ] }>
										{ this.transform( elem.children ) }
									</a>
								)
										
							case Tag.h1:
								return <h1>{ this.transform( elem.children ) }</h1> 
										
							case Tag.h2:
								return <h2>{ this.transform( elem.children ) }</h2> 
										
							case Tag.h3:
								return <h3>{ this.transform( elem.children ) }</h3> 
										
							case Tag.plaintext:
								return elem.innerText

							case Tag.p:
								return <p>{ this.transform( elem.children ) }</p>

							case Tag.strong:
								return <strong>{ this.transform( elem.children ) }</strong>
										
							case Tag.em:
								return <em>{ this.transform( elem.children ) }</em>
										
							case Tag.ul:
								return <ul>{ this.transform( elem.children ) }</ul>

							case Tag.li:
								return <li>{ this.transform( elem.children ) }</li>
										
						}
					})
				}
			</Fragment>
		)
	}

}

