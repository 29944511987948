import { User, TripCollection } from '../user/user';
import { DataStream } from './data-stream';
import { FirebaseHelper } from '../firebase/firebase-helper';

export class FirebaseStream extends DataStream {

	getUser( userId: string ): Promise< User > {
		return new Promise< User >( async resolve => {
			const firestore = await FirebaseHelper.firestore();
			const doc = await firestore.collection( 'users' ).doc( userId ).get();
			resolve( new User().fromObject( doc.data() ) );
		})
	}

	async saveUser( user: User ): Promise<void> {
		const firestore = await FirebaseHelper.firestore();
		return firestore.collection( 'users' ).doc( user.id ).set( this.buildFirebaseUser( user ) );
	}

	async getTripCollection( userId: string ): Promise< TripCollection > {
		const user = await this.getUser( userId )
		return user.tripCollection
	}

	private buildFirebaseUser( user: User ) {
		const visitedBlogs = new Set<string>()
		user.searchWishes( ()=>true ).forEach( wish => visitedBlogs.add( wish.blogId ) )
		return { ...user.toObject(), visitedBlogs: Array.from( visitedBlogs ) }
	}
}
