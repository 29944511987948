import { h, JSX, toChildArray, cloneElement, VNode, Fragment } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "./jas-component";
import { Severity } from "../trip-planner/controller/controller";
let ExclamationIcon = require( "@fortawesome/fontawesome-free/svgs/solid/exclamation-triangle.svg" );
let InfoIcon = require( "@fortawesome/fontawesome-free/svgs/solid/info-circle.svg" );

interface ErrorIconProps extends JasComponentProps {
	onClick?: ()=> void;
	message: JSX.Element | string;
	severity?: Severity;
	alignMessage?: 'left' | 'right'
	overChildren?: boolean
}

interface ErrorIconState extends JasComponentState {
	showMessage: boolean
}

export class ErrorIcon extends JasComponent<ErrorIconProps, ErrorIconState> {

	render() {
		const { children, message, alignMessage, overChildren } = this.props
		const { showMessage } = this.state
		const severity = this.props.severity || Severity.error
		const align = alignMessage || 'right'
		const severityClass = severity === Severity.error? 'error' 
										: severity === Severity.warning? 'warning' : 'info'

		if ( message ) {
			return (
				<span className={ this.buildMainCSSClass( 'no-wrap' ) }>
					<span>
						{ 
							toChildArray( children ).map( (child: VNode) => cloneElement( child as VNode, {
								className: `${ child.props['className'] } ${ severityClass }`,
								... child.props
							})) 
						}
					</span>
					<span data-testid={ this.buildTestId() }
						className={ ( showMessage? ' tooltip' : '' ) }
						onClick={ ()=>this.clicked() }
						onMouseLeave={ ()=>this.setState({ showMessage: false }) }
						style={{ marginLeft: overChildren && '-1.6em' }}
					>
						{ severity === Severity.info 
							?	<InfoIcon className={ `svg-inline icon-button ${ severityClass }` }
									width="1em" 
								/>
							:	<ExclamationIcon className={ `svg-inline icon-button ${ severityClass }` }
									width="1em" 
								/>
						}
						{ showMessage &&
							<span className={`tooltip-text bottom-tooltip ${ severityClass } align-${ align }`}>
								{ message }
							</span>
						}
					</span>
				</span>
			)
		}
		else {
			return children
		}
	}

	private clicked() {
		this.setState( prevState => ({
			showMessage: !prevState.showMessage
		}))

		const { onClick } = this.props
		onClick && onClick()
	}

	getClassName() {
		return 'ErrorIcon'
	}
}
