import "../../../scss/components/item-picker/item-picker.scss"
import { JSX } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../jas-component";

export interface ViewListItem<T> {
	key: string;
	label: string;
	object?: T;
}

export interface ItemPickerState extends JasComponentState {
}

export interface ItemPickerProps<T> extends JasComponentProps {
	items: ViewListItem<T>[];
	label?: string;
	customItemLabel?: string;
	onSelectItem: ( item: ViewListItem<T> ) => void;
	emptyItemsMessage?: JSX.Element;
}

export abstract class ItemPicker<
	T, 
	P extends ItemPickerProps<T>, 
	S extends ItemPickerState ={}
> extends JasComponent<P, S > {

	protected itemClicked( item: ViewListItem<T> ) {
		this.props.onSelectItem && this.props.onSelectItem( item );
	}

}