import { h } from "preact";
import { ControllerPanel, ControllerPanelProps, ControllerPanelState, WishListItemMap } from "./controller-panel";
import { PickerMasterView } from "../../components/master-detail-view/picker-master-view";
import { CityController } from "../controller/city-controller";
import { CityPanel } from "./city-panel";
import { InputStayingDays } from "./components/input-staying-days";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { Severity } from "../controller/controller";
import { Config } from "../../config";
import { ViewListItem } from '../../components/item-picker/item-picker';
import { Wish } from '../../wishes/controller/wish';

export class CountryPanel extends ControllerPanel< ControllerPanelProps, ControllerPanelState >{

	render() {
		const { locale } = this.state;
		const { controller, active } = this.props;
		const { stayTooLong, stayTooShort } = controller.getErrorState();

		return (
			<div data-testid={ this.buildTestId() }
				className={ this.buildMainCSSClass( 'controller-panel' ) }
			>

				{ this.renderHeaderPicture() }


				<div className="row multiline">
					<div data-testid="clickable" 
						className="clickable column half" 
						onClick={ e => { 
							this.activatePanel( controller, e ) 
						}}
					>
						
						<TutorialBubble	step={{ 5: this.tutorialConfig().countryDetails }}>

							<img className="inline-middle" 
								src={ Config.getFlag( controller.isoCode ) }
								alt={ `${ controller.isoCode } flag` }
							/>
							<h2 className="inline-middle">{controller.name}</h2>

						</TutorialBubble>

					</div>
					<div className="align-right dim-color column half">

						<InputStayingDays
							controller={ controller }
							errorMessage={
								stayTooLong? locale.stayTooLong :
								stayTooShort? locale.stayTooShort : ''
							}
							errorSeverity={ Severity.error }
						/>

					</div>
				</div>

				{ active && this.renderContentPanel() }

			</div>
		);
	}

	private renderContentPanel() {
		const { controller } = this.props
		const { locale } = this.state
		const { addCity, addAnotherCity } = this.tutorialConfig()

		return (
			<div className="panel-content" >
				<TutorialBubble step={{	6: addCity, 28: addAnotherCity }}>

					<PickerMasterView
						addButtonLabel={ locale.addCity }
						customItemLabel={ locale.customCity }
						selectableItems={ this.getSelectableItems().map( wishItem => ({
							key: wishItem.key,
							label: wishItem.label,
							object: wishItem.object
						}))}
						selectedItems={ controller.selectedItems.map( item => ({
							key: item.id,
							label: item.name,
							object: item
						}))}
						addItem={ wishItem => {
							const city = controller.addSelected(
								new CityController( wishItem.label, wishItem.key ) )
									.setRelatedPostUrl( wishItem.object && wishItem.object.post )
									.setPictureUrl( wishItem.object && wishItem.object.picture ) as CityController;
							city.transport.setRoundTrip( city.isFirstCity() )
							return city;
						}}
						onDelete={ item => controller.deleteSelected( item.object ) }
						onMoveUp={ item => this.moveSelected( item, -1 ) }
						onMoveDown={ item => this.moveSelected( item, 1 ) }
						emptySelectableItemsMessage={ 
							this.getSelectableItemsMessage( locale.noWishFound ) 
						}
					>
						{( city: CityController, pActive: boolean, activateClick: () => void ) => (
							<CityPanel
								controller={city}
								active={pActive}
								activatePanel={ ()=>this.activatePanel( city ) }
							/>
						)}
					</PickerMasterView>
				</TutorialBubble>
			</div>
		)
	}

	private tutorialConfig(): TutorialStepConfig {
		const { controller, active } = this.props

		return {
			countryDetails: {
				stepSeq: 5,
				hideContinueButton: true,
				hide:	controller.owner.selectedItems.items[0] !== controller, 
				skip: active
			},
			addCity: {
				stepSeq: 6,
				skip: controller?.selectedItems.length > 0,
				position: 'right',
				hShift: '12em',
				hideContinueButton: true,
				hide:	controller?.owner?.selectedItems.items[0] !== controller,
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'selectedItems')
			},
			addAnotherCity: {
				stepSeq: 28,
				skip: controller?.selectedItems.length > 1,
				position: 'right',
				hShift: '12em',
				vShift: '6em',
				arrowShift: '1em',
				hideContinueButton: true,
				hide:	controller?.owner?.selectedItems.items[0] !== controller,
				waitEvent: ()=>TutorialBubble.propObserver( controller, 'selectedItems')
			}
		}
	}

	protected retrieveSelectableItemsFromRelatedWishes(): ViewListItem< Wish >[] {
		const { controller } = this.props

		const wishes = controller.user.searchWishes( wish => {
			return wish.city
				&& wish.city.length > 0
				&& wish.country === controller.isoCode;
		});

		let wishByCity: WishListItemMap = {};

		wishes.forEach( wish => {
			const isCityWishOnly = !wish.activity;
			if ( !( wishByCity[ wish.city ] && wishByCity[ wish.city ].object ) ) {
				wishByCity[ wish.city ] = {
					key: wish.city,
					label: wish.city,
					object: isCityWishOnly? wish : undefined
				}
			}
		})

		let items = [];
		for ( var key in wishByCity ) {
			items.push( wishByCity[ key ] )
		}
		return items;
	}

	getClassName() {
		return 'CountryPanel';
	}
}
