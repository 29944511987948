import { AffiliateProgram } from "./affiliate-program";
import { Utils } from "../../utils/utils";

let logo = require( '../images/get-your-guide-logo.png' )

interface BookingQueryString {
	partner_id: string;
	q: string; //query activity or place
	date_from: string; //yyyy-mm-dd
	date_to: string
	customerSearch: number;
	searchSource: number;
	utm_medium: string;
	placement: string;
}

export class GetYourGuide extends AffiliateProgram {

	getUrlLink() {
		const { stayLength, destination } = this.booking;
		const city = this.booking.owner.owner.name;
		const country = this.booking.owner.owner.owner.name;
		let checkIn = this.booking.checkIn || new Date( Date.now() );
		const toDate = Utils.dateFrom( checkIn, stayLength );
		const query: BookingQueryString = {
			partner_id: this.getAffiliateId(),
			q: `${ destination }, ${ city }, ${ country }`,
			date_from: Utils.isValid( checkIn ) && checkIn.toISOString().slice( 0, 10 ),
			date_to: Utils.isValid( toDate ) && toDate.toISOString().slice( 0, 10 ),
			customerSearch: 1,
			searchSource: 2,
			utm_medium: 'affiliate',
			placement: 'other'
		}

		return 'https://www.getyourguide.com/s/' + Utils.objectToQueryString( query );
	}

	getLogo() {
		return logo;
	}

	getAffiliateKeyString() {
		return 'getYourGuide';
	}

}
