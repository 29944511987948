import { Config } from "../config";

export class Locale {
	private constructor( locale: string ) {
		this._table = null;
		this._pendingPromise = null;
		this._lang = locale || Config.locale;
	}

	public static get instance() {
		if ( !this._instance ) {
			this._instance = new Locale( this._registeredLocale );
		}
		return this._instance;
	}

	static pluralize( word: string, amount: number, pluralizer: string ) {
		if ( amount > 1 ) {
			return word + pluralizer;
		}
		return word;
	}

	static setLocale( locale: string ) {
		this._registeredLocale = locale;
		this._instance = null;
	}

	async get( component: string ): Promise< {} > {
		if ( !this._table ) {
			this._table = await this.fetchCache(
				async ()=>{
					try {
						return ( await fetch( this.getLocaleFilePath()	) ).json()
					}
					catch ( error ) {
						return ( await fetch( this.getLocaleFilePath('en')	) ).json()
					}
				}
			);
		}
		return this._table[ component ];
	}

	private fetchCache< T >( cachedPromise: ()=>Promise< T > ) {
		if ( !this._pendingPromise ) {
			this._pendingPromise = new Promise< T >( resolve => resolve( cachedPromise() ) );
		}
		return this._pendingPromise;
	}

	private getLocaleFilePath( locale?: string ) {
		if ( locale ) return Config.getCdnURL( `${ Config.pages.localePath }/${ locale }.json` )

		if ( this._lang.indexOf('http') < 0 ) {
			return Config.getCdnURL( `${ Config.pages.localePath }/${ this._lang }.json` )
		}

		return this._lang
	}

	private static _instance: Locale = null
	private static _registeredLocale: string = 'en'
	private _lang: string
	private _pendingPromise = null
	private _table: {} = null
}
