import "../../../scss/widgets/travel-planner/printable.scss";
import { h } from 'preact';
import { JasComponent } from "../../components/jas-component";
import { UserController } from '../../user/controller/user-controller';
import { CountryController } from '../controller/country-controller';
import { CityController } from '../controller/city-controller';
import { ActivityController } from '../controller/activity-controller';
import { Utils } from '../../utils/utils';
let PrintIcon = require( "@fortawesome/fontawesome-free/svgs/solid/print.svg")
let CopyIcon = require( "@fortawesome/fontawesome-free/svgs/regular/copy.svg")
let FacebookIcon = require( "@fortawesome/fontawesome-free/svgs/brands/facebook-square.svg")
let TwitterIcon = require( "@fortawesome/fontawesome-free/svgs/brands/twitter-square.svg")


export class Printable extends JasComponent {
	render() {
		const tripController = UserController.instance.user.lastViewedTrip
		const departureDateStr = Utils.isValid( tripController.departureDate )
			? `on ${ tripController.departureDate.toLocaleDateString() }` : ''
		const returnDateStr = Utils.isValid( tripController.returnDate )
			? tripController.returnDate.toLocaleDateString() : ''

		return (
			<div className={ this.buildMainCSSClass() }>
				<div className="content">
					<h2> My trip to { tripController.name }	</h2>
					<p>
						Departing from { tripController.departure } { departureDateStr }
					</p>

					<ul>
						{ tripController.selectedItems.items.map( 
							country => this.country( country as CountryController) 
						)}
					</ul>

					<p>
						Will be back home on { returnDateStr }
					</p>
				</div>

				<div className="share-buttons">
					<PrintIcon 
						className="svg-inline icon-button" fill='grey'
						onClick={ ()=>this.print() }
					/>
					<CopyIcon
						className="svg-inline icon-button" fill='grey'
						onClick={ ()=>this.copy() }
					/>
					<FacebookIcon 
						className="svg-inline icon-button" fill='grey'
						onClick={ ()=>this.facebook() }
					/>
					<TwitterIcon 
						className="svg-inline icon-button" fill='grey'
						onClick={ ()=>this.twitter() }
					/>
				</div>
			</div>
		)
	}

	country( country: CountryController ) {
		const arrivalDateStr = Utils.isValid( country.arrivalDate() )
			? `Arriving on ${ country.arrivalDate().toLocaleDateString() }` : ''

		return (
			<li key={ country.id }>
				{/* <img src={ country.pictureUrl } /> */}
				<h3>{ country.name }</h3>
				<p>
					<a href={ country.relatedPostUrl }>Info about { country.name }</a>
				</p>
				<p>
					{ arrivalDateStr }
				</p>
				<p>
					Will visit:
				</p>
				<ul>
					{ country.selectedItems.items.map( 
						city => this.city( city as CityController ) 
					)}
				</ul>
			</li>
		)
	}

	city( city: CityController ) {
		return(
			<li key={ city.id }>
				{/* <img src={ city.pictureUrl } /> */}
				<h4>{ city.name }</h4>
				<a href={ city.relatedPostUrl }>Info about { city.name }</a>
				<p>
					Arriving on { city.arrivalDate()?.toLocaleDateString() }
				</p>
				<p>
					by { city.transport.bookingCategory }
				</p>
				<p>
					and staying at { city.accommodation.name } { city.accommodation.bookingCategory }
				</p>
				<p>
					Will see:
				</p>
				<ul>
					{ city.selectedItems.items.map( 
						activity => this.activity( activity as ActivityController ) 
					)}
				</ul>
			</li>
		)
	}

	activity( activity: ActivityController ) {
		return(
			<li key={ activity.id }>
				{/* <img src={ activity.pictureUrl } /> */}
				<h5>{ activity.name }</h5>
				<a href={ activity.relatedPostUrl }>Info about { activity.name }</a>
			</li>
		)
	}

	private print() {
		window.print()
 	}

	private copy() {
		const copyElem = ( this.base as Element ).firstElementChild as HTMLInputElement
		const textArea = document.createElement('textarea')

		textArea.value = copyElem.innerText
		textArea.setAttribute('readonly', '');
		textArea.style.position = 'absolute';
		textArea.style.left = '-9999px';
	
		document.body.appendChild( textArea )
		textArea.select()
    document.execCommand("copy");
    document.body.removeChild( textArea )
	}

	private facebook() {
		alert( 'Only premium members can share on Facebook' )
	}

	private twitter() {
		alert( 'Only premium members can share on Twitter' )
	}

	getClassName() {
		return 'Printable'
	}
}
