export interface Values {
	[ varName: string ]: string;
}

export enum Tag { a, p, strong, em, h1, h2, h3, plaintext, ul, ol, li, img, blockQuote }
interface Attributes {
	[ key: string ]: string
}

export interface ParsedElement {
	tag: Tag;
	attributes?: Attributes;
	innerText?: string;
	children?: ParsedElement[];
}

type ParsedElementFactory = ( groups: RegExpMatchArray, values: Values ) => ParsedElement

export interface ParserProcessorData {
	regEx: RegExp
	text: string
	values: Values
	plainText: string
	parsedElementFactory: ParsedElementFactory
}

//TODO: Make parsers plugable by dependency injection using the visitor pattern to eliminate switch

interface ParserDefinition {
	regEx: RegExp,
	parsedElementFactory: ParsedElementFactory
}

const parsers: ParserDefinition[] = [
	{ 
		regEx: /^>(.*\n?.*)(?:$|\n\n)/m, 
		parsedElementFactory: ( groups, values ) => ({
			tag: Tag.blockQuote,
			children: new MarkdownBase().getSyntaxTree( 
				groups[1].replace('\n>', ' ' ), values 
			)
		})
	},
	{
		regEx: /^\[(.*)]\((.*)\)/,
		parsedElementFactory: ( groups, values ) => ({
			tag: Tag.a,
			attributes: {
				href: MarkdownBase.replaceValue( groups[2], values )
			},
			children: new MarkdownBase().getSyntaxTree( groups[1], values )
		})
	},
	{
		regEx: /^!\[(.*)]\((.*)\)/,
		parsedElementFactory: ( groups, values ) => ({
			tag: Tag.img,
			attributes: {
				alt: MarkdownBase.replaceValue( groups[1], values ),
				src: MarkdownBase.replaceValue( groups[2], values )
			}
		})
	},
	{
		regEx: /^(#+) (.*)\s*/,
		parsedElementFactory: ( groups, values ) => {
			const tags = [ Tag.h1, Tag.h2, Tag.h3 ]
			return {
				tag: tags[ groups[1].length - 1 ],
				children: new MarkdownBase().getSyntaxTree( 
					groups[2].replace( '#', '\\#' ), values 
				)
			}
		}
	}
]

export class MarkdownBase {
	constructor() {
		this._parsedElements = []
	}

	getSyntaxTree( text: string, values: Values ): ParsedElement[] {
		let i = 0
		let plainTextStart = 0
		let spacesRemoved = 0
		const isAfterNewLine = ( pos?:number ) => {
			return i == 0 || text[ ( pos || i ) - spacesRemoved - 1 ] == '\n'
		}

		while( i < text.length ) {
			switch( text[i] ) {
				
				case '\\':
					i += 2
					break
				
				case '*': 
				case '_':
					if( i==0 || this.isSpace( text[ i - 1 ] ) ) {
						if ( i>plainTextStart ) {
							this.parsePlainText( text.slice( plainTextStart, i ), values );
						}
						const result = this.parseEmphasis( text.slice( i ), values )
						if ( result ) {
							i += result
							plainTextStart = i;
						}
						else ++i
					} 
					else ++i
					break
				
				case '-':
				case '*':
				case '+':
					if ( this.isSpace( text[i+1] ) && isAfterNewLine() ) {
						if ( i > plainTextStart ) {
							this.parsePlainText( text.slice( plainTextStart, i ), values );
						}

						i += this.parseStartList( text.slice( i ), values, spacesRemoved )

						plainTextStart = i + 1;
					}
					++i;
					break

				case '¬':
					if ( this.isSpace( text[i+1] ) && isAfterNewLine() ) {
						if ( i > plainTextStart ) {
							this.parsePlainText( text.slice( plainTextStart, i ), values );
						}

						i += this.parseList( text.slice( i ), values, spacesRemoved )

						plainTextStart = i + 1
					}
					++i
					break

				case '\n':
					if ( i > plainTextStart ) {
						this.parsePlainText( text.slice( plainTextStart, i ), values );
						plainTextStart = i;
					}

					if ( text[ i+1 ] == '\n' ) {
						const result = this.parseParagraph( text.slice(i), values )
						if ( result ) {
							i += result
							plainTextStart = i;
						}
						else ++i
					}
					else {
						const newLinePos = i
						while( this.isSpace( text[ ++i ] ) );
						spacesRemoved = i - newLinePos - 1
						plainTextStart = i
					}
					break

				default:
					const nextPos = parsers.reduce( ( accumulator, parser ) => {
						if ( accumulator ) return accumulator
						else return this.parseGenericTag({
							text: text.slice(i),
							values: values,
							plainText: text.slice( plainTextStart, i ),
							regEx: parser.regEx,
							parsedElementFactory: parser.parsedElementFactory
						})
					}, 0 )
					if ( nextPos ) {
						i += nextPos
						plainTextStart = i
					}
					else ++i;
					break
			}
		}
		if ( i>plainTextStart ) {
			this.parsePlainText( text.slice( plainTextStart, i ), values );
			plainTextStart = i;
		}

		return this._parsedElements
	}

	private parseParagraph( text: string, values: Values ) {
		if ( text.search( /\n+\s*(?!\s*-)./ ) < 0 ) return 0

		const trimmed = text.trimLeft()
		const endIdx = trimmed.search( /\n\n+[^\s-]/ )
		const endText = ( endIdx >= 0 ? endIdx : trimmed.length )

		this._parsedElements.push({
			tag: Tag.p,
			children: new MarkdownBase().getSyntaxTree( trimmed.slice( 0, endText ), values )
		})

		return endText + text.length - trimmed.length
	}

	private parsePlainText( text: string, values: Values ) {
		if ( !text.trim() ) return; 
		let newText = ''
		for ( let i = 0; i < text.length; ++i ) {
			if ( text[i]!='\\' ) newText += text[i];
		}

		this._parsedElements.push({
			tag: Tag.plaintext,
			innerText: MarkdownBase.replaceValue( newText, values ),
		})
	}

	private parseEmphasis( text: string, values: Values ) {
		if ( !this.hasEmChar( text[0] ) ) return 0
	
		for( var emChars = 0; this.hasEmChar( text[emChars] ) && emChars < text.length; ++emChars );

		if ( this.isSpace( text[ emChars ] ) ) return 0

		const startText = emChars > 1? 2 : 1
		const lastEmChar = text.slice( emChars ).search( /\S[*_]/ ) + emChars + 1

		this._parsedElements.push({
			tag: emChars > 1? Tag.strong : Tag.em,
			children: new MarkdownBase().getSyntaxTree( 
				text.slice( startText, lastEmChar + ( emChars - startText ) ), values
			)
		})

		return lastEmChar + emChars
	}

	private hasEmChar( c: string ) {
		return c == '*' || c == '_'
	}

	private isSpace( c: string ) {
		return c == ' ' || c =='\t' || c == '\n'
	}

	private parseStartList( text: string, values: Values, spacesRemoved: number ) {
		const regex = new RegExp( `\\n+${ '\\t'.repeat( spacesRemoved ) }[-\\*\\+] `, 'g' )

		const endText = text.match( 
			new RegExp( `^${ '\\t'.repeat( spacesRemoved ) }(?!\\s|[-\\*\\+] ).`, 'm' ) 
		)?.index || text.length
		
		this._parsedElements.push({
			tag: Tag.ul,
			children: new MarkdownBase().getSyntaxTree(
				'¬' + text.slice( 1, endText ).replace( regex, '\n¬ ' ), values
			)
		})
		return endText - 1
	}

	private parseList( text: string, values: Values, spacesRemoved: number ) {
		const endText = text.match( 
			new RegExp( `\\n+${ '\\t'.repeat( spacesRemoved ) }\\S.` ) 
		)?.index || text.length

		this._parsedElements.push({
			tag: Tag.li,
			children: new MarkdownBase().getSyntaxTree( text.slice( 2, endText ), values )
		})
		return endText
	}

	private parseGenericTag( data: ParserProcessorData ) {
		const { regEx, text, plainText, values, parsedElementFactory } = data
		const groups = text.match( regEx )
		if ( !groups?.length ) return 0

		this.parsePlainText( plainText, values )

		this._parsedElements.push( parsedElementFactory( groups, values ) )

		return groups[0].length
	}

	static replaceValue( text: string, values: Values ): string {
		return text.replace(/\${\s*(\w*)\s*}/g, function( _match , group){
      return values[ group ] || '';
	  });
	}

	private _parsedElements: ParsedElement[];
}