import "../../../scss/user/member-panel.scss";
import { h } from 'preact'
import { JasComponent, JasComponentProps, JasComponentState } from "../../components/jas-component";
import { User } from "../user";
import { Auth } from '../auth/auth';
const UserIcon = require( "@fortawesome/fontawesome-free/svgs/solid/user-circle.svg" );

interface MemberPanelProps extends JasComponentProps {
	user: User;
	onQueryClose: ()=>void;
}

export class MemberPanel extends JasComponent<MemberPanelProps, JasComponentState> {
	componentDidMount() {
		window.addEventListener( 'click', e => this.globalClickHandler(e) );
	}

	componentWillUnmount() {
		window.removeEventListener( 'click', (e) => this.globalClickHandler(e) );
	}

	render() {
		const { user, onQueryClose } = this.props
		const { locale } = this.state;
		if ( !user ) return

		return (
			<div className={ this.buildMainCSSClass( 'align-center' ) }>
				<div className="user-image">
					{ user.pictureUrl
						? <img width="4em" src={ user.pictureUrl }/>
						: <UserIcon className="svg-inline" width="4em"/>
					}
				</div>
				<div className="user-name-email">
					<h2>{ user.name }</h2>
					<p>{ user.email }</p>
					<hr/>
					<label>
						{ locale.showTutorial }
						<input 
							type="checkbox" 
							checked={ user.showTutorial }
							onChange={ event => user.setShowTutorial( event.target['checked']) }
						/>
					</label>
				</div>
				<button className="logout-button"
					onClick={ ()=> {
						Auth.instance.logout() 
						onQueryClose && onQueryClose()
					}}
				>
					{ locale.logout }
				</button>
				<button onClick={ ()=> onQueryClose() }>
					{ locale.close }
				</button>
	
			</div>
		)
	}

	private globalClickHandler( event: Event ) {
		if ( this.base && !this.base.contains( event.target as HTMLElement ) ) {
			const { onQueryClose } = this.props;

			// onQueryClose && onQueryClose()
		}
	}

	getClassName() {
		return 'MemberPanel'
	}
}