import "../scss/main.scss";
import { WishWidget } from "./wishes/view/wish-widget";
import { TravelPlanWidget } from "./trip-planner/travel-plan-widget";
import { Auth } from "./user/auth/auth";
import { WishCounterWidget } from "./trip-planner/wish-counter-widget";
import { ShareTripWidget } from "./share-trip/share-trip-widget";
import { Config } from "./config";
import { Admin } from "./user/admin-services/admin";

export class Initializer {
	private static loadStyleSheet( styleSheet: string ) {
		const id = 'id-' + styleSheet;
		if ( document.getElementById( id ) ) return null;

		const styleElement = document.createElement('link');
		styleElement.type = 'text/css';
		styleElement.rel = 'stylesheet';
		styleElement.id = id;
		styleElement.href = styleSheet;

		document.head.appendChild(styleElement);
		return styleElement;
	}

	private static attachToDOM() {
		WishWidget.attachToDOM( 'WishWidget', WishWidget.attacher );
		TravelPlanWidget.attachToDOM( 'TravelPlanWidget', TravelPlanWidget.attacher );
		WishCounterWidget.attachToDOM( 'WishCounterWidget', WishCounterWidget.attacher );
		ShareTripWidget.attachToDOM( 'ShareTripWidget', ShareTripWidget.attacher );
	}

	static wtgInit( styleSheet: string | boolean = true ) {
		if (typeof window === 'undefined' ) return;
		
		let styleSheetUrl = styleSheet as string;
		styleSheetUrl = styleSheetUrl.length? styleSheetUrl : Config.getCdnURL( Config.pages.styleSheet );

		const styleElement = Initializer.loadStyleSheet( styleSheetUrl );

		if ( styleElement ) styleElement.onload = ()=> Initializer.attachToDOM()
		else Initializer.attachToDOM()
	};

	static wtgAuth() {
		return Auth.instance;
	}

	static wtgAdmin() {
		return Admin.instance;
	}

}

export function wtgDismount() {
	WishWidget.detachFromDOM( 'WishWidget' );
	TravelPlanWidget.detachFromDOM( 'TravelPlanWidget' );
	WishCounterWidget.detachFromDOM( 'WishCounterWidget' );
	ShareTripWidget.detachFromDOM( 'ShareTripWidget' );
}

window['wtgAuth'] = Initializer.wtgAuth
window['wtgAdmin'] = Initializer.wtgAdmin
window['wtgInit'] = Initializer.wtgInit
window['wtgDismount'] = wtgDismount

export default Initializer.wtgInit
