import { h, FunctionComponent } from "preact";
import { Config } from "../config";

interface LinkProps {
	to: string;
	children?: preact.VNode | string;
}

export const Link:FunctionComponent<LinkProps> = ( props: LinkProps ) => {
	return <a href={ Config.getWebServerURL( props.to ) }>{ props.children }</a>
}