import { h } from "preact";
import { JasComponent, JasComponentProps, JasComponentState } from "../../components/jas-component";
import { Utils } from "../../utils/utils";
import { DateWithLocker } from "../../components/date-with-locker";
import { Booking } from "../controller/booking";
import { ChangeCallback } from "../../quarkum.js/observable";
import { TripController } from "../../trip-planner/controller/trip-controller";
import { Controller, Severity } from "../../trip-planner/controller/controller";

export interface BookingDatesPanelProps extends JasComponentProps {
	booking: Booking;
	startDateLabel: string;
	endDateLabel?: string;
	disableEndDate?: boolean;
	type?: string;
	onInputFormater?: ( dateStr: string ) => Date;
	valueFormater?: ( date: Date ) => string;
	errorMessages: {
		badBookingDates?: string;
		fillCheckInDateFirst?: string;
		checkInDateMismatch?: string;
		checkOutDateMismatch?: string;
		cannotUnlockWhenConfirmed?: string;
	}
}

export interface BookingDatesPanelState extends JasComponentState {
	errorInCheckIn: boolean;
	errorInCheckOut: boolean;
	fillCheckInDateFirstError: boolean;
}

export class BookingDatesPanel extends JasComponent< BookingDatesPanelProps, BookingDatesPanelState > {
	constructor( props: BookingDatesPanelProps ) {
		super( props );
		const { booking } = props;

		this._tripCtrlHandler = booking
			.tripController().onChange( ( changedProp, sender ) => {
				if ( sender == booking.owner ) return;

				if ( ( changedProp.departureDate || changedProp.stayLength ) 
																										&& booking.lockCheckIn ) {
					this.setState({
						errorInCheckIn: Utils.daysDiff( booking.owner.arrivalDate(), booking.checkIn ) != 0
					});
				}
			}
		)
		
		this._ownerCtrlHandler = booking
			.owner.onChange( ( ( changedProp, sender ) =>{
				if ( sender != booking.owner || !booking.owner.stayLength 
												|| changedProp.stayLength === undefined ) return;

				if ( booking.lockStayLength ) {
					this.setState({
						errorInCheckOut: changedProp.stayLength !== booking.stayLength
					});
				}
			})
		)
		
		this.initErrorState();
	}

	componentWillUnmount() {
		this.props.booking.tripController().removeOnChange( this._tripCtrlHandler )
		this.props.booking.owner.removeOnChange( this._ownerCtrlHandler )
	}

	render() {
		const { errorInCheckIn, errorInCheckOut, fillCheckInDateFirstError } = this.state;
		const { booking, startDateLabel, endDateLabel, errorMessages, disableEndDate, type, onInputFormater, valueFormater } = this.props;
		const {
			checkInError,
			checkOutError,
			checkInMissmatchError,
			checkOutMissmatchError,
		} = booking.getErrorState();
		const now = new Date( Date.now() );

		return(
			<div>
				<div className="row multiline">

					<div className="align-center column">
						<DateWithLocker
							type={ type }
							label={ startDateLabel }
							onLockerClick={ ()=>this.checkInLockerClick() }
							locked={ booking.lockCheckIn }
							onInput={ ( event: Event )=> this.setCheckIn(
								onInputFormater
									?	onInputFormater( event.target['value'] )
									: new Date( event.target['value'] )
							) }
							value={
								valueFormater
									?	valueFormater( booking.checkIn )
									: this.checkIn()
							}
							min={
								valueFormater
											? valueFormater( now )
											: now.toISOString().slice( 0, 10 )
							}
							errorMessage={ errorMessages && errorInCheckIn && ( checkInError && errorMessages.badBookingDates || checkInMissmatchError && errorMessages.checkInDateMismatch ) }
							disabled={ booking.confirmed }
						/>
					</div>

					{!disableEndDate &&
					<div className="align-center column">
						<DateWithLocker
							label={ endDateLabel }
							onLockerClick={ ()=>this.checkOutLockerClick() }
							locked={ booking.lockStayLength }
							onInput={ ( event: Event )=> this.setCheckOut( new Date( event.target['value'] ) ) }
							value={ this.checkOut() }
							errorMessage={ errorMessages && errorInCheckOut && (
																checkOutError && errorMessages.badBookingDates
																|| checkOutMissmatchError && errorMessages.checkOutDateMismatch
								)	|| fillCheckInDateFirstError && errorMessages.fillCheckInDateFirst
							}
							min={ now.toISOString().slice( 0, 10 ) }
							disabled={ booking.confirmed }
						/>
					</div>
					}
				</div>
			</div>
		);
	}

	private initErrorState() {
		const { booking } = this.props;
		const { checkOutError, checkInMissmatchError, checkOutMissmatchError } = booking.getErrorState();

		this.state = { ...this.state,
			errorInCheckIn: checkInMissmatchError > Severity.false,
			errorInCheckOut: checkOutError > Severity.false || checkOutMissmatchError > Severity.false,
			fillCheckInDateFirstError: false,
		}
	}

	private setCheckIn( date: Date ) {
		const { booking } = this.props;
		if ( booking.confirmed ) return;
		const newCalculatedStayLength = Utils.daysDiff( date, booking.checkOut() );

		booking
			.setStayLength( newCalculatedStayLength, false )
			.setCheckIn( date )

		if ( !booking.lockCheckIn ) {
			booking.setLockStayLength( false );
		}

		const { checkInError, checkInMissmatchError, checkOutMissmatchError } = booking.getErrorState();
		this.setState({
			errorInCheckIn: checkInError > Severity.false || checkInMissmatchError > Severity.false,
			errorInCheckOut: checkOutMissmatchError > Severity.false,
			fillCheckInDateFirstError: false
		});
	}

	private checkInLockerClick() {
		const { booking } = this.props;

		if ( booking.confirmed ) return;

		booking.lockCheckIn? this.setCheckIn( null ) : this.setCheckIn( booking.checkIn );
	}

	private setCheckOut( date: Date ) {
		const { booking } = this.props;
		if ( booking.confirmed ) return;

		const days = Utils.daysDiff( booking.checkIn, date );
		const checkInSet = Utils.isValid( booking.checkIn );

		booking
			.setStayLength( days )
			.setLockStayLength( Utils.isValid( date ) && checkInSet )

		const { checkOutError, checkOutMissmatchError, checkInMissmatchError } = booking.getErrorState();

		this.setState({
			errorInCheckIn: checkInMissmatchError > Severity.false,
			errorInCheckOut: checkOutError > Severity.false || checkOutMissmatchError > Severity.false,
			fillCheckInDateFirstError: !checkInSet && Utils.isValid( date )
		});
	}

	private checkOutLockerClick() {
		const { booking } = this.props;

		if ( booking.confirmed ) return;

		booking.lockStayLength? this.setCheckOut( null ) : this.setCheckOut( booking.checkOut() );
	}

	private checkIn() {
		const { booking } = this.props;

		if ( Utils.isValid( booking.checkIn ) ) {
			return booking.checkIn.toISOString().slice( 0, 10 );
		}
		else {
			return '';
		}
	}

	private checkOut() {
		const checkOutDate = this.props.booking.checkOut();

		if ( Utils.isValid( checkOutDate ) ) {
			return checkOutDate.toISOString().slice( 0, 10 );
		}
		else {
			return '';
		}
	}

	getClassName() {
		return 'BookingDatesPanel';
	}

	private _tripCtrlHandler: ChangeCallback<TripController>;
	private _ownerCtrlHandler: ChangeCallback<Controller>;
}
