import { Persistent, persistent, persistentCollection, persistentParser } from "../quarkum.js/persistent";
import { TripController } from "../trip-planner/controller/trip-controller";
import { Wish } from "../wishes/controller/wish";
import { UserCredential } from "./auth/user-auth-types";

export interface TripCollection {
	[ x: string ]: TripController;
}

export interface WishCollection {
	[ wishId: string ] : Wish;
}

export class User extends Persistent {
	constructor() {
		super();
		this._showTutorial = true;
		this._wishCollection = {};
		this._tripCollection = {};
		this._isLoggedIn = false;
	}

	setAuthUser( obj: UserCredential ) {
		if ( obj ) {
			this.fromObject( obj );
			this._email = obj.email
			this._emailVerified = obj.emailVerified
			this._name = obj.name
			this._pictureUrl = obj.pictureUrl
			this._subscriptionPlan = obj.customData.subscriptionPlan
			this._planExpireDate = obj.customData.planExpireDate
			this._creationDate = obj.creationDate
			this._lastLogin = Date.now()

			this._isLoggedIn = true;
		}
		else {
			this._isLoggedIn = false;
		}
		return this;
	}

	isLoggedIn() {
		return this._isLoggedIn;
	}

	get email() {
		return this._email;
	}

	get emailVerified() {
		return this._emailVerified
	}

	get name() {
		return this._name || ( this.email && this.email.slice( 
			0, this.email.indexOf('@') 
		) )
	}

	get pictureUrl() {
		return this._pictureUrl;
	}

	get subscriptionPlan() {
		return this._subscriptionPlan
	}

	get planExpireDate() {
		return new Date( this._planExpireDate )
	}

	setShowTutorial( value: boolean ) {
		this.changeProp( 'showTutorial', value );
		return this;
	}

	get showTutorial() {
		return this._showTutorial;
	}

	setLastViewedTrip( trip: TripController ) {
		this._lastViewedTrip = trip.id;
		this.notifyChange({ lastViewedTrip: trip } as any, this )
		return this;
	}

	get lastViewedTrip() {
		return this._tripCollection[ this._lastViewedTrip ];
	}

	addTrip( trip: TripController ) {
		trip.setUser( this );
		this._tripCollection[ trip.id ] = trip;
		this.setLastViewedTrip( trip );
		this.notifyChange({ tripCollection: this._tripCollection } as any, this )
		return trip;
	}

	get tripCollection() {
		return this._tripCollection;
	}

	getTrip( tripName: string ) {
		return User.getTrip( this.tripCollection, tripName )
	}

	static getTrip( tripCollection: TripCollection, tripName: string ) {
		return Object.values( tripCollection ).find( trip => trip.name === tripName );
	}

	removeTrip( trip: TripController ) {
		this._tripCollection[ trip.id ] = null;
		this.notifyChange({ tripCollection: this._tripCollection } as any, this )
	}

	addWish( wish: Wish ) {
		this._lastWishUpdateDate = wish.lastUpdated.getTime()

		this._wishCollection[ wish.id ] = wish;
		this.notifyChange({ addWish: wish } as any, this )
		this.notifyChange({ wishCount: this.wishCount } as any, this )
		return wish;
	}

	removeWish( wish: Wish ) {
		delete this._wishCollection[ wish.id ];
		this.notifyChange({ removeWish: wish } as any, this )
		this.notifyChange({ wishCount: this.wishCount } as any, this )
	}

	findWish( country: string, city: string, activity: string, blogId: string ): Wish {
		for ( const key in this._wishCollection ) {
			const wish = this._wishCollection[ key ];

			const eligibleWish = true //wish.wishLevel > 0
				&& ( wish.country || '' ) == ( country || '' )
				&& ( wish.city || '' ) == ( city || '' )
				&& ( wish.activity || '' ) == ( activity || '' )
				&& ( wish.blogId || '' ) == ( blogId || '' )

			if ( eligibleWish ) return wish;
		}
		return null;
	}

	searchWishes( eligibleWish: ( wish: Wish ) => boolean ): Wish[] {
		let wishes = [];
		for ( const key in this._wishCollection ) {
			const wish = this._wishCollection[ key ];

			if ( eligibleWish( wish ) ) wishes.push( wish );
		}
		return wishes;
	}

	get wishCount() {
		return Object.keys( this._wishCollection ).length
	}

	get lastLogin() {
		return new Date( this._lastLogin )
	}

	get creationDate() {
		return new Date( this._creationDate )
	}

	get lastWishUpdateDate() {
		return new Date( this._lastWishUpdateDate )
	}
	
	loaded() {
		Object.values( this.tripCollection ).forEach( trip => {
			trip.setUser( this )
		})
	}

	private _isLoggedIn: boolean;
	private _emailVerified: boolean;
	private _subscriptionPlan: string;
	private _planExpireDate: number;
	private _email: string;
	private _name: string;
	private _pictureUrl: string;
	
	@persistent private _lastViewedTrip: string;
	@persistent private _showTutorial: boolean;
	@persistentCollection private _tripCollection: TripCollection;
	@persistentCollection private _wishCollection: WishCollection;
	@persistent private _lastLogin: number;
	@persistent private _creationDate: number;
	@persistent private _lastWishUpdateDate: number;
}
