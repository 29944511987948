import { h, JSX } from "preact";
import { ItemPickerProps, ViewListItem, ItemPicker, ItemPickerState } from "./item-picker";
let PlusCircleIcon = require( "@fortawesome/fontawesome-free/svgs/solid/plus-circle.svg" );

interface ItemPickerDropdownState extends ItemPickerState {
	showItems: boolean;
	customItem: string;
}

interface ItemPickerDropdownProps<T> extends ItemPickerProps<T> {
	icon?: JSX.Element;
	dropUp?: boolean;
	anchorElement?: HTMLElement;
}

export class ItemPickerDropdown<T> extends ItemPicker<T, ItemPickerDropdownProps<T>, ItemPickerDropdownState> {

	constructor( props: ItemPickerDropdownProps<T> ) {
		super( props );
		this.state = {...this.state,
			showItems: false,
		};
	}

	componentDidMount() {
		window.addEventListener( 'click', e => this.globalClickHandler(e) );
	}

	componentWillUnmount() {
		window.removeEventListener( 'click', (e) => this.globalClickHandler(e) );
	}

	componentDidUpdate() {
		const thisElement = this.base as Element

		if ( thisElement ) {
			const button = thisElement.getElementsByClassName( 'add-button' )[0]
			const pickerPanel = thisElement.getElementsByClassName( 'picker-panel' )[0]
			const controllerContainer = document.getElementsByClassName( 'controller-container' )[0]
			const menuHeight = pickerPanel?.scrollHeight

			const buttonTop = button?.getBoundingClientRect().top
			const parentTop = controllerContainer?.getBoundingClientRect().top
			if ( pickerPanel && parentTop < ( buttonTop - menuHeight ) ) {
				pickerPanel.className += ' drop-up'
			}
		}
	}

	render() {
		const { customItemLabel, items, emptyItemsMessage, dropUp, anchorElement } = this.props ;
		const { showItems, customItem } = this.state ;

		if ( anchorElement ) {
			anchorElement.setAttribute( 'style', `position: ${showItems? 'relative' : 'unset'}` )
		}

		return (
			<div data-testid={ this.buildTestId() } 
				className={ this.buildMainCSSClass( dropUp? 'stick-to-bottom' : '' ) }
				style={{ 
					position: showItems? ( dropUp? 'sticky' : !anchorElement && 'relative' ) : undefined,
				}}
			>
				{ this.renderButton() }

				{ showItems &&
					<ul	className="picker-panel">
						{
							 items && items.map( item => { return (
								<li key={item.key}
									className="picker-item-button"
									onClick={ ()=> this.itemClicked( item ) }>
									{item.label}
								</li>
							)})
						}
						{ customItemLabel && 
							<li key="custom"
								className="custom-picker-item"
							>
								<input 
									type="text"
									className="custom-item-input"
									placeholder={customItemLabel}
									onInput={ event => this.setState({ customItem: event.target[ 'value' ] }) }
									value={ customItem }
								/>
								<PlusCircleIcon className="icon-button inline-middle" data-testid="add-custom-item-icon" fill='grey' width='1rem'
									onClick={ ()=> this.itemClicked({
										key: customItem,
										label: customItem,
										// object: {}
									}) }
								/>
							</li>
						}
						{ items && !items.length && emptyItemsMessage }
					</ul>
				}
			</div>
		);
	}

	private renderButton() {
		const { label, icon } = this.props
		const { showItems } = this.state

		const onClick = ()=> this.setState( { showItems: !showItems } ) 

		if ( label ) {
			return (
				<button class="add-button" onClick={ onClick }>
					{icon}
					<span>{label}</span>
				</button>
			)
		}
		else {
			return (
				<span className="clickable" onClick={ onClick }>
					{ icon }
				</span>
			)
		}
	}

	private globalClickHandler( event: Event ) {
		if ( this.base && !this.base.contains( event.target as HTMLElement )  ) {
			this.setState({ showItems: false})
		}
	}

	protected itemClicked( item: ViewListItem<T> ) {
		if ( item.label ) {
			super.itemClicked( item )
			this.setState({
				showItems: false,
				customItem: ''
			});
		}
	}

	getClassName() {
		return 'ItemPickerDropdown';
	}
}
