import { h } from "preact";
import { CityController } from "../../trip-planner/controller/city-controller";
import { BookingDatesPanel } from "./booking-dates-panel";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { ChangeCallback } from "../../quarkum.js/observable";
import { Transport } from "../controller/transport"
import { BookingPanel } from "./booking-panel";
import { JetRadar } from "../../affiliates/controller/jet-radar";
import { ControllerPanel, ControllerPanelProps, ControllerPanelState } from "../../trip-planner/view/controller-panel";
import { ErrorIcon } from "../../components/error-icon";

export interface TransportPanelProps extends ControllerPanelProps {
	controller: Transport;
}

export interface TransportPanelState extends ControllerPanelState {
}

export class TransportPanel extends ControllerPanel< TransportPanelProps, TransportPanelState > {

	componentDidMount() {
		const { controller } = this.props

		this._handler = controller.onChange( ( changedProp ) => {
			if ( changedProp.stayLength ) {
				controller.setCheckIn( controller.checkIn )
			}
		})
	}

	componentWillUnmount() {
		this.props.controller.removeOnChange( this._handler )
	}

	render() {
		const { locale } = this.state
		const { controller, active } = this.props
		const jetRadar = new JetRadar( controller );

		if ( !controller || !locale.arrivingBy )return;

		let headingLabel: string 

		if ( controller.bookingCategory ) {
			headingLabel = locale.arrivingBy + ' ' + locale[ controller.bookingCategory ];
		}
		else {
			headingLabel = locale.transport;
		}

		return (
			<div className={ this.buildMainCSSClass( 'controller-panel' ) }>

				<BookingPanel
					controller={ controller }
					pickerLabel={ locale.transportPickerLabel }
					affiliateProgram={ jetRadar }
					headingLabel={ headingLabel }
					active={ active }
					activatePanel={ ()=>this.activatePanel( controller ) }
				>

					<div>

						{ this.renderGenericTransportInfo() }

						{ this.renderDepartureDestinationFields() }

						{ this.renderDaysInTransitPanel() }

						<BookingDatesPanel
							booking={ controller }
							startDateLabel={ locale.departureDate }
							endDateLabel={ locale.returnDate }
							disableEndDate={ !controller.roundTrip }
							errorMessages={ locale }
						/>

					</div>

				</BookingPanel>
			</div>
		)
	}

	private renderGenericTransportInfo() {
		const { locale } = this.state
		const { controller } = this.props

		const companyDenomination = locale.loaded && locale.companyDenomination[ controller.bookingCategory ];
		const routeIdDenomination = locale.loaded && locale.routeIdDenomination[ controller.bookingCategory ];

		return(
			<div>
				<label>{ companyDenomination }: <input placeholder={ companyDenomination }
					type="text"
					value={ controller.name }
					onInput={ event => controller.setName( event.target['value'] ) }/>
				</label>

				<label>{ routeIdDenomination }: <input placeholder={ routeIdDenomination }
					type="text"
					value={ controller.routeId }
					onInput={ event => controller.setRouteId( event.target['value'] ) }/>
				</label>

				<label>{ locale.bookingReference }: <input placeholder={ locale.bookingReference }
					type="text"
					value={ controller.bookingReference }
					onInput={ event => controller.setBookingReference( event.target['value'] ) }/>
				</label>
			</div>
		)
	}

	private renderDepartureDestinationFields() {
		const { locale } = this.state;
		const { controller } = this.props

		return (
			<TutorialBubble step={{ 18: this.tutorialConfig().departureDestinationFields }}>

				<div>
					<div>
						<label>{ locale.departure }:

							<ErrorIcon
								message={ controller.getErrorState().departureMismatchError && locale.departureMismatch }
								severity={ controller.getErrorState().departureMismatchError }
								overChildren
							>
								<input placeholder={ locale.departure }
									type="text"
									value={ controller.departure }
									onInput={ event => controller.setDeparture( event.target['value'] ) }
								/>

							</ErrorIcon>

						</label>
					</div>
					<label>{ locale.destination }:
						<ErrorIcon
							message={ controller.getErrorState().destinationMismatchError && locale.destinationMismatch }
							severity={ controller.getErrorState().destinationMismatchError }
							overChildren
						>

							<input placeholder={ locale.destination }
								type="text"
								value={ controller.destination }
								onInput={ event => controller.setDestination( event.target['value'] ) }
							/>

						</ErrorIcon>
					</label>
				</div>
			</TutorialBubble>
		)
	}

	private renderDaysInTransitPanel() {
		const { locale } = this.state;
		const { controller } = this.props
		const city = controller.owner as CityController;

		return (
			<div className="row multiline">

				<label className="input-days align-center column">
					<TutorialBubble	step={{ 19: this.tutorialConfig().daysInTransit }}>

						<span> { locale.daysInTransit }: </span>
						<input type="number" min="0" max="99"
							value={ city.daysInTransit }
							onInput={ event => city.setDaysInTransit( Number( event.target['value'] ) ) }
						/>

					</TutorialBubble>
				</label>

				<label className="align-center column">
					<TutorialBubble	step={{ 20: this.tutorialConfig().roundTrip }}>

						<input type="checkbox"
							checked={ controller.roundTrip }
							onClick={ () => controller.setRoundTrip( !controller.roundTrip ) }
						/>
						{ locale.roundTrip }

					</TutorialBubble>
				</label>

			</div>
		)
	}

	private tutorialConfig(): TutorialStepConfig {
		const city = this.props.controller.owner as CityController;

		return {
			departureDestinationFields: {
				stepSeq: 18,
				hide: !city.isFirstCity()
			},
			daysInTransit: {
				stepSeq: 19,
				hide: !city.isFirstCity()
			},
			roundTrip: {
				stepSeq: 20,
				align: 'right',
				hShift: '3%',
				arrowShift: '30%',
				hide: !city.isFirstCity()
			}	
		}	
	}

	getClassName() {
		return 'TransportPanel';
	}

	private _handler: ChangeCallback<Transport>
}
