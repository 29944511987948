import { Booking, BookingErrorState } from "./booking";
import { persistent, registerFactory } from "../../quarkum.js/persistent";
import { Utils } from "../../utils/utils";
import { Severity } from "../../trip-planner/controller/controller";

@registerFactory( 'Accommodation', ()=> new Accommodation() )
export class Accommodation extends Booking {

	getBookingCategories(): string[] {
		return [
			'hotel', 'guesthouse', 'appartment', 'hostel', 'campsite'
		]
	}

	protected getStayLength() {
		return this.lockStayLength? super.getStayLength() : this.owner.stayLength;
	}

	getErrorState(): BookingErrorState {
		const checkInValid = Utils.isValid( this.checkIn );
		const checkOutValid = Utils.isValid( this.checkOut() );

		return {
			...super.getErrorState(),
			checkInError: ( this.stayLength <= 0 && checkOutValid && checkInValid ) && Severity.error,
			checkOutError: ( this.lockStayLength && this.stayLength <= 0 && checkInValid ) && Severity.error,
			checkOutMissmatchError: ( this.lockStayLength && this.owner.lockStayLength && this.owner.stayLength !== this.stayLength ) && Severity.error,
			checkInMissmatchError: ( Utils.daysDiff( this.owner.arrivalDate(), this.checkIn ) != 0 && Utils.isValid( this.owner.arrivalDate() ) ) && Severity.error
		};
	}

	setAddress( value: string ) {
		this.changeProp( 'address', value );
		return this;
	}

	get address() {
		return this._address;
	}

	@persistent private _address: string;
}
