import { Config } from '../config';

type Firebase = typeof import( 'firebase' )//.app.App;

declare global {
	var firebase: typeof firebase
}

enum Services { 
	app = 'app', 
	firestore = 'firestore', 
	firestoreMemory = 'firestore.memory', 
	auth = 'auth',
	functions = 'functions'
}
type AuthProvider = 'FacebookAuthProvider' | 'GoogleAuthProvider';

export class FirebaseHelper {
	
	static firebaseVersion = '8.2.9'

	private static init() {
		if ( !FirebaseHelper._appPromise ) {

			FirebaseHelper._appPromise = new Promise< Firebase >( resolve => {
				FirebaseHelper.loadService( Services.app ).then( () => {
					if ( Config.betaDataServer ) {
						firebase.initializeApp({															// cspell: disable
							apiKey: "AIzaSyDPjKGK37PyDyZjhnNTjSNX8iwgL8qUnwg",
							authDomain: "beta-wish-to-go.firebaseapp.com",
							databaseURL: "https://beta-wish-to-go.firebaseio.com",
							projectId: "beta-wish-to-go",
							storageBucket: "beta-wish-to-go.appspot.com",
							messagingSenderId: "958926410885",
							appId: "1:958926410885:web:c7a3d39ae3945106b585ec",
							measurementId: "G-RVVKLJRN1M"											
						})
					}
					else {
						firebase.initializeApp({ 															
							apiKey: "AIzaSyAL7HGSveC9Qo_hUA-pIShdlEi3XWGI-Sg", 
							authDomain: "wish-to-go.com",
							databaseURL: "https://wish-to-go.firebaseio.com",
							projectId: "wish-to-go",
							storageBucket: "wish-to-go.appspot.com",
							messagingSenderId: "760218719658",
							appId: "1:760218719658:web:5eddc4739e81f6fdaa87d3",
							measurementId: "G-KYSSTEMQCG" 											// cspell: enable	
						})
					}
					resolve( firebase as any )
				})
			});
		}

		return FirebaseHelper._appPromise;
	}

	private static loadService( service: Services ): Promise<any> {
		return FirebaseHelper.loadCDN( 'firebase-' + service )
		// if want to use webpack dynamic import
		// 	return import(
		// 		/* webpackChunkName: "firebase-app" */
		// 		'firebase/' + service
		// 	)
	}

	static async firestore() {
		if ( !FirebaseHelper._firestorePromise ) {
			await FirebaseHelper.init();
			FirebaseHelper._firestorePromise = await FirebaseHelper.loadService( Services.firestoreMemory )
		}
		return firebase.firestore();
	}

	static async auth() {
		if ( !FirebaseHelper._authPromise ) {
			await FirebaseHelper.init();
			FirebaseHelper._authPromise = await FirebaseHelper.loadService( Services.auth )
		}
		return firebase.auth();
	}

	static async authProvider( provider: AuthProvider ) {
		await FirebaseHelper.auth()
		return new firebase.auth[ provider ]();
	}

	static async functions() {
		if ( !FirebaseHelper._functionsPromise ) {
			await FirebaseHelper.init();
			FirebaseHelper._functionsPromise = await FirebaseHelper.loadService( Services.functions )
		}
		return firebase.functions();
	}

	static loadCDN( module: string ): Promise<void> {
		return new Promise<void>( resolve =>{
			const deferredScript = document.createElement( 'script' );
			deferredScript.async = true
			deferredScript.src = `https://www.gstatic.com/firebasejs/
														${ FirebaseHelper.firebaseVersion }/${ module }.js`

			document.body.appendChild(deferredScript).onload = () => {
				resolve();
			}
		})
	}

	private static _appPromise: Promise<Firebase>;
	private static _firestorePromise: Promise<void>;
	private static _authPromise: Promise<void>;
	private static _functionsPromise: Promise<void>;
}