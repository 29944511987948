import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { MemberPanel } from '../../../user/view/member-panel';
import { UserController } from '../../../user/controller/user-controller';
let UserIcon = require( "@fortawesome/fontawesome-free/svgs/solid/user-circle.svg" );
let SignInIcon = require( "@fortawesome/fontawesome-free/svgs/solid/sign-in-alt.svg" );

interface LoginAreaProps {
	signUpLabel: string;
	loginLabel: string; 
	loggedInLabel: string;
}

export function LoginArea( props: LoginAreaProps ) {
	const { loggedInLabel } = props;
	const [ showMemberPanel, setShowMemberPanel ] = useState( false )
	const user = UserController.instance.user;

	return (
		<Fragment>

			<div className="login-area"
			>
				<div className={ user? 'clickable' : '' }
					onClick={ 
						()=> user && setShowMemberPanel( !showMemberPanel )
					}
				>
					<span>{	user && (	user.name || loggedInLabel ) }</span>
				
					{ user && ( user.pictureUrl
							? <img className="svg-inline icon-button" width="2.5em" src={ user.pictureUrl }/>
							: <UserIcon className="svg-inline icon-button" width="2.5em"/>
						) || <SignInIcon className="svg-inline" width="2.5em"/>
					}
				</div>


			</div>

			{	showMemberPanel &&
				<MemberPanel 
					user={ user } 
					onQueryClose={ ()=> setShowMemberPanel( false ) }
				/>
			}
		</Fragment>
	)
}



