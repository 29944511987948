import { AffiliateData } from "../model/affiliate-data";
import { Booking } from "../../bookings/controller/booking";

export abstract class AffiliateProgram {
	constructor( booking: Booking ) {
		this.booking = booking;
	}

	abstract getUrlLink(): string;
	abstract getLogo(): string;
	protected abstract getAffiliateKeyString(): string;

	get affiliateKey() {
		return this.getAffiliateKeyString();
	}

	getAffiliateId() {
		const id = AffiliateData.getAffiliateId( '', this.affiliateKey );
		if ( id ) return id;
		else throw new Error( 'Affiliate ID not found' );
	}

	protected booking: Booking;
}
