import { h } from "preact";
import { JasComponentProps, JasComponentState, JasComponent } from "../../components/jas-component";
import { ActivityController } from "../../trip-planner/controller/activity-controller";
import { BookingDatesPanel } from "./booking-dates-panel";
import { Utils } from "../../utils/utils";

export interface TourPanelProps extends JasComponentProps {
	controller: ActivityController;
}

export interface TourPanelState extends JasComponentState {
}

export class TourPanel extends JasComponent< TourPanelProps, TourPanelState > {

	render() {
		const { locale } = this.state;
		const { controller } = this.props;
		const { tour } = controller;

		return (
			<div className={this.buildMainCSSClass() }>
				<div>

					<label>{ locale.tourName }: <input placeholder={ locale.tourName }
						type="text"
						value={ tour.name }
						onInput={ event => tour.setName( event.target['value'] ) }/>
					</label>
				</div>

				<div>
					<label>{ locale.tourStartAddress }: <input placeholder={ locale.optional }
						type="text"
						value={ tour.meetingPointAddress }
						onInput={ event => tour.setMeetingPointAddress( event.target['value'] ) }/>
					</label>
				</div>

				<div>
					<label>{ locale.bookingReference }: <input placeholder={ locale.bookingReference }
						type="text"
						value={ tour.bookingReference }
						onInput={ event => tour.setBookingReference( event.target['value'] ) }/>
					</label>
				</div>

				<BookingDatesPanel
					type="datetime-local"
					onInputFormater={ strDate => {
						const hours = Number( strDate.slice( -5, -3 ) );
						const min = Number( strDate.slice( -2 ) );
						const d = new Date( strDate.slice( 0, 10 ) );
						d.setUTCHours( hours, min );
						return d;
					}}
					valueFormater={ date => Utils.isValid( date ) && date.toISOString().slice( 0, 16 ) || '' }
					booking={ tour }
					startDateLabel={ locale.tourDateTime }
					disableEndDate={ true }
					errorMessages={ locale }
				/>
			</div>
		);
	}

	getClassName() {
		return 'TourPanel';
	}

}
