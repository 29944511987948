import { FirebaseHelper } from "../../firebase/firebase-helper";
import { AdminService } from "./admin";
import { UserCustomData, QueryNewSubscriptionData } from "../auth/user-auth-types";

type QueryNewSubscriptionDataCall = ( p: QueryNewSubscriptionData ) => any

export class FirebaseAdmin extends AdminService {
	
	async queryNewSubscription( userEmail: string, customData: UserCustomData ) {
		const functions = await FirebaseHelper.functions()
		const newSubscription: QueryNewSubscriptionDataCall = functions.httpsCallable( 'queryNewSubscription' )
		return newSubscription({
			userEmail: userEmail,
			customData: customData
		})
	}

	async buyPlan( plan: string ) {
		throw( 'Not implemented' )
	}
}