import { h } from 'preact';
import { JasComponentProps, JasComponentState, JasComponent } from "../components/jas-component";
import { AffiliateProgram } from './controller/affiliate-program';

export interface AffiliateProps extends JasComponentProps {
	program: AffiliateProgram;
}

export interface AffiliateState extends JasComponentState {}

export class Affiliate< P extends AffiliateProps, S extends AffiliateState > extends JasComponent< P, S >{

	render() {
		const { locale } = this.state;
		const { program } = this.props;

		return (
			<div className={ 'margin-half row center' + this.buildMainCSSClass() }>
				<button>
					<p>{ locale.loaded && locale[ program.affiliateKey ][ 'header' ] }</p>
					<a href={ program.getUrlLink() } target="_blank">
						<img src={ program.getLogo() }/>
						{ this.props.children }
					</a>
					<p>{ locale.loaded && locale[ program.affiliateKey ][ 'footer' ] }</p>
				</button>
			</div>
		);
	}

	getClassName() {
		return 'Affiliate';
	}
}
