import { Delegate, Callback } from "../../quarkum.js/delegate";
import { Step } from "./tutorial-bubble";

interface TutorialEvent {
	step?: number;
	enabled?: boolean;
}

type TutorialDelegate = Delegate<Tutorial, TutorialEvent>
export type TutorialCallback = Callback<Tutorial, TutorialEvent>

export class Tutorial {
	private constructor() {
		this._tutorialStep = 0;
		this._lastVisitedStep = 0;
		this._onTutorialDelegate = new Delegate<Tutorial, TutorialEvent>()
	}

	static get instance() {
		return this._instance || ( this._instance = new this() );
	}

	onChange(handler: TutorialCallback ) {
		return this._onTutorialDelegate.connect( handler )
	}

	removeOnChange( handler: TutorialCallback ) {
		this._onTutorialDelegate.disconnect( handler )
	}

	get tutorialCurrentStep() {
		return this._tutorialStep;
	}

	incTutorialStep( fromStep?: Step, disable: boolean = false ) {
		if ( disable ) return;

		let fromLastStep: number;

		if ( typeof fromStep === 'object'  ) {
			const fStep: number[] = []
			for( var key in fromStep ) {
				fStep.push( Number( key ) )
			}
			fromLastStep = fStep.reduce( ( maxLessThanCurrentStep, item ) => {
				if ( maxLessThanCurrentStep < item && item <= this._tutorialStep ) {
					maxLessThanCurrentStep = item;
				}
				return maxLessThanCurrentStep;
			})
		}
		else {
			fromLastStep = fromStep || this._tutorialStep;
		}
		const steps = fromLastStep - this._tutorialStep + 1;

		if ( steps > 0 ) {
			if ( this._lastVisitedStep < this._tutorialStep ) {
				this._lastVisitedStep = this._tutorialStep;
			}
			
			this._tutorialStep += steps;
	
			this._onTutorialDelegate.fire({ step: this._tutorialStep }, this )
		}
	}

	showTutorialBubble() {
		return this._enabled && this._tutorialStep >= this._lastVisitedStep
	}

	enableTutorial( enable: boolean) {
		if ( this._enabled != enable ) {
			this._enabled = enable;
			this._onTutorialDelegate.fire({ enabled: this._enabled }, this )
			this.resetTutorial();
		}
	}

	resetTutorial( at: number = 0 ) {
		this._tutorialStep = at;
		this._lastVisitedStep = at;
		this._onTutorialDelegate.fire({ step: at }, this )
	}

	private _enabled: boolean;
	private _onTutorialDelegate: TutorialDelegate
	private _lastVisitedStep: number;
	private _tutorialStep: number;
	private static _instance: Tutorial;
}