import "../../../scss/components/master-view.scss"
import { h } from "preact";
import { JasComponent, JasComponentState, JasComponentProps } from "../jas-component";
import { DetailView } from "./detail-view";
import { ViewListItem } from "../item-picker/item-picker";

export interface MasterViewState extends JasComponentState {
	activeMap: {
		[ key: string ]: boolean;
	}
}

export interface MasterViewProps<T> extends JasComponentProps {
	listSource: ViewListItem<T>[];
	onDelete: ( item: ViewListItem<T> ) => void;
	onMoveUp: ( item: ViewListItem<T> ) => ViewListItem<T>;
	onMoveDown: ( item: ViewListItem<T> ) => ViewListItem<T>;
	scrollOnMove?: boolean;
}

export class MasterView< T > extends JasComponent< MasterViewProps<T>, MasterViewState >{
	constructor( props: MasterViewProps<T> ) {
		super( props );

		this.state = { ...this.state, activeMap: {} }
	}

	componentDidUpdate() {
		if ( this.props.scrollOnMove && this.scrollByOnMoveUpDown ) {
			window.scrollBy( 0, this.scrollByOnMoveUpDown );
			this.scrollByOnMoveUpDown = 0;
		}
	}

	render() {
		const { listSource, onDelete } = this.props;
		const activeView = this.someDetailViewIsActive()

		return (
			<ul data-testid={ this.buildTestId() } className={ this.buildMainCSSClass() }>
				{ 
					listSource?.filter( item => activeView? this.getActiveState( item.key ) : !this.getActiveState( item.key ) )
						.map( item => { return (
							<li id={item.key} key={item.key}>
								<DetailView
									item={ item }
									queryActivate={ () => this.detailViewClicked( item.key ) }
									active={ this.getActiveState( item.key ) }
									onDelete={ ()=>onDelete( item ) }
									onMoveUp={ ()=>this.moveUp( item ) }
									onMoveDown={ ()=>this.moveDown( item ) }
									haveToShowUp={ () => this.haveToShowUp( item ) }
									haveToShowDown={ () => this.haveToShowDown( item ) } >
									{this.props.children}
								</DetailView>
							</li>
						)})
				}
			</ul>
		);
	}

	private getActiveState( key: string ) {
		const { activeMap } = this.state;

		if ( activeMap[ key ] === undefined ) {
			this.setState( prevState => {
				let active = { ...prevState.activeMap }
				active[ key ] = false;
				return {
					activeMap: active
				}
			})
			return true;
		}
		return activeMap[ key ];
	}

	private moveUp( item: ViewListItem<T> ) {  //TODO: have to consider different clientHeight. See solution in ViewPanel.
		const replacedItem = this.props.onMoveUp( item );
		this.scrollByOnMoveUpDown = -( this.base as Element ).children
																		.namedItem( replacedItem.key ).scrollHeight;
	}

	private moveDown( item: ViewListItem<T> ) {  //TODO: have to consider different clientHeight. See solution in ViewPanel.
		const replacedItem = this.props.onMoveDown( item );
		this.scrollByOnMoveUpDown = ( this.base as Element ).children
																		.namedItem( replacedItem.key ).scrollHeight;
	}

	private detailViewClicked( key: string ) {
		this.setState( ( state: MasterViewState ) => {
			let active = {...state.activeMap};

			this.props.listSource.forEach( item => {
				active[ item.key ] = ( item.key === key )? !active[ item.key ] : false 
			})

			return {
				activeMap: active
			}
		});
	}

	someDetailViewIsActive() {
		const { activeMap } = this.state

		let someAreActive = false;

		Object.values( activeMap ).forEach( item => {
			someAreActive = someAreActive || item
		})

		return someAreActive
	}

	protected haveToShowUp( item: ViewListItem<T> ): boolean {
		const itemPos = this.props.listSource.indexOf( item );
		return itemPos != 0;
	}

	protected haveToShowDown( item: ViewListItem<T> ): boolean {
		const itemPos = this.props.listSource.indexOf( item );
		return itemPos != ( this.props.listSource.length - 1 );
	}

	getClassName() {
		return 'MasterView';
	}

	private scrollByOnMoveUpDown: number;
}
