import { h } from 'preact'
import { UserController, UserControllerEvent } from '../../../user/controller/user-controller';
import { JasComponent, JasComponentProps, JasComponentState } from '../../../components/jas-component';
import { Callback } from '../../../quarkum.js/delegate';
const SpinnerIcon = require( "@fortawesome/fontawesome-free/svgs/solid/spinner.svg" );
const CloudSaveIcon = require( "@fortawesome/fontawesome-free/svgs/solid/cloud-download-alt.svg" );
const CheckCircleIcon = require( "@fortawesome/fontawesome-free/svgs/regular/check-circle.svg" );

interface SaveTripButtonProps extends JasComponentProps {
	savingLabel: string;
	saveLabel: string;
	savedLabel: string
}

interface SaveTripButtonState extends JasComponentState {
	pendingChanges: boolean;
	saving: boolean;
}

export class SaveTripButton extends JasComponent<SaveTripButtonProps, SaveTripButtonState> {
	componentDidMount() {
		this._userCtrlHandler = UserController.instance.onUserControllerChange( event => {
			if ( ( event.pendingChanges ?? event.saving ) !== undefined ) {
				this.setState( event )
			}
		})
	}

	componentWillUnmount() {
		UserController.instance.removeOnChange( this._userCtrlHandler )
	}

	render() {
		const { pendingChanges, saving } = this.state;

		return (
			<button className="save-button"
				onClick={ ()=>UserController.instance.save() }
				disabled={ !pendingChanges || saving }
			>
				{ this.saveButtonIcon() }{ this.saveButtonText() }
			</button>
		)
	}

	saveButtonText() {
		const { savingLabel, saveLabel, savedLabel } = this.props
		const { saving, pendingChanges } = this.state;

		return saving? savingLabel : pendingChanges? saveLabel : savedLabel;
	}

	saveButtonIcon() {
		const { saving, pendingChanges } = this.state;

		const savingIcon = <SpinnerIcon data-testid="icon-spinner" fill="lightgrey" width="1em" height="1.4em" className="svg-inline icon-button spin"/>;
		const pendingIcon = <CloudSaveIcon data-testid="icon-cloud-download-alt" fill="lightPink" width="1em" height="1.4em" className="svg-inline icon-button"/>;
		const normalIcon = <CheckCircleIcon data-testid="icon-check-circle" fill="lightgreen" width="1em" height="1.4em" className="svg-inline icon-button"/>;
	
		return saving? savingIcon : pendingChanges? pendingIcon: normalIcon;
	}

	getClassName() {
		return 'SaveTripButton'
	}

	private _userCtrlHandler: Callback< UserController, UserControllerEvent >
}
