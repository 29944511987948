import { v4 as uuid } from "uuid"
import { List, persistentArray } from "../../utils/list";
import { Persistent, persistent, persistentParser, persistentBoolean } from "../../quarkum.js/persistent";
import { Utils } from "../../utils/utils";
import { TripController } from "./trip-controller";
import { CityController } from "./city-controller";
import { User } from "../../user/user";
import { Observable, ChangeEvent } from "../../quarkum.js/observable";

export enum Severity { false = 0, info, warning, error }

export interface ControllerErrorState {
	stayTooShort: Severity;
	stayTooLong: Severity;
}

export abstract class Controller extends Persistent {
	static readonly Error = {
		noNegativeNumbers: 'noNegativeNumbers'
	}

	constructor( name?: string, isoCode?: string ) {
		super()
		this.setId( uuid() );
		this._name = name;
		this._isoCode = isoCode;
		this._selectedArray = [];
		this._selectedItems = new List< Controller >( this._selectedArray );
		this._stayLength = 0;
	}

	setName( name: string ) {
		this.changeProp( 'name', name );
		return this;
	}

	get name() {
		return this._name;
	}

	setISOCode( code: string ) {
		this.changeProp( 'isoCode', code )
		return this;
	}

	get isoCode() {
		return this._isoCode;
	}

	setOwner( value: Controller ) {
		this._owner = value;
		return this;
	}

	get owner() {
		return this._owner;
	}

	getRootController() {
		let root: Controller = this;
		while( root._owner ) {
			root = root._owner
		}
		return root;
	}

	setStayLength( value: number, lock: boolean = true ) {
		if ( this.changeProp( 'stayLength', value ) && lock ) {
			this._lockStayLength = true;
		};
		return this;
	}

	protected getStayLength() {
		return this.lockStayLength?	this._stayLength : this.childrenStayLength();
	}

	get stayLength() {
		return this.getStayLength();
	}

	setLockStayLength( value: boolean ) {
		this.changeProp( 'lockStayLength', value );
		return this;
	}

	get lockStayLength() {
		return this._lockStayLength;
	}

	setUser( value: User ) {
		if ( this._user === value ) return this;

		this.changeProp( 'user', value );
		return this;
	}

	get user(): User {
		return this._user || this.owner && this.owner.user;
	}

	setRelatedPostUrl( value: string ) {
		this.changeProp( 'relatedPostUrl', value );
		return this;
	}

	get relatedPostUrl() {
		return this._relatedPostUrl;
	}

	setPictureUrl( value: string ) {
		this.changeProp( 'pictureUrl', value );
		return this;
	}

	get pictureUrl() {
		return this._pictureUrl;
	}

	tripController(): TripController {
		return this.getRootController() as TripController;
	}

	arrivalDate() {
		if ( !this.tripController() ) return null;
		return Utils.dateFrom( this.tripController().departureDate, this.getDaysFromTripStart() );
	}

	getDaysFromTripStart(): number {
		if ( !this.owner ) return 0;

		const parentItems = this.owner.selectedItems.items;
		let days = 0;
		let i = 0;

		while ( parentItems[i] && ( parentItems[i] !== this ) ) {
			const daysInTransit = (<CityController>parentItems[i]).daysInTransit? (<CityController>parentItems[i]).daysInTransit : 0;
			days += parentItems[i++].stayLength + daysInTransit;
		}
		return days + this.owner.getDaysFromTripStart();
	}

	hasError(): Severity {
		const errors = this.getErrorState();
		let highestSeverity = Severity.false

		for ( var errorProp in errors ) {
			if ( errors[ errorProp ] > highestSeverity ) {
				highestSeverity = errors[ errorProp ];
			}
		}
		return highestSeverity;
	}

	doChildrenHaveError(): Severity {
		let highestSeverity = Severity.false

		this.selectedItems.items.forEach( item => {
			const thisSeverity = item.hasError()
			if ( thisSeverity > highestSeverity ) {
				highestSeverity > thisSeverity
			}

			const childrenSeverity = item.doChildrenHaveError()
			if ( childrenSeverity > highestSeverity ) {
				highestSeverity = childrenSeverity
			}
		})
		return highestSeverity
	}

	getErrorState(): ControllerErrorState {
		return {
			stayTooLong: this.isStayTooLong() && Severity.error,
			stayTooShort: this.isStayTooShort() && Severity.error
		}
	}

	private isStayTooShort() {
		return this.lockStayLength && this._stayLength < this.childrenStayLength();
	}

	private isStayTooLong() {
		return this.owner && this.owner.isStayTooShort();
	}

	protected childrenStayLength(): number {
		let days = 0;
		this.selectedItems.items.forEach( item => days += item.stayLength )
		return days;
	}

	get selectedItems() {
		return this._selectedItems;
	}

	loaded() {
		this._selectedArray.forEach( item =>{
			item.setOwner( this );
		})
		this._selectedItems = new List<Controller>( this._selectedArray );
	}

	addSelected( item: Controller ) {
		item.setOwner( this );
		this._selectedItems.push( item );

		this.notifyChange({ selectedItems: this.selectedItems } as any, this ); //TODO: remove
		this.notifyChange({ addSelected: item } as any, this ); 
		return item;
	}

	moveSelected( item: Controller, distance: number ): Controller {
		const replacedItem = this._selectedItems.swap( item, distance );
		this.notifyChange({ selectedItems: this.selectedItems } as any, this );//TODO: remove
		this.notifyChange({ moveSelected: {
			item: item,
			distance: distance 
		}} as any, this );

		return replacedItem;
	}

	deleteSelected( item: Controller ) {
		this._selectedItems.delete( item );
		this.notifyChange({ selectedItems: this.selectedItems } as any, this ); //TODO: remove
		this.notifyChange({ deleteSelected: item } as any, this );
	}

	set onQuerySave( cb: () => boolean ) {
		this._onQuerySave = cb;
	}

	static iterateFromRoot( root: Controller, visitor: ( controller: Controller )=>boolean, reverse: boolean = false ) {
		if ( !root ) return null;
		const childController = root.selectedItems.items;

		if ( visitor && visitor( root ) ) return root;

		var i = reverse? childController.length : 0;
		while( reverse? i : i < childController.length ) {
			if ( reverse ) --i;
			const foundController = Controller.iterateFromRoot( childController[ i ], visitor, reverse );
			if ( foundController ) return foundController;
			if ( !reverse ) ++i;
		}
		return null;
	}

	static iterateFromLeaf( startingLeaf: Controller, visitor: ( controller: Controller )=>boolean, reverse: boolean = false ) {
		if ( !startingLeaf || !startingLeaf.owner ) return null;

		const ownerControllers = startingLeaf.owner.selectedItems.items;

		var i = reverse? ownerControllers.length : 0;
		let leafFound = false; //do not visit until startingLeaf is found

		while ( reverse? i : i < ownerControllers.length ) {
			if ( reverse ) --i;
			if ( leafFound ) {
				const foundController = Controller.iterateFromRoot( ownerControllers[ i ], visitor, reverse );
				if ( foundController ) return foundController;
			}
			else {
				leafFound = startingLeaf === ownerControllers[ i ];
			}
			if ( !reverse ) ++i;
		}

		return Controller.iterateFromLeaf( startingLeaf.owner, visitor, reverse );
	}

	nextControllerByName( name: string, visitor?: ( name: string, controller: Controller )=>void ): Controller {
		return Controller.iterateFromLeaf( this, controller => {
			if ( visitor ) visitor( name, controller );
			return controller.name === name;
		})
	}

	protected notifyChange( event: ChangeEvent<this>, observable: Observable ) {
		super.notifyChange( event, observable );
		this.notifyQuerySave( event )
		this.owner && this.owner.notifyChange( event, observable );
	}

	protected notifyQuerySave( event: ChangeEvent<this> ) {
		if ( event.user === undefined ) {
			this._onQuerySave && this._onQuerySave();
			this.owner && this.owner.notifyQuerySave( event )
		}
	}

	private _user: User;
	private _onQuerySave: () => boolean;
	private _selectedItems: List<Controller>;
	@persistentArray private _selectedArray: Controller[];
	@persistentOwner private _owner: Controller;
	@persistent private _isoCode: string;
	@persistent private _name: string;
	@persistent private _stayLength: number;
	@persistentBoolean private _lockStayLength: boolean;
	@persistent private _relatedPostUrl: string;
	@persistent private _pictureUrl: string;
}

function persistentOwner( target: Controller, property: string ) {
	return persistentParser(
		null,
		ownerController => ownerController && ownerController.id
	)( target, property);
}
