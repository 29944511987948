import { h, toChildArray } from "preact";
import { Booking } from "../controller/booking";
import { Utils } from "../../utils/utils";
import { AffiliateProgram } from "../../affiliates/controller/affiliate-program";
import { TutorialBubble, TutorialStepConfig } from "../../components/tutorial/tutorial-bubble";
import { CityController } from "../../trip-planner/controller/city-controller";
import { Accommodation } from "../controller/accommodation";
import { Transport } from "../controller/transport";
import { Tour } from "../controller/tour";
import { ActivityController } from "../../trip-planner/controller/activity-controller";
import { ControllerPanelProps, ControllerPanelState, ControllerPanel } from "../../trip-planner/view/controller-panel";
import { Affiliate } from "../../affiliates/affiliate";
import { CityPanel } from "../../trip-planner/view/city-panel";
import { Severity } from "../../trip-planner/controller/controller";
import { ViewListItem } from '../../components/item-picker/item-picker';
import { Wish } from '../../wishes/controller/wish';

export interface BookingPanelProps extends ControllerPanelProps {
	affiliateProgram: AffiliateProgram;
	headingLabel: string;
	pickerLabel?: string;
}

export interface BookingPanelState extends ControllerPanelState {
}

export class BookingPanel extends ControllerPanel< BookingPanelProps, BookingPanelState > {

	render() {
		const { active, affiliateProgram } = this.props;
		const child = toChildArray( this.props.children );
		const booking = this.props.controller as Booking;
		
		if (!booking)return;

		const { accommodationDetails, transportDetails } = this.tutorialConfig()

		return (
			<div className={this.buildMainCSSClass() }>

				<div className="clickable" 
					onClick={ 
						e => this.activatePanel( booking, e ) 
					}
				>

					<TutorialBubble step={{ 9: accommodationDetails, 16: transportDetails }}>

						{ this.renderHeading() }
					
					</TutorialBubble>

				</div>

				{ active &&
					<div className="panel-content">

						{ child.length>1 && child[1] }

						{ this.renderFields() }

						{ this.findBookingAndConfirmButtons() }

						{ affiliateProgram &&
							<Affiliate
								program={ affiliateProgram }
							/>
						}
					</div>
				}
			</div>
		);
	}

	private renderHeading() {
		const { active } = this.props
		const controller = this.props.controller as Booking;
		const locale = this.state.locale;

		const booked = controller.bookingReference || controller.confirmed

		return (
			<div className={ this.buildMainCSSClass() }>

				{ active && controller.owner instanceof CityController &&
					<CityPanel
						active={ false }
						controller={ controller.owner }
						activatePanel={ () => this.activatePanel( controller.owner )}
					/>
				}

				<div className="booking-heading">
					<div className="row multiline vertical-center">
						<div className="column two-thirds">

							<h3>
								
								{ this.props.headingLabel }

							</h3>
						
						</div>
						<div className="column third align-right">
							<span className="label">
								{ 
									controller.confirmed? locale.bookingConfirmed :
										booked
											? locale.booked 
											: `${ locale[ controller.getClassName() ] } ${ locale.notBooked }`
								}
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}

	private renderFields() {
		const { locale } = this.state;
		const { pickerLabel } = this.props;
		const child = toChildArray( this.props.children );
		const booking = this.props.controller as Booking
		const { selectHotel, selectFlight } = this.tutorialConfig()

		return (
			<fieldset className="" disabled={ booking.confirmed }>

				{ pickerLabel &&
					<div className="align-center">
						<TutorialBubble	step={{ 10: selectHotel, 17: selectFlight	}}>

							<label>
								{ pickerLabel }
							</label>

							<select
								onChange={ event => booking.setBookingCategory( event.target[ 'value' ] ) }
								value={ booking.bookingCategory }
							>
								<option value={null}>{locale.notSet}</option>
								{ 
									booking.getBookingCategories().map( cat => 
										<option value={ cat }>{ locale[ cat ] }</option> 
									) 
								}
							</select>
						</TutorialBubble>
					</div>
				}

				{ booking.bookingCategory && child[0] }

				{ booking.confirmed &&
					<span className="tooltip-text top-tooltip info">
					{ locale.cannotUnlockWhenConfirmed }
					</span>
				}
			</fieldset>
		)
	}

	private findBookingAndConfirmButtons() {
		const { locale } = this.state;
		const { affiliateProgram } = this.props;
		const booking = this.props.controller as Booking
		const { bookAccommodation, bookTransport, bookTour } = this.tutorialConfig()

		if ( booking.bookingCategory ) return (
				<div className="row center multiline">

					{ affiliateProgram &&
						<TutorialBubble step={{ 14: bookAccommodation, 21: bookTransport, 25: bookTour}}>

							<button
								onClick={ () => window.open( affiliateProgram.getUrlLink() ) }
							>
								{ `${ locale.findBooking } ${ booking.bookingCategory } ${ locale.withThisData }` }
							</button>
						</TutorialBubble>
					}
					<button
						onClick={ ()=> booking.setConfirmed( !booking.confirmed ) }
						disabled={ this.disableConfirmation() }
					>
						{ booking.confirmed? locale.confirmed : locale.confirm }
					</button>

				</div>
		)
	}

	private disableConfirmation() {
		const booking = this.props.controller as Booking;
		const { checkInError, checkOutError } = booking.getErrorState();
		const hasError = checkInError || checkOutError

		return hasError > Severity.false
			|| !Utils.isValid( booking.checkIn ) 
			|| !Utils.isValid( booking.checkOut() );
	}

	private tutorialConfig(): TutorialStepConfig {
		const booking = this.props.controller as Booking
		const active = this.props.active

		return {
			accommodationDetails: {
				stepSeq: 9,
				hideContinueButton: true,
				skip: active,
				hide: !(booking instanceof Accommodation)
			},
			selectHotel: {
				stepSeq: 10,
				vShift: '-0.5em',
				arrowShift: '40%',
				hShift: '25%',
				hideContinueButton: true,
				skip: booking.bookingCategory === 'hotel',
				hide:	!(booking instanceof Accommodation),
				waitEvent: ()=>TutorialBubble.propObserver( 
					booking, 'bookingCategory', 'hotel' 
				) 
			},
			bookAccommodation: {
				stepSeq: 14,
				hide: !( booking instanceof Accommodation ),
				arrowShift: '30%'
			},
			transportDetails: {
				stepSeq: 16,
				hideContinueButton: true,
				skip: active,
				hide: !(booking instanceof Transport)
			},
			selectFlight: {
				stepSeq: 17,
				vShift: '-0.5em',
				arrowShift: '40%',
				hShift: '25%',
				hideContinueButton: true,
				skip: booking.bookingCategory === 'flight',
				hide:	!(booking instanceof Transport),
				waitEvent: ()=>TutorialBubble.propObserver( 
					booking, 'bookingCategory', 'flight' 
				) 
			},
			bookTransport: {
				stepSeq: 21,
				hide: !( booking instanceof Transport ),
				arrowShift: '30%'
			},
			bookTour: {
				stepSeq: 25,
				hide: !( booking instanceof Tour ),
				arrowShift: '30%'
			}
		}
	}

	getClassName() {
		return 'BookingPanel';
	}

}
