import { AffiliateProgram } from "./affiliate-program";
import { Utils } from "../../utils/utils";
let logo = require( '../images/booking-com-logo.png' )

interface BookingQueryString {
	aid: string;
	ss: string;																							
	checkin_year: number;																	// cspell: disable-line
	checkin_month: number;																// cspell: disable-line
	checkin_monthday: number;															// cspell: disable-line
	checkout_year: number;																	
	checkout_month: number;
	checkout_monthday: number;
}

export class BookingCom extends AffiliateProgram {

	getUrlLink(): string {
		const { stayLength, destination } = this.booking;
		let checkIn = this.booking.checkIn || new Date( Date.now() );
		const checkOutDate = Utils.dateFrom( checkIn, stayLength );
		const query: BookingQueryString = {
			aid: this.getAffiliateId(),
			ss: destination,
			checkin_monthday: checkIn.getDate(),							// cspell: disable-line
			checkin_month: checkIn.getMonth() + 1,						// cspell: disable-line
			checkin_year: checkIn.getFullYear(),							// cspell: disable-line
			checkout_monthday: checkOutDate.getDate(),
			checkout_month: checkOutDate.getMonth() + 1,
			checkout_year: checkOutDate.getFullYear()
		}

		return 'https://www.booking.com/searchresults.en.html' + Utils.objectToQueryString( query );
	}

	getLogo(): string {
		return logo;
	}

	getAffiliateKeyString() {
		return 'bookingCom';
	}
}
