import { h } from "preact";
import { TripController } from "../../controller/trip-controller";
import { UserController } from "../../../user/controller/user-controller";
import { ItemPickerDropdown } from "../../../components/item-picker/item-picker-dropdown";
import { ViewListItem } from "../../../components/item-picker/item-picker";
import { useRef } from "preact/hooks";
let DropDownIcon = require( "@fortawesome/fontawesome-free/svgs/solid/chevron-circle-down.svg" );

interface InputTripNameProps {
	controller: TripController;
	placeholder: string;
	newTripPlaceholder: string;
}

export const InputTripName = ( props: InputTripNameProps ) => {
	const anchorRef = useRef(null);
	const { controller, placeholder: placeHolder, newTripPlaceholder } = props
	
	return (
		<div 
			className="input-trip-name drop-to-left wide"
			ref={ anchorRef }
		>
			<input className="input-big" type="text" list="tripCollection"
				placeholder={ placeHolder}
				value={ controller?.name || '' }
				onInput={ e => controller.setName( e.target['value'] ) }
			/>
			<span className="inline-middle">
				<ItemPickerDropdown
					items={
						UserController.instance.user?.tripCollection &&
						Object.values( UserController.instance.user.tripCollection ).map( trip => {
							return { key: trip.id, label: trip.name, object: trip } as ViewListItem<TripController>
						})
					}
					dropUp={ false }
					anchorElement={ anchorRef.current }
					icon={
						<DropDownIcon data-testid="drop-down-icon" className="icon-button" fill='grey' width='1rem'/>
					}
					customItemLabel={ newTripPlaceholder }
					onSelectItem={ item => {
						if ( item.object ) {
							UserController.instance.user.setLastViewedTrip( item.object )
						}
						else {
							UserController.instance.user.addTrip( new TripController( item.label ) )
						}
					}}
				/>
			</span>
		</div>
	)
}