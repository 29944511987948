import { Controller } from "./controller";
import { CountryController } from "./country-controller";
import { Accommodation } from "../../bookings/controller/accommodation";
import { Transport } from "../../bookings/controller/transport";
import { persistent, registerFactory } from "../../quarkum.js/persistent";

@registerFactory( 'CityController', ()=>new CityController() )
export class CityController extends Controller{
	constructor( name?: string, isoCode?: string ) {
		super( name, isoCode );
		this._daysInTransit = 0;
		this._accommodation = new Accommodation().setOwner( this );
		this._transport = new Transport().setOwner( this );
	}

	get country() {
		return (<CountryController>this.owner).name;
	}

	get countryISO() {
		return (<CountryController>this.owner).isoCode;
	}

	setDaysInTransit( value: number ) {
		this.changeProp( 'daysInTransit', value );
		return this;
	}

	get daysInTransit() {
		return this._daysInTransit;
	}

	getDaysFromTripStart() {
		return super.getDaysFromTripStart() + this.daysInTransit;
	}

	isFirstCity(): boolean {
		return this.previousCity() === null;
	}

	setTransport( value: Transport ) {
		if ( value ) {
			value.setOwner( this );
		}

		this.changeProp( 'transport', value )
		return this;
	}

	get transport() {
		return this._transport;
	}

	setAccommodation( value: Accommodation ) {
		if ( value ) {
			value.setOwner( this );
		}
		this.changeProp( 'accommodation', value )
		return this;
	}

	get accommodation() {
		return this._accommodation;
	}

	previousCity(): CityController {
		return Controller.iterateFromLeaf( this, controller => controller instanceof CityController, true );
	}

	arrivingFrom(): string {
		let city = this.previousCity()
		if ( city ) {
			return city.name;
		}
		else {
			return this.tripController() && this.tripController().departure
		}
	}

	protected getStayLength() {
		const accommodationLength = this.accommodation
			&& this.accommodation.lockStayLength
			&& this.accommodation.stayLength || 0;

		return this.lockStayLength?
			super.getStayLength() :
			accommodationLength < 0? 0 : accommodationLength;
	}

	loaded() {
		super.loaded();
		if ( this.accommodation ) this.accommodation.setOwner( this )
		if ( this.transport ) this.transport.setOwner( this )
	}

	@persistent private _daysInTransit: number;
	@persistent private _transport: Transport;
	@persistent private _accommodation: Accommodation;
}
